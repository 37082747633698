import { ReactComponent as IconMail } from '../../../assets/svg/icon_mail.svg';
import { ReactComponent as IconContact } from '../../../assets/svg/icon_contact.svg';
import { ReactComponent as IconEye } from '../../../assets/svg/icon_eye.svg';
import { ReactComponent as IconBits } from '../../../assets/svg/icon_bits.svg';
import { ReactComponent as IconLocationIp } from '../../../assets/svg/icon_ip_location.svg';
import { ReactComponent as IconKey } from '../../../assets/svg/icon_key.svg';
import { ReactComponent as IconAnalytics } from '../../../assets/svg/icon_analytics.svg';
import { ReactComponent as IconShare } from '../../../assets/svg/icon_share.svg';
import { ReactComponent as IconTrash } from '../../../assets/svg/icon_trash.svg';
import { ReactComponent as IconHeadphones } from '../../../assets/svg/icon_headphones.svg';
import { ReactComponent as IconCircle1 } from '../../../assets/svg/circle_1.svg';
import { ReactComponent as IconCircle2 } from '../../../assets/svg/circle_2.svg';
import { ReactComponent as IconCircle3 } from '../../../assets/svg/circle_3.svg';
import { ReactComponent as IconCircle4 } from '../../../assets/svg/circle_4.svg';
import { ReactComponent as IconCircle5 } from '../../../assets/svg/circle_5.svg';
import { ReactComponent as IconCircle6 } from '../../../assets/svg/circle_6.svg';
import { ReactComponent as IconCircle7 } from '../../../assets/svg/circle_7.svg';
import { ReactComponent as IconCircle8 } from '../../../assets/svg/circle_8.svg';
import { ReactComponent as IconCircle9 } from '../../../assets/svg/circle_9.svg';
import { ReactComponent as IconCircle10 } from '../../../assets/svg/circle_10.svg';
import { PrivacyCardsType } from '../_types/privacyCardsType';

export const privacyPolicyCardsMail: PrivacyCardsType[] = [
	{
		icon: IconMail,
		data: `We store your email address for secure authentication, ensuring your safety.`,
		backgroundElement: IconCircle1
	},
	{
		icon: IconContact,
		data: `We don't store personal information like your contact number, name, or birthdate. Please don't provide it.`,
		backgroundElement: IconCircle2
	},
	{
		icon: IconEye,
		data: `We don't store your emails. Your email service provider handles storage, sending, and receiving, but they can't see your content.`,
		backgroundElement: IconCircle3
	},
	{
		icon: IconBits,
		data: `We do not store any metadata of your email.`,
		backgroundElement: IconCircle4
	},
	{
		icon: IconLocationIp,
		data: `We do not log your IP from where you access Zunu.`,
		backgroundElement: IconCircle5
	},
	{
		icon: IconKey,
		data: `Your keys are stored with us. They are always in encrypted format. You only have access to it. We have no technical means to decrypt it.`,
		backgroundElement: IconCircle6
	},
	{
		icon: IconAnalytics,
		data: `We cannot and do not data mine or run AI on any information about you.`,
		backgroundElement: IconCircle7
	},
	{
		icon: IconShare,
		data: `We do not have any data of yours to sell or share.`,
		backgroundElement: IconCircle8
	},
	{
		icon: IconTrash,
		data: `We permanently delete all data related to your account when you delete your account.`,
		backgroundElement: IconCircle9
	},
	{
		icon: IconHeadphones,
		data: `We keep records of your interactions with Ziroh Labs, including sales, support, bug reports, and feature requests.`,
		backgroundElement: IconCircle10
	}
];

export const privacyPolicyCardsDrive: PrivacyCardsType[] = [
	{
		icon: IconMail,
		data: `We store your email address for secure authentication, ensuring your safety.`,
		backgroundElement: IconCircle1
	},
	{
		icon: IconContact,
		data: `We don't store personal information like your contact number, name, or birthdate. Please don't provide it.`,
		backgroundElement: IconCircle2
	},
	{
		icon: IconEye,
		data: `We don't store your files. They are shared and synced by your cloud service provider, who can't see your content.`,
		backgroundElement: IconCircle3
	},
	{
		icon: IconBits,
		data: `We do not store any metadata of your file.`,
		backgroundElement: IconCircle4
	},
	{
		icon: IconLocationIp,
		data: `We do not log your IP from where you access Zunu.`,
		backgroundElement: IconCircle5
	},
	{
		icon: IconKey,
		data: `Your keys are stored with us. They are always in encrypted format. You only have access to it. We have no technical means to decrypt it.`,
		backgroundElement: IconCircle6
	},
	{
		icon: IconAnalytics,
		data: `We cannot and do not data mine or run AI on any information about you.`,
		backgroundElement: IconCircle7
	},
	{
		icon: IconShare,
		data: `We do not have any data of yours to sell or share.`,
		backgroundElement: IconCircle8
	},
	{
		icon: IconTrash,
		data: `We permanently delete all data related to your account when you delete your account.`,
		backgroundElement: IconCircle9
	},
	{
		icon: IconHeadphones,
		data: `We keep records of your interactions with Ziroh Labs, including sales, support, bug reports, and feature requests.`,
		backgroundElement: IconCircle10
	}
];

export const privacyPolicyCardsMessage: PrivacyCardsType[] = [
	{
		icon: IconMail,
		data: `We store your email address for secure authentication, ensuring your safety.`,
		backgroundElement: IconCircle1
	},
	{
		icon: IconContact,
		data: `We don't store personal information like your name, or birthdate. Please don't provide it.`,
		backgroundElement: IconCircle2
	},
	{
		icon: IconEye,
		data: `We don't store your messages. They are managed by your service provider, who cannot see your message content.`,
		backgroundElement: IconCircle3
	},
	{
		icon: IconBits,
		data: `We do not store any metadata of your message.`,
		backgroundElement: IconCircle4
	},
	{
		icon: IconLocationIp,
		data: `We do not log your IP from where you access Zunu.`,
		backgroundElement: IconCircle5
	},
	{
		icon: IconKey,
		data: `Your keys are stored with us. They are always in encrypted format. You only have access to it. We have no technical means to decrypt it.`,
		backgroundElement: IconCircle6
	},
	{
		icon: IconAnalytics,
		data: `We cannot and do not data mine or run AI on any information about you.`,
		backgroundElement: IconCircle7
	},
	{
		icon: IconShare,
		data: `We do not have any data of yours to sell or share.`,
		backgroundElement: IconCircle8
	},
	{
		icon: IconTrash,
		data: `We permanently delete all data related to your account when you delete your account.`,
		backgroundElement: IconCircle9
	},
	{
		icon: IconHeadphones,
		data: `We keep records of your interactions with Ziroh Labs, including sales, support, bug reports, and feature requests.`,
		backgroundElement: IconCircle10
	}
];

export const privacyPolicyCardsCamera: PrivacyCardsType[] = [
	{
		icon: IconMail,
		data: `We store your email address for secure authentication, ensuring your safety.`,
		backgroundElement: IconCircle1
	},
	{
		icon: IconContact,
		data: `We don't store personal information like your contact number, name, or birthdate. Please don't provide it.`,
		backgroundElement: IconCircle2
	},
	{
		icon: IconEye,
		data: `We don't store your photos and videos.`,
		backgroundElement: IconCircle3
	},
	{
		icon: IconBits,
		data: `We do not store any metadata of your photos and videos.`,
		backgroundElement: IconCircle4
	},
	{
		icon: IconLocationIp,
		data: `We do not log your IP from where you access Zunu.`,
		backgroundElement: IconCircle5
	},
	{
		icon: IconKey,
		data: `Your keys are stored with us. They are always in encrypted format. You only have access to it. We have no technical means to decrypt it.`,
		backgroundElement: IconCircle6
	},
	{
		icon: IconAnalytics,
		data: `We cannot and do not data mine or run AI on any information about you.`,
		backgroundElement: IconCircle7
	},
	{
		icon: IconShare,
		data: `We do not have any data of yours to sell or share.`,
		backgroundElement: IconCircle8
	},
	{
		icon: IconTrash,
		data: `We permanently delete all data related to your account when you delete your account.`,
		backgroundElement: IconCircle9
	},
	{
		icon: IconHeadphones,
		data: `We keep records of your interactions with Ziroh Labs, including sales, support, bug reports, and feature requests.`,
		backgroundElement: IconCircle10
	}
];

// export const privacyPolicyCardsPasswords: PrivacyCardsType[] = [
//     {
//       icon: IconMail,
//       data: `We store your email address for secure authentication, ensuring your safety.`,
//       backgroundElement: IconCircle1,
//     },
//     {
//       icon: IconContact,
//       data: `We don't store personal information like your contact number, name, or birthdate. Please don't provide it.`,
//       backgroundElement: IconCircle2,
//     },
//     {
//       icon: IconEye,
//       data: `We don't store your emails. Your email service provider handles storage, sending, and receiving, but they can't see your content.`,
//       backgroundElement: IconCircle3,
//     },
//     {
//       icon: IconBits,
//       data: `We do not store any metadata of your email.`,
//       backgroundElement: IconCircle4,
//     },
//     {
//       icon: IconLocationIp,
//       data: `We do not log your IP from where you access Zunu.`,
//       backgroundElement: IconCircle5,
//     },
//     {
//       icon: IconKey,
//       data: `Your keys are stored with us. They are always in encrypted format. You only have access to it. We have no technical means to decrypt it.`,
//       backgroundElement: IconCircle6,
//     },
//     {
//       icon: IconAnalytics,
//       data: `We cannot and do not data mine or run AI on any information about you.`,
//       backgroundElement: IconCircle7,
//     },
//     {
//       icon: IconShare,
//       data: `We do not have any data of yours to sell or share.`,
//       backgroundElement: IconCircle8,
//     },
//     {
//       icon: IconTrash,
//       data: `We permanently delete all data related to your account when you delete your account.`,
//       backgroundElement: IconCircle9,
//     },
//     {
//       icon: IconHeadphones,
//       data: `We keep records of your interactions with Ziroh Labs, including sales, support, bug reports, and feature requests.`,
//       backgroundElement: IconCircle10,
//     },
//   ];
