import { useEffect, useState } from "react";
import { useTermshook } from "./terms.hook";
import styles from './terms.module.scss';
import { TERMS } from "./terms_data";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import useAppHook from "../../App.hook";
import { useDownloadBtnStore } from "../../StateManager/DownloadBtnStore";

function TermsMobile() {
    const { isDownloadBtn, setIsDownloadBtn} = useDownloadBtnStore();

    const {
        topics, active,
        handleTopicClick,
        isCamera, isMail, isMessage, isDrive,
    } = useTermshook()

    function TermsArticle({ title, contents }: { title: string, contents: any }) {
        return (
            <div className={styles.point}>
                <p className={styles.pointTitle}>{title}</p>
                <div className={styles.pointContent}>
                    {contents.map((item: any) => {
                        return item.imgSrc ? (<></>) : (parse(item))
                    })}
                </div>

            </div>
        )
    }

    return (
        <section className={styles.terms}>

            <Helmet>
                <meta name="description" content="Terms of Service of Zunu Apps" />
                <title>Terms of Service - Zunu</title>
                
                <meta property="og:site_name" content="Zunu" />
                <meta property="og:title" content="Terms of Service - Zunu" />
                <meta property="og:url" content="https://zunuprivacy.com" />
                <meta property="og:description" content="Terms of Service of Zunu Apps" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://zunuprivacy.com/icon192.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@zirohlabs" />
                <meta name="twitter:title" content="Zunu: Software-defined security stack with privacy-preserving, performant cryptographic algorithms." />
                <meta name="twitter:description" content="Terms of Service of Zunu Apps" />
                <meta name="twitter:image"
                    content="https://zunuprivacy.com/icon192.png" />
            </Helmet>

            <p className={styles.pageHeading}>Terms of Service</p>

            <div className={styles.topicsSelector}>
                {topics.map((topic: string, i) => {
                    return <p key={i} className={`${styles.topic} ${active == topic.toLowerCase() ? `${styles.topic_active}` : ''}`} onClick={() => { handleTopicClick(topic) }}>{topic}</p>
                })}
            </div>

            <div className={styles.productTermsWrapper}>
                {isCamera &&
                    <section className={styles.productTerms}>
                        {TERMS.camera.map((terms: any, i) => {
                            return <TermsArticle key={i} title={terms.title} contents={terms.contents} />
                        })}
                    </section>
                }

                {isMail &&
                    <section className={styles.productTerms}>
                        {TERMS.mail.map((terms: any, i) => {
                            return <TermsArticle key={i} title={terms.title} contents={terms.contents} />
                        })}
                    </section>
                }

                {isMessage &&
                    <section className={styles.productTerms}>
                        {TERMS.messgae.map((terms: any, i) => {
                            return <TermsArticle title={terms.title} contents={terms.contents} />
                        })}
                    </section>
                }

                {isDrive &&
                    <section className={styles.productTerms}>
                        {TERMS.drive.map((terms: any, i) => {
                            return <TermsArticle key={i} title={terms.title} contents={terms.contents} />
                        })}
                    </section>
                }

            </div>
        </section>
    )
}

export default TermsMobile