import React from 'react'
import styles from './academicAlliance.module.scss'
import { Helmet } from 'react-helmet'
import { ReactComponent as RupeeIcon } from '../../assets/svg/rupee.svg'
import { ReactComponent as VirtualPrivacyIcon } from '../../assets/svg/virtual_privacy.svg'
import { ReactComponent as InternshipIcon } from '../../assets/svg/internship.svg'
import { ReactComponent as AcademicVaultIcon } from '../../assets/svg/academicVault.svg'
import Carousel from 'react-multi-carousel'
import IICMR from '../../assets/png/IICMR.png'
import MIT from '../../assets/png/MIT.png'
import RV from '../../assets/png/RV.png'
import REVA2 from '../../assets/png/RV2.png'
import Amriti from '../../assets/png/REVA.png'
import EwlCertificate1 from '../../assets/webp/ewl_badge.webp'
import EwlCertificate2 from '../../assets/webp/ewl_certificate.webp'
import EwlCertificate3 from '../../assets/webp/ewl_socialpost.webp'
import { Link } from 'react-router-dom'
import ewlStudents from '../../assets/webp/students.webp'
import ewlUniversities from '../../assets/webp/universities.webp'
import ewlStates from '../../assets/webp/states.webp'
import ewlProjects from '../../assets/webp/ewl_projects.webp'
import { OutsideClickHandler } from '../../_shared/components/OutsideClickHandler/OutsideClickHandler'
import { AcademicData } from './academicAlliance_data'
import { useDeviceStore } from '../../StateManager/DeviceStore'
import { DEVICE } from '../../_shared/constants/constants'
import Marquee from 'react-fast-marquee'
import useAcademicAllianceHook from './academicAlliance.hook'

//LAZY LOADING
import { lazily } from 'react-lazily';
const { SignUpForm } = lazily(() => import('./components/SignUpForm/signUpForm'));

function AcademicAlliance() {
    const {
        isRegisterForm,
        registerFormOpen, registerFormClose,
        ewlResponsive,
    } = useAcademicAllianceHook();

    const { device } = useDeviceStore();

    return (
        <div className={styles.academicAlliancePage}>
            <Helmet>
                <meta name="description" content="Connecting academia to the world of tech" />
                <title>Academic Alliance: Connecting academia to the world of tech</title>
                <meta property="og:site_name" content="Zunu" />
                <meta property="og:title" content="Academic Alliance: Connecting academia to the world of tech" />
                <meta property="og:url" content="https://zunuprivacy.com/academic-alliance" />
                <meta property="og:description" content="Connecting academia to the world of tech" />
                <meta property="og:type" content="website" />
                <meta property="og:image"
                    content="https://ziroh.com/Images/zunuimages/zunu_icon_colored-192x192.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@zirohlabs" />
                <meta name="twitter:title" content="Zunu: Software-defined security stack with privacy-preserving, performant cryptographic algorithms." />
                <meta name="twitter:description" content="Connecting academia to the world of tech" />
                <meta name="twitter:image"
                    content="https://ziroh.com/Images/zunuimages/zunu_icon_colored-192x192.png" />
            </Helmet>
            <div className={styles.academicPageIntro}>
                <div className={styles.academicChildContainer}>
                    <div className={styles.academicContents}>
                        <p className={styles.academicHeader}>Academic Alliance </p>
                        <p className={styles.academicSubText}>Connecting academia <br></br> To The World of Tech</p>
                        <button className={styles.registerButton} onClick={registerFormOpen}>REGISTER YOUR UNIVERSITY</button>
                    </div>
                </div>

            </div>
            {isRegisterForm &&
                <div className={styles.signUpformModal}>
                    <div className={styles.sigmnupModalChild}>
                        <OutsideClickHandler
                            onOutsideClick={() => {
                                registerFormClose();
                            }}
                        >
                            <SignUpForm onclose={registerFormClose} isRegisterForm={isRegisterForm} />

                        </OutsideClickHandler>
                    </div>
                </div>
            }


            <div className={styles.academicProgram}>
                <div className={styles.academicProgramContainer}>
                    <p className={styles.academicProgramHeading}>Academic Alliance <span>Program</span></p>
                    <p className={styles.academicProgramText}>
                        Academic Alliance provides partner institutions an opportunity to
                        introduce their students to cutting-edge technologies and broaden
                        their horizons on the current industry practices.
                    </p>

                    <div className={styles.academicProgramChild}>
                        <div className={styles.programGridContainer}>
                            <div className={styles.academicProgramContents}>
                                <div className={styles.programHeaderContainer}>
                                    <RupeeIcon className={styles.section2Icons} />
                                    <p className={styles.programGridHeader}>Earn While You Learn (EWL)</p>
                                </div>
                                <p className={styles.programGridText}>Live-Industry Projects across Programming
                                    Languages. Successful submissions are rewarded.</p>
                            </div>
                            <div className={styles.academicProgramContents}>
                                <div className={styles.programHeaderContainer}>
                                    <VirtualPrivacyIcon className={styles.section2Icons} />
                                    <p className={styles.programGridHeader}>Virtual Privacy Lab</p>
                                </div>
                                <p className={styles.programGridText}>
                                    Data Privacy Community inclusion through
                                    events, workshops and technology deployment through
                                    products, APIs, and SDKs.</p>
                            </div>
                            <div className={styles.academicProgramContents}>
                                <div className={styles.programHeaderContainer}>
                                    <InternshipIcon className={styles.section2Icons} />
                                    <p className={styles.programGridHeader}>Internship & Placement</p>
                                </div>
                                <p className={styles.programGridText}>Priority-based opportunities for your
                                    students, subject to appropriate coding tests and
                                    interviews.</p>
                            </div>
                            <div className={styles.academicProgramContents}>
                                <div className={styles.programHeaderContainer}>
                                    <AcademicVaultIcon className={styles.section2Icons} />
                                    <p className={styles.programGridHeader}>Academic Vault</p>
                                </div>
                                <p className={styles.programGridText}>Privacy-Preserving Software Suite
                                    for Academic Institutions. Private Communication,
                                    Collaboration, and Sharing for all stakeholders.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.campusMOUPartners}>
                <div className={styles.campusMOUChild}>
                    <p className={styles.campusMOUHeading}>
                        On-Campus MOU Signing
                    </p>
                    <div className={styles.marqueeContainer}>
                        <Marquee
                            pauseOnHover={true}
                            loop={0}
                            speed={40}
                        >
                            <div className={styles.marqueeImagesContainer}>
                            <div className={styles.marqueeContents}>
                                    <Link
                                     to={"https://www.linkedin.com/posts/zirohlabs_cuttingedgetechnology-educationinindia-activity-6830403006631165952-18Sc"} target='_blank' aria-label='IICMR'>
                                     
                                        <img src={IICMR} alt='IICMR'/>
                                        <p className={styles.universityNames}>IICMR-MCA</p>
                                    </Link>
                                </div>
                                <div className={styles.marqueeContents}>
                                    <Link to={"https://www.linkedin.com/posts/zirohlabs_ziroh-labs-academic-alliance-program-activity-6871066482017665024-otdW"} target='_blank' aria-label='MIT'>
                                        <img src={MIT} alt='MIT'/>
                                        <p className={styles.universityNames}>MIT-WPU</p>
                                    </Link>
                                </div>
                                <div className={styles.marqueeContents}>
                                    <Link to={"https://www.linkedin.com/feed/update/urn:li:activity:6937324185819963392"} target='_blank' aria-label='RV University'>
                                        <img src={RV} alt='RV'/>
                                        <p className={styles.universityNames}>RV University</p>
                                    </Link>
                                </div>
                                <div className={styles.marqueeContents}>
                                    <Link 
                                    to={"https://www.linkedin.com/posts/zirohlabs_ziroh-labs-signs-mou-with-reva-university-activity-6912317004527869952-TO-i?utm_source=linkedin_share&utm_medium=member_desktop_web"} target='_blank' aria-label='REVA University'>
                                        <img src={REVA2} alt='REVA'/>
                                        <p className={styles.universityNames}>REVA University</p>
                                    </Link>
                                </div>
                                <div className={styles.marqueeContents}>
                                    <Link to={"https://www.linkedin.com/posts/zirohlabs_ziroh-labs-signs-mou-with-amrita-vishwa-vidyapeetham-activity-6916679130566586368-r3MD?utm_source=linkedin_share&utm_medium=member_desktop_web"} target='_blank' aria-label='AVV'>
                                        <img src={Amriti} alt='AVV'/>
                                        <p className={styles.universityNames}>AMRITA VISHWA VIDYAPEETHAM</p>
                                    </Link>
                                </div>
                            </div>
                        </Marquee>
                    </div>
                </div>

            </div>
            <div className={styles.ewlCertificateContainer}>
                <div className={styles.ewlCertificateChild}>
                    <p className={styles.ewlText}>Earn While you Learn <span>EWL</span> Program Certificates</p>

                    <div className={styles.ewlrightContainer}>
                        <div className={styles.ewlCarousel}>
                            <Carousel
                                responsive={ewlResponsive}
                                draggable={false}
                                swipeable={false}
                                showDots={false}
                                centerMode={false}
                                autoPlay={true}
                                autoPlaySpeed={2000}
                                arrows={false}
                                infinite={true}
                            >
                                <div className={styles.ewlCertificates}>
                                    <img src={EwlCertificate1} alt='ewl certificate'/>
                                </div>
                                <div className={styles.ewlCertificates}>
                                    <img src={EwlCertificate2} alt='ewl certificate'/>
                                </div>
                                <div className={styles.ewlCertificates}>
                                    <img src={EwlCertificate3} alt='Social Media Recognition'/>
                                </div>

                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.universitiesContainer}>
                <div className={styles.universitiesChild}>
                    <p className={styles.universityHeading}>Partner Universities</p>
                    {device == DEVICE.MOBILE ? (
                        <div className={styles.universityGrid}>
                            {AcademicData.slice(0, 3).map((logo: any) => {
                                return (
                                    <div className={styles.universityContent}>
                                        <img src={logo.image} alt='University Logo'/>
                                        <p className={styles.gridUniversityName}>{logo.University}</p>
                                    </div>
                                )
                            })}
                        </div>

                    )
                        :
                        (
                            <div className={styles.universityGrid}>
                                {AcademicData.slice(0, 5).map((logo: any) => {
                                    return (
                                        <div className={styles.universityContent}>
                                            <img src={logo.image} alt='University Logo' />
                                            <p className={styles.gridUniversityName}>{logo.University}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }

                    <Link to="partner-universities" className={styles.seeMore} aria-label='partner-universities'>
                        <button className={styles.seeMoreButton} aria-label='See More'>See More</button>
                    </Link>

                </div>
            </div>


            <div className={styles.joinCommunityContainer}>
                <div className={styles.communityAndRegisterContainer}>
                    <div className={styles.joincommunitychild}>
                        <p className={styles.communityHeading}>Join Our Community</p>
                        <div className={styles.communityGrid}>
                            <div className={styles.communityContents}>
                                <img src={ewlStudents} className={styles.communityIcons} alt='Students'/>
                                <div className={styles.communityTextContainer}>
                                    <p className={styles.iconName}>Students</p>
                                    <p className={styles.iconNumber}>1M+</p>
                                </div>
                            </div>
                            <div className={styles.communityContents}>
                                <img src={ewlUniversities} className={styles.communityIcons} alt='Universities' />
                                <div className={styles.communityTextContainer}>
                                    <p className={styles.iconName}>Universities</p>
                                    <p className={styles.iconNumber}>40+</p>
                                </div>
                            </div>
                            <div className={styles.communityContents}>
                                <img src={ewlProjects} className={styles.communityIcons} alt='EWL Projects'/>
                                <div className={styles.communityTextContainer}>
                                    <p className={styles.iconName}>EWL Projects</p>
                                    <p className={styles.iconNumber}>45</p>
                                </div>
                            </div>
                            <div className={styles.communityContents}>
                                <img src={ewlStates} className={styles.communityIcons} alt='States'/>
                                <div className={styles.communityTextContainer}>
                                    <p className={styles.iconName}>States</p>
                                    <p className={styles.iconNumber}>17+</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={styles.signUpFormContainer}>
                        <SignUpForm />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AcademicAlliance