import { useEffect } from "react"
import { downloadService } from "../../App.registration"
import { PLATFORM } from "../../_shared/constants/constants"
import { useDownloadBtnStore } from "../../StateManager/DownloadBtnStore"

function useDownloadHook() {

    const { isDownloadBtn, setIsDownloadBtn } = useDownloadBtnStore();
    useEffect(() => {
        setIsDownloadBtn(false)
    }, [])

    function handleBuildDownload(platform: string) {
        if (platform == PLATFORM.MAC) {
            downloadService.downloadZunu(PLATFORM.MAC)
        }

        if (platform == PLATFORM.MACIntel) {
            downloadService.downloadZunu(PLATFORM.MACIntel)
        }

        if (platform == PLATFORM.WIN) {
            downloadService.downloadZunu(PLATFORM.WIN)
        }

        if (platform == PLATFORM.IOS) {
            downloadService.downloadZunu(PLATFORM.IOS)
        }

        if (platform == PLATFORM.AND) {
            downloadService.downloadZunu(PLATFORM.AND)
        }

        if (platform == PLATFORM.INDUS) {
            downloadService.downloadZunu(PLATFORM.INDUS)
        }

        if (platform == PLATFORM.LINUX18) {
            downloadService.downloadZunu(PLATFORM.LINUX18)
        }

        if (platform == PLATFORM.LINUX22) {
            downloadService.downloadZunu(PLATFORM.LINUX22)
        }

        if (platform == PLATFORM.WIN_PORATBLE) {
            downloadService.downloadZunu(PLATFORM.WIN_PORATBLE)
        }

    }

    return {
        handleBuildDownload,
    }
}

export  {useDownloadHook}