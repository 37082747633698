import * as React from 'react';
import styles from './zunuCamera.module.scss'
import { ReactComponent as FileTransferDoodle } from '../../assets/svg/capture_image_doodle.svg';
import { ReactComponent as RecordIcon } from '../../assets/svg/record_icon.svg';
import { ReactComponent as FlashIcon } from '../../assets/svg/flash_icon.svg';
import { ReactComponent as CardIcon1 } from '../../assets/svg/drive_card_icon1.svg';
import { ReactComponent as CardIcon2 } from '../../assets/svg/camera_card_icon1.svg';
import { ReactComponent as CardIcon3 } from '../../assets/svg/drive_card_icon2.svg';
import { ReactComponent as CardIcon4 } from '../../assets/svg/drive_card_icon4.svg';
import { ReactComponent as CardIcon5 } from '../../assets/svg/camera_card_icon2.svg';
import { ReactComponent as CardIcon6 } from '../../assets/svg/camera_card_icon3.svg';
import { ReactComponent as CardIcon7 } from '../../assets/svg/camera_card_icon4.svg';
import { ReactComponent as CardIcon8 } from '../../assets/svg/camera_card_icon5.svg';
import { ReactComponent as CardIcon9 } from '../../assets/svg/camera_card_icon6.svg';
import { ReactComponent as CardIcon10 } from '../../assets/svg/camera_card_icon7.svg';
import { ReactComponent as CardIcon11 } from '../../assets/svg/camera_card_icon8.svg';
import { ReactComponent as CardIcon12 } from '../../assets/svg/camera_card_icon9.svg';
import { ReactComponent as CardIcon13 } from '../../assets/svg/camera_card_icon10.svg';
import USP1 from '../../assets/webp/camera_usp1.webp';
import USP2 from '../../assets/webp/camera_usp2.webp';
import USP1Mobile from '../../assets/webp/drive_usp1_mobile.webp';
import USP2Mobile from '../../assets/webp/drive_usp2_mobile.webp';
// import BannerImage from '../../assets/webp/camera_banner_image.webp';
import BannerImage from '../../assets/webp/camera_banner_image_2.webp';
// import BannerImageMobile from '../../assets/webp/camera_banner_image_mobile.webp';
import BannerImageMobile from '../../assets/webp/camera_banner_image_mobile_2.webp';
// import SetApaprtImage from '../../assets/webp/camera_setapart_image.webp';
import SetApaprtImage from '../../assets/webp/camera_setapart_image_2.webp';
import SetApaprtImageMobile from '../../assets/webp/camera_setapart_image_mobile.webp';
import CameraFeat1 from '../../assets/webp/camera_feat1.webp';
import CameraFeat2 from '../../assets/webp/camera_feat2.webp';
import CameraFeat3 from '../../assets/webp/camera_feat3.webp';
import CameraFeat4 from '../../assets/webp/camera_feat4.webp';
import CameraFeat5 from '../../assets/webp/camera_feat5.webp';
import CameraFeat6 from '../../assets/webp/camera_feat6.webp';
import { useThemeStore } from '../../StateManager/ThemeStore';
import { DEVICE, THEME } from '../../_shared/constants/constants';
import { Helmet } from 'react-helmet';
import { useEffect, useRef } from 'react';
import { QUESTIONS } from '../Support/questions_data';
import { TESTIMONIALS } from '../Home/components/Testimonials/testimonials_data';
import { useDeviceStore } from '../../StateManager/DeviceStore';
import { Fade, AttentionSeeker } from "react-awesome-reveal";
import { useDownloadBtnStore } from '../../StateManager/DownloadBtnStore';
import { WHITEPAPERS } from '../_shared/components/WhitePapers/whitePapersData';


//LAZY LOADING
import { lazily } from 'react-lazily';
const { Testimonials } = lazily(() => import('../Home/components/Testimonials/testimonials'));
const { WhitePapers } = lazily(() => import('../_shared/components/WhitePapers/whitePapers.component'));
const { FloaterDownload } = lazily(() => import('../_shared/components/FloaterDownload/floaterDownload.component'));
const { FloaterNavigator } = lazily(() => import('../_shared/components/floaterNavigator/floaterNavigator.component'));
const { ProductFaqs } = lazily(() => import('../_shared/components/productFaqs/productFaqs.component'));

export function ZunuCamera() {

    const { theme } = useThemeStore()
    const { device, setDevice } = useDeviceStore();
    const { isDownloadBtn, setIsDownloadBtn} = useDownloadBtnStore();
    const headerRef: any = useRef();

    useEffect(() => {
        if (device != DEVICE.MOBILE) {
            setIsDownloadBtn(true)
            return
        }
        else {
           setIsDownloadBtn(false)
            return
        }
    }, [device])

    return (
        <div className={styles.productPage}>

            <Helmet>
                <meta name="description" content="Capture and store photos & videos with the utmost confidentiality." />
                <title>Zunu Camera - Privacy-First Camera App</title>
                
                <meta property="og:site_name" content="Zunu" />
                <meta property="og:title" content="Zunu Camera - Privacy-First Camera App" />
                <meta property="og:url" content="https://zunuprivacy.com" />
                <meta property="og:description" content="Capture and store photos & videos with the utmost confidentiality." />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://zunuprivacy.com/icon192.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@zirohlabs" />
                <meta name="twitter:title" content="Zunu: Software-defined security stack with privacy-preserving, performant cryptographic algorithms." />
                <meta name="twitter:description" content="Capture and store photos & videos with the utmost confidentiality." />
                <meta name="twitter:image"
                    content="https://zunuprivacy.com/icon192.png" />
            </Helmet>

            <div className={styles.productIntro} >
                <div className={styles.productIntroText} ref={headerRef}>
                    <Fade delay={200} className={styles.introTextWrapper}>
                        <p>Secure Shots, Sealed <span>Privacy.</span> <br />Capture Memories Carefree. <br />They are never revealed to <br />anyone else.</p>
                    </Fade>
                </div>
                <FileTransferDoodle className={styles.fileTransferDoodle} />
                <FlashIcon className={styles.flashIcon} />
                <RecordIcon className={styles.recordIcon} />

                <FloaterNavigator />
            </div>

            <FloaterDownload />


            <div className={styles.productUSP}>
                <p className={styles.uspHeading}>Zunu Camera: 100% <span>Privacy</span> for Photos and Videos<br />On <span>Device and Cloud.</span></p>

                <div className={`${styles.uspContainer} ${styles.usp1}`}>
                    <div className={styles.usptextContainer}>
                        <Fade fraction={0.2} cascade damping={0.2}>
                            <div className={styles.uspText}>

                                <p>Privacy Risks</p>
                                <p>in <span>Unencrypted</span> Camera Apps.</p>
                            </div>
                        </Fade>
                        {device != DEVICE.MOBILE &&
                            <div className={styles.uspTextCardsConatiner}>
                                <div className={styles.uspTextCard}><CardIcon1 /><p>Photos and videos saved in the Gallery/Photos are <span>unencrypted.</span></p></div>
                                <div className={styles.uspTextCard}><CardIcon2 /><p>Apps on phones have <span>permission to access</span> the Gallery/Photos, <span>revealing</span> all photos and videos to them.</p></div>
                                <div className={styles.uspTextCard}><CardIcon3 /><p>Cloud storage services, if enabled, <span>sync Gallery/Photos</span> content.</p></div>
                                <div className={styles.uspTextCard}><CardIcon4 /><p>Media in Gallery/Photos is at <span>risk</span> if the device is <span>lost</span> or its password is <span>compromised.</span></p></div>
                                <div className={styles.uspTextCard}><CardIcon5 /><p>Photos and videos are <span>vulnerable</span> to <span>ransomware</span>, <span>malware</span>, and <span>virus attacks</span>.</p></div>
                            </div>
                        }
                    </div>
                    <figure>
                        <img src={device == DEVICE.MOBILE ? `${USP1}` : `${USP1}`} alt="Zunu Mail USP" fetchPriority='high' loading='lazy' />
                    </figure>

                    {device == DEVICE.MOBILE &&
                        <div className={styles.uspTextCardsConatiner}>
                            <div className={styles.uspTextCard}><CardIcon1 /><p>Photos and videos saved in the Gallery/Photos are <span>unencrypted.</span></p></div>
                                <div className={styles.uspTextCard}><CardIcon2 /><p>Apps on phones have <span>permission to access</span> the Gallery/Photos, <span>revealing</span> all photos and videos to them.</p></div>
                                <div className={styles.uspTextCard}><CardIcon3 /><p>Cloud storage services, if enabled, <span>sync Gallery/Photos</span> content.</p></div>
                                <div className={styles.uspTextCard}><CardIcon4 /><p>Media in Gallery/Photos is at <span>risk</span> if the device is <span>lost</span> or its password is <span>compromised.</span></p></div>
                                <div className={styles.uspTextCard}><CardIcon5 /><p>Photos and videos are <span>vulnerable</span> to <span>ransomware</span>, <span>malware</span>, and <span>virus attacks</span>.</p></div>
                        </div>
                    }
                </div>

                <div className={`${styles.uspContainer} ${styles.usp2}`}>
                    <div className={styles.usptextContainer}>
                        <Fade fraction={0.2} cascade damping={0.2}>
                            <div className={styles.uspText}>

                                <p>Ultimate Privacy</p>
                                <p><span>for Photos and Videos with Zunu Camera</span></p>
                            </div>
                        </Fade>
                        {device != DEVICE.MOBILE &&
                            <div className={styles.uspTextCardsConatiner}>
                                <div className={styles.uspTextCard}><CardIcon6 /><p>Photos and Videos captured are <span>invisible</span> in the Phone's Gallery/Photos.</p></div>
                                <div className={styles.uspTextCard}><CardIcon7 /><p>3rd party apps with Gallery/Photos <span>access permissions cannot</span> see your private memories.</p></div>
                                <div className={styles.uspTextCard}><CardIcon8 /><p>If the Cloud storage service is enabled, then photos are <span>synced encrypted.</span></p></div>
                                <div className={styles.uspTextCard}><CardIcon9 /><p>Photos and Videos in the Cloud are <span>invisible to the Cloud</span> Storage service provider.</p></div>
                                <div className={styles.uspTextCard}><CardIcon10 /><p>Cloud Storage Service Providers <span>cannot run AI</span> on your Media. They are <span>Dark Web Safe.</span></p></div>
                                <div className={styles.uspTextCard}><CardIcon11 /><p>Photos and Videos in the Cloud are <span>safe</span> when your Cloud Storage Account is compromised.</p></div>
                                <div className={styles.uspTextCard}><CardIcon12 /><p>Photos and Videos are <span>protected</span> on the Phone when it's lost or its password is compromised.</p></div>
                                <div className={styles.uspTextCard}><CardIcon13 /><p>Photos and Videos on your Phone are <span>safe</span> from all Ransomware, Malware, and Virus Attacks.</p></div>
                            </div>
                        }
                    </div>
                    <figure>
                        <img src={device == DEVICE.MOBILE ? `${USP2}` : `${USP2}`} alt="Zunu Mail USP"  fetchPriority='high' loading='lazy'/>
                    </figure>
                    {device == DEVICE.MOBILE &&
                        <div className={styles.uspTextCardsConatiner}>
                            <div className={styles.uspTextCard}><CardIcon6 /><p>Photos and Videos captured are <span>invisible</span> in the Phone's Gallery/Photos.</p></div>
                            <div className={styles.uspTextCard}><CardIcon7 /><p>3rd party apps with Gallery/Photos <span>access permissions cannot</span> see your private memories.</p></div>
                            <div className={styles.uspTextCard}><CardIcon8 /><p>If the Cloud storage service is enabled, then photos are <span>synced encrypted.</span></p></div>
                            <div className={styles.uspTextCard}><CardIcon9 /><p>Photos and Videos in the Cloud are <span>invisible to the Cloud</span> Storage service provider.</p></div>
                            <div className={styles.uspTextCard}><CardIcon10 /><p>Cloud Storage Service Providers <span>cannot run AI</span> on your Media. They are <span>Dark Web Safe.</span></p></div>
                            <div className={styles.uspTextCard}><CardIcon11 /><p>Photos and Videos in the Cloud are <span>safe</span> when your Cloud Storage Account is compromised.</p></div>
                            <div className={styles.uspTextCard}><CardIcon12 /><p>Photos and Videos are <span>protected</span> on the Phone when it's lost or its password is compromised.</p></div>
                            <div className={styles.uspTextCard}><CardIcon13 /><p>Photos and Videos on your Phone are <span>safe</span> from all Ransomware, Malware, and Virus Attacks.</p></div>
                        </div>
                    }
                </div>

            </div>


            <div className={styles.productBanner}>
                <Fade cascade damping={0.4} fraction={0.1}>
                    <p className={styles.bannerHeading}>No <span>AI Invasion</span>. <br /> No <span>Dark Web</span> Threats.<br /> All Memories are Always <span>Private.</span></p>
                    <figure>
                        <img src={device == DEVICE.MOBILE ? `${BannerImageMobile}` : `${BannerImage}`} alt="Zunu Mail on all Devices"   fetchPriority='high' loading='lazy'/>
                    </figure>
                </Fade>
            </div>

            {/* <div className={styles.productCardsConatiner}>
                <div className={styles.productCard}>
                    <CardIcon1 />
                    <AttentionSeeker effect='pulse'>
                        <p>Experience Privacy beyond <span>End-to-End Encryption</span></p>
                    </AttentionSeeker>
                </div>
                <div className={styles.productCard}>
                    <CardIcon2 />
                    <AttentionSeeker effect='pulse' delay={400}>
                        <p>Add Zunu's <span>100% Privacy</span> to your current Email Address.</p>
                    </AttentionSeeker>
                </div>
                <div className={styles.productCard}>
                    <CardIcon3 />
                    <AttentionSeeker effect='pulse' delay={800}>
                        <p>Search and Quickly Retrieve Emails in the most privacy preserving way.</p>
                    </AttentionSeeker>
                </div>
                <div className={styles.productCard}>
                    <CardIcon4 />
                    <AttentionSeeker effect='pulse' delay={1200}>
                        <p>Emails are <span>Secured</span> from the moment you start composing. Privacy from the Go. </p>
                    </AttentionSeeker>
                </div>
                <div className={styles.productCard}>
                    <CardIcon5 />
                    <AttentionSeeker effect='pulse' delay={1600}>
                        <p><span>Emails are private</span> when your phone is lost, its password is compromised, and even when your email account is hacked.</p>
                    </AttentionSeeker>
                </div>
                <div className={styles.productCard}>
                    <CardIcon6 />
                    <AttentionSeeker effect='pulse' delay={2000}>
                        <p><span>Email Confidentiality</span> During Ransomware, Malware and Virus Attacks.</p>
                    </AttentionSeeker>
                </div>
            </div> */}

            <div className={styles.setApart}>
                <div className={styles.setApartHead}>
                    <Fade cascade damping={0.2}>
                        <p className={styles.heading}>What sets Zunu Camera Apart?</p>
                        <p className={styles.subHeading}>It's Unmatched Device-to-Device End-to-End Encryption for 100% Privacy.</p>
                    </Fade>
                </div>
                <figure>
                    <img src={device == DEVICE.MOBILE ? `${SetApaprtImageMobile}` : `${SetApaprtImage}`} alt="Zunu Drive explaination" fetchPriority='high' loading='lazy' />
                </figure>
            </div>

            <div className={styles.productFeatures}>
                <Fade>
                    <p className={styles.productFeaturesHeading}> Capture Moments, Keep <span>Privacy.</span></p>
                </Fade>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Fade>
                            <p className={styles.featureHeading}>Preserve Memories, Keep Them Yours.</p>
                        </Fade>
                        <ul>
                            <Fade cascade damping={0.2} delay={100} triggerOnce>
                                <li>Invisible Media.</li>
                                <li>Capture Freely.</li>
                                <li>Safe and Secure: Awkward selfies, wild party memories, and all other intimate moments stay hidden from everyone.</li>
                            </Fade>
                        </ul>
                    </div>

                    <figure>
                        <img src={CameraFeat1} alt="Safe from Dark web" fetchPriority='high' loading='lazy' />
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Fade>
                            <p className={styles.featureHeading}>Private on Device</p>
                        </Fade>
                        <ul>
                            <Fade cascade damping={0.2} delay={100} triggerOnce>
                                <li>With Zunu, Snap and Seal the Media on your phone.</li>
                                <li>3rd party apps with Gallery access permissions can't see your photos and Videos.</li>
                                <li>Lost your phone? Is its password compromised? Or did it fall into the drain? No worries; Your Photos and Videos are safe.</li>
                            </Fade>
                        </ul>
                    </div>

                    <figure>
                        <img src={CameraFeat2} alt="Safe from Dark web"  fetchPriority='high' />
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Fade>
                            <p className={styles.featureHeading}>Encrypted Photo Sync</p>
                        </Fade>
                        <ul>
                            <Fade cascade damping={0.2} delay={100} triggerOnce>
                                <li>Your photos stay private, even during syncing.</li>
                                <li>No more unwanted leaks of your cherished memories.</li>
                                <li>Experience total privacy.</li>
                            </Fade>
                        </ul>
                    </div>

                    <figure>
                        <img src={CameraFeat3} alt="Safe from Dark web"  fetchPriority='high' loading='lazy'/>
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Fade>
                            <p className={styles.featureHeading}>Private on Cloud</p>
                        </Fade>
                        <ul>
                            <Fade cascade damping={0.2} delay={100} triggerOnce>
                                <li>When photos and videos are uploaded to the cloud, the cloud service provider cannot see their content.</li>
                                <li>Even if your cloud account is breached, your photos stay private.</li>
                                <li>Losing your cloud account password won't expose your private photos.</li>
                            </Fade>
                        </ul>
                    </div>

                    <figure>
                        <img src={CameraFeat4} alt="Safe from Dark web"  fetchPriority='high' loading='lazy'/>
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Fade>
                            <p className={styles.featureHeading}>Take Control. Share Confidently.</p>
                        </Fade>
                        <ul>
                            <Fade cascade damping={0.2} delay={100} triggerOnce>
                                <li>Share your photos with complete control.</li>
                                <li>You decide who sees what, even after you've hit that share button.</li>
                                <li>Your pictures remain yours, and you have the ultimate say!</li>
                            </Fade>
                        </ul>
                    </div>

                    <figure>
                        <img src={CameraFeat5} alt="Safe from Dark web"  fetchPriority='high' loading='lazy'/>
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Fade>
                            <p className={styles.featureHeading}>Securely Shielded</p>
                        </Fade>
                        <ul>
                            <Fade cascade damping={0.2} delay={100} triggerOnce>
                                <li>Photos and Videos are immune to Ransomware, Malware, and Viruses.</li>
                                <li>Even under attack, your photos and videos stay protected.</li>
                            </Fade>
                        </ul>
                    </div>

                    <figure>
                        <img src={CameraFeat6} alt="Safe from Dark web"  fetchPriority='high' loading='lazy' />
                    </figure>
                </div>


            </div>

            <WhitePapers whitepapers={WHITEPAPERS.CAMERA} />
            <ProductFaqs data={QUESTIONS.camera.faq} heading="Frequently asked questions" background="#E6DDFF" url="/support?p=camera" />
            <Testimonials data={TESTIMONIALS.CAMERA} heading="What our Users say about Zunu Camera" />

        </div>
    )
}