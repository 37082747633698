import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useDownloadBtnStore } from "../../StateManager/DownloadBtnStore";

export const useTermshook = () => {

    const { isDownloadBtn, setIsDownloadBtn} = useDownloadBtnStore();

    const [searchParams] = useSearchParams();
    const query = searchParams.get('product');


    const topics = ['Camera', 'Mail', 'Message', 'Drive']
    const [active, setIsActive] = useState(topics[0])

    const [isCamera, setIsCamera] = useState(false)
    const [isMail, setIsMail] = useState(false)
    const [isMessage, setIsMessage] = useState(false)
    const [isDrive, setIsDrive] = useState(false)

    useEffect(() => {
     setIsDownloadBtn(false)

        if (query) {
            handleTopicClick(query)
        }
        else {
            handleTopicClick(topics[0])
            return
        }
    }, [])

    function handleTopicClick(topic: string) {
        setIsActive(topic.toLowerCase())
        setIsCamera(false)
        setIsMail(false)
        setIsMessage(false)
        setIsDrive(false)
        if (topic.toLowerCase() === topics[0].toLowerCase()) {
            setIsCamera(true)
            return
        }
        if (topic.toLowerCase() === topics[1].toLowerCase()) {
            setIsMail(true)
            return
        }
        if (topic.toLowerCase() === topics[2].toLowerCase()) {
            setIsMessage(true)
            return
        }
        if (topic.toLowerCase() === topics[3].toLowerCase()) {
            setIsDrive(true)
            return
        }
        else {
            handleTopicClick(topics[0])
            return
        }
    }


    return {
        topics, active, setIsActive,
        handleTopicClick,
        isCamera, isMail, isMessage, isDrive,
    }
}