import React, { useEffect, useState } from 'react'
import {RegistrationModal} from '../_shared/components/RegistrationModal/registrationModal.component';
import styles from './hackathon.module.scss';
import { useDownloadBtnStore } from '../../StateManager/DownloadBtnStore';

function Hackathon() {

    const { isDownloadBtn, setIsDownloadBtn} = useDownloadBtnStore();
    useEffect(() => {
        setIsDownloadBtn(true)
    }, [])

    const [isModal, setIsModal] = useState(false)

    function handleRegisterClick() {
        setIsModal(true);
    }

    return (
        <div className={styles.hackathonPage}>
            <div className={styles.eventIntro}>
                <p><i>"</i>Privacy for Bharat<i>"</i> Innovators Hackathons <span>2024</span></p>
                <button onClick={handleRegisterClick}>Register Now</button>
            </div>

            <div className={styles.eventDescription}>
                <div className={styles.descriptionCategory}>
                    <div className={styles.categoryHeading}><span>Challenge</span> Overview :</div>
                    <div className={styles.categoryText}>
                        <p>Welcome to the "Privacy for Bharat" Innovators Hackathon 2024, where innovation is driven by privacy! This exciting event is set to take place over 24-48 hours at the prestigious IIT Dharwad University Campus. </p>
                        <p>We invite passionate innovators, tech enthusiasts, and problem solvers to join us in addressing the pressing challenges of privacy in the digital age.</p>
                        <table>
                            <tr>
                                <th><strong>Event Details</strong></th>
                            </tr>
                            <tr>
                                <td>Duration</td>
                                <td>24-48 hours</td>
                            </tr>
                            <tr>
                                <td>Date</td>
                                <td>[To be Announced]</td>
                            </tr>
                            <tr>
                                <td>Location</td>
                                <td>IIT Dharwad University Campus</td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div className={styles.descriptionCategory}>
                    <div className={styles.categoryText}>
                        <div className={styles.cardsContainer}>
                            <div className={styles.card}>Privacy-Enhancing Technologies (PETs)</div>
                            <div className={styles.card}>Secure Data Handling and Storage</div>
                            <div className={styles.card}>AI-Driven Privacy Enhancements</div>
                        </div>
                    </div>
                </div>

                <div className={styles.descriptionCategory}>
                    <div className={styles.categoryHeading}>Why Privacy for Bharat?</div>
                    <div className={styles.categoryText}>
                        <p>In an era dominated by technology, it's crucial to rethink how applications are designed to ensure privacy remains intact. "Privacy for Bharat" aims to foster critical thinking and innovative solutions to protect user privacy while utilizing digital platforms.</p>
                    </div>
                </div>

                <div className={styles.descriptionCategory}>
                    <div className={styles.categoryHeading}>Who Can Participate?</div>
                    <div className={styles.categoryText}>
                        <p>The even is open to all faculty, students and enthusiasts across the nation to contribute their expertise and creativity in solving privacy problems.</p>
                    </div>
                </div>

                <div className={styles.descriptionCategory}>
                    <div className={styles.categoryHeading}>How to Participate?</div>
                    <div className={styles.categoryText}>
                        <p>Ready to take on the challenge? Click the "Register" button below and secure your spot in the Privacy for Bharat Innovators Hackathon 2024.</p>
                        <a onClick={handleRegisterClick}>Register Now</a>
                    </div>
                </div>

                <div className={styles.descriptionCategory}>
                    <div className={styles.categoryHeading}><span className={styles.rewards}>Prizes and Recognition</span></div>
                    <div className={styles.categoryText}>
                        <p>Exciting prizes, goodies, and certificates await the most innovative solutions! Showcase your skills, connect with like-minded individuals, and make a lasting impact on the future of privacy in Bharat.</p>
                    </div>
                </div>

                <div className={styles.descriptionCategory}>
                    <div className={styles.categoryHeading}>Get Social</div>
                    <div className={styles.categoryText}>
                        <p>Stay connected and engage with us on social media. Follow our pages for the latest updates, announcements, and behind-the-scenes content.</p>
                    </div>
                </div>

                <div className={styles.descriptionCategory}>
                    <div className={styles.categoryHeading}><span>Join the Conversation</span></div>
                    <div className={styles.categoryText}>
                        <p>Use our official event hashtag #PrivacyForBharat to join the conversation, share your progress, and connect with fellow participants.</p>
                        <p>Don't miss this opportunity to be part of a transformative event that puts privacy at the forefront of innovation. We look forward to seeing you at the "Privacy for Bharat" Innovators Hackathon 2024!</p>
                    </div>
                </div>

            </div>

            {isModal && <RegistrationModal setIsModal={setIsModal} subject={"Hackathon Registration"} />}
        </div>
    )
}

export default Hackathon