import SUITE_THUMBNAIL from '../../assets/webp/zunu_suite_thumbnail.webp'

export const VIDEOS={
    ZUNU: [
        {
            url: 'https://vimeo.com/978786224',
            thumbnail: SUITE_THUMBNAIL,
        },
    ],
    MAIL:[
        {
            url: 'https://vimeo.com/347119375',
            thumbnail: 'https://embed-ssl.wistia.com/deliveries/778c72e6124f6d6a0dd2b7a7ec5587b3.webp?image_crop_resized=1280x720'
        },
        {
            url: 'https://vimeo.com/449787858',
            thumbnail: 'https://marketplace.canva.com/EAFAMirCsX4/2/0/1600w/canva-purple-creative-livestream-youtube-thumbnail-X2eVuOzURSM.jpg'
        },
        {
            url: 'https://vimeo.com/252443587',
            thumbnail: 'https://embed-ssl.wistia.com/deliveries/fc9a51c9fef02f5d8016d88be6f9eb01.webp?image_crop_resized=1279x720'
        },
        {
            url: 'https://vimeo.com/347329657',
            thumbnail: 'https://uploads-ssl.webflow.com/624192c165989022d973ddbe/62b3574cc452383ebc657b4a_WhatsApp%20Image%202022-06-22%20at%2010.47.07%20PM.jpeg'
        },
        {
            url: 'https://vimeo.com/225519343',
            thumbnail: 'https://i.ytimg.com/vi/n5u8BR8y95w/maxresdefault.jpg'
        },
        {
            url: 'https://vimeo.com/180293809',
            thumbnail: 'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/b39cdb100448623.5f0a82f421c3c.jpg'
        },
        {
            url: 'https://vimeo.com/8697260',
            thumbnail: 'https://i.ytimg.com/vi/32gw7HrR2rY/maxresdefault.jpg'
        },
        {
            url: 'https://vimeo.com/336812686',
            thumbnail: 'https://cdn.vidyard.com/thumbnails/32421620/85sKSZFHwTQsLt4fCaVAqQ.jpg'
        },
        {
            url: 'https://vimeo.com/291164885',
            thumbnail: 'https://embed-ssl.wistia.com/deliveries/bf03977d298af9ea4c495e649b98f585.webp?image_crop_resized=1280x720'
        },
    ],
}