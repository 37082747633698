import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ENVIRONMENT } from '../../../environment';
import { useOsStore } from '../../../StateManager/OsStore';
import { PLATFORM } from '../../constants/constants';
import styles from './storeRedirect.module.scss';

const StoreRedirect = () => {
    const { application, code } = useParams();

    const { os } = useOsStore();

    useEffect(() => {
        const playStoreUrl = `https://play.google.com/store/apps/details?id=com.example.app&referrer=${code}`;

        let url = ENVIRONMENT.zunuDownloadUrls.android;

        if (os !== '') {
            if (application === 'mail') {
                if (os === PLATFORM.IOS || os === PLATFORM.MAC || os === PLATFORM.MACIntel) {
                    url = ENVIRONMENT.zunuDownloadUrls.mailAppStore
                }
                else {
                    url = ENVIRONMENT.zunuDownloadUrls.mailPlayStore
                }
            }

            if (application === 'drive') {
                if (os === PLATFORM.IOS || os === PLATFORM.MAC || os === PLATFORM.MACIntel) {
                    url = ENVIRONMENT.zunuDownloadUrls.driveAppStore
                }
                else {
                    url = ENVIRONMENT.zunuDownloadUrls.drivePlayStore
                }
            }

            if (application === 'message') {
                url = ENVIRONMENT.zunuDownloadUrls.messagePlayStore
            }

            if (application === 'camera') {
                if (os === PLATFORM.IOS || os === PLATFORM.MAC || os === PLATFORM.MACIntel) {
                    url = ENVIRONMENT.zunuDownloadUrls.cameraAppStore
                }
                else {
                    url = ENVIRONMENT.zunuDownloadUrls.cameraPlayStore
                }
            }
            
            window.location.href = url;
        }

    }, [application, code, os]);

    return (
        <div className={styles.redirect}>
            <p>Redirecting to your Store...</p>
        </div>
    );
};

export default StoreRedirect;
