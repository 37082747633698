import * as React from 'react';
import styles from './button.module.scss'

export interface ButtonProps {
    text: string,
    type?: string,
}

export default function Button (props: ButtonProps) {
  return (
    <div className={`${styles.button} ${props.type === 'secondary' && `${styles.secondary}`}`}>
      {props.text}
    </div>
  );
}
