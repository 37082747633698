import test from '../../assets/webp/usingZunu_illustration_dark.webp'

export const QUESTIONS= {
    zunu: {
        faq: [
            {   
                id: `1`,
                title: `How does Zunu protect my files from Ransomware?`,
                contents: [
                    `<div><strong>Your files in Zunu are:</strong></div>
                    <div>
                        <ul>
                            <li>Always encrypted, so ransomware cannot read your data.</li>
                            <li>Secured by Integrity Checks to protect backups.</li>
                            <li>Protected and encrypted in cloud backups.</li>
                            <li>Recoverable to any device you own.</li>
                        </ul>
                    </div>
                    `,
                ],
            },
            {   
                id: `2`,
                title: `Can Google or Microsoft read my content anymore?`,
                contents: [
                    `<div>No. Zunu encrypts the data you share or save on them. Service providers such as Google or Microsoft won't be able to read, profile or run AI’s on your content.</div>
                    <div>Your attachments, emails, files and its metadata are entirely encrypted. No third-party application can access your information. It can be accessed only through Zunu.</div>
                    `,
                ],
            },
            {   
                id: `3`,
                title: `What is a friendly ID? What does it let me do?`,
                contents: [
                    `
                    <div>A friendly ID allows other users to locate you on ZUNU quickly. You can set your friendly ID within the profile settings section. Instead of searching for someone using their email address, you can quickly and conveniently find them by entering their friendly ID.</div>
                    <div>Friendly ID enhances your privacy by anonymising your information to other users.</div>
                    `,
                ],
            },
            {   
                id: `4`,
                title: `How to add Zunu to USB based storages?`,
                contents: [
                    `<div>You can use Zunu to make your USBs private. The portable version requires no installation. You simply extract and run Zunu directly from your USB.</div>
                    <div><strong>Add Zunu to USB device:</strong>
                        <br />
                        <ol>
                            <li>Download Zunu Portable from <a href="https://zunuprivacy.com/downloads" >zunuprivacy.com/downloads</a>.</li>
                            <li>Extract the file to your USB device.</li>
                            <li>Run the Zunu application.</li>
                            <li>On USBs, you can use Zunu to share and get files in confidence.</li>
                        </ol>
                    </div>
                    `,
                ],
            },
            {   
                id: `5`,
                title: `How to set the visibility of my Zunu profile?`,
                contents: [
                    `<div>You can set different levels of visibility for your Zunu account. You can keep your profile photo and email ID hidden from other users. Zunu provides the world's best privacy for safeguarding your identity. </div>
                    <div><strong>To set the visibility of your profile:</strong>
                        <br />
                        <ol>
                            <li>Open Zunu Application in Desktop.</li>
                            <li>Click on the drop-down menu located near your profile icon.</li>
                            <li>Go to "Settings".</li>
                            <li>Under the "Profile Settings", Tap on the “Edit” option under E-mail ID.</li>
                            <li>Set the visibility of your email ID.</li>
                            <li>You can also set the visibility of your profile photo by clicking on the edit button on the photo.</li>
                        </ol>
                    </div>
                    `,
                ],
            },
        ],
        started: [
            {
                id: `1`,
                title: `What is Zunu?`,
                contents: [
                    `<div>Zunu is a Privacy platform that provides 100% privacy for your files and emails. It helps you keep your private life private on all your devices and platforms. Zunu hides your information in plain sight by adding a layer of zero knowledge encryption to your files and emails.</div>`,
                    // {
                    //     imgSrc: { src: test, altText: "key components" },
                    // },
                ],
            },
            {
                id: `2`,
                title: `How to install Zunu on a desktop or laptop?`,
                contents: [
                    `<div>Zunu can be installed on Windows, Mac and Linux operating systems. It is also available for your Android and iOS mobile phones. Zunu keeps in sync while providing the world’s best privacy and security.</div>
                    <div><strong>To install Zunu:</strong>
                    <br />
                    <ul>
                        <li>Visit <a href='https://zunuprivacy.com/downloads' >zunuprivacy.com/downloads</a>.</li> 
                        <li>Click on the operating system of your choice.</li>
                        <li>Once the file is downloaded, run the installation file to install it on your device.</li>
                    </ul>
                    </div>`,
                ],
            },
            {
                id: `3`,
                title: `How do I create an account on Zunu?`,
                contents: [
                    `<div>Zunu provides device to device encryption for all your files and emails. It takes end to end encryption one step forward by protecting your data on your device. When you use Zunu, your information is encrypted to your operating system. Zunu goes the extra mile to ensure your privacy on your device.</div>
                    <div>You can easily sign-up for Zunu with your email address. We authenticate your email address before we create an account.</strong></div>
                    <div><strong>To create an account:</strong>
                    <br />
                        <ul>
                            <li>Run Zunu on your device.</li> 
                            <li>Click on the Sign Up Button.</li>
                            <li>Agree to Terms and Conditions. You can also check our privacy policy here.</li>
                            <li>Click on the Next.</li>
                            <li>Enter your email address and click on Get Started.</li>
                            <li>Enter the 6-digit OTP you received in your email and Click on the Verify OTP button.</li>
                            <li>Set a password for your account. Please use a password that is different from the ones you use today.</li>
                            <li>Click on the Create Account.</li>
                        </ul>
                    </div>
                    `,
                ],
            },
            {
                id: `4`,
                title: `How to set-up Zunu for my emails and files?`,
                contents: [`
                    <div>Zunu adds 100% privacy for your emails and files. In order to achieve this you have to connect your accounts and devices to Zunu.</div>
                    <div><strong>Setting-up Zunu Mail:</strong>
                    <br />
                    <ul>
                        <li>Launch Zunu Mail after signing into Zunu.</li>
                        <li>Click on Add Account.</li>
                        <li>Select your email provider.</li>
                        <li>Select the checkbox to give permissions required for encrypted emails.</li>
                        <li>Click on Continue to add the account to Zunu.</li>
                    </ul>
                    </div>
                    <div><strong>Setting-up Zunu Drive:</strong>
                    <br />
                    <ul>
                        <li>Launch Zunu Drive after signing into Zunu.</li>
                        <li>Click on Add Cloud.</li>
                        <li>Select your cloud provider.</li>
                        <li>Set a name for your cloud account.</li>
                        <li>Click on Continue to to give permissions required for encrypted files.</li>
                    </ul>
                    </div>
                    <div>Zunu achieves 100% Privacy on multiple accounts. Add many accounts and access all of them accounts at once.</div>
                    `,
                ],
            },
        ],
        using: [
            {
                id: `1`,
                title: `How to add a file into Zunu?`,
                contents: [
                    `<div>Zunu lets your keep your files private on your device. It applies layer of  zero-knowledge encryption to protect your files from any kind of unauthorised access or processing.</div>
                    <div><strong>To upload a file:</strong>
                    <br />
                    <ul>
                        <li>Open Zunu Drive.</li>
                        <li>Click on the Add button, select add files.</li>
                        <li>Select the files you want to protect.</li>
                        <li>You can also right click to upload a file.</li>
                    </ul>
                    </div>
                    <div>We recommend that you drag and drop files as it moves the file to Zunu. It deletes the unencrypted file copy from your device. </div>
                    `,
                ],
            },
            {
                id: `2`,
                title: `How to sync a file in Zunu?`,
                contents: [
                    `<div>Zunu keeps all your information private and in sync. It syncs encrypted file blocks to cloud accounts and devices. </div>
                    <div><strong>To sync a file:</strong>
                    <br />
                    <ul>
                        <li>Open Zunu Drive.</li>
                        <li>Right-click on the file you want to sync.</li>
                        <li>Click on sync and select your cloud.</li>
                        <li>The icon on the file will change once the sync is completed.</li>
                    </ul>
                    </div>
                    <div>When you log into a new device, you can access your synced files by click on the cloud account.</div>
                    `,
                ],
            },
            {
                id: `3`,
                title: `How to share a file in Zunu?`,
                contents: [
                    `<div>Zunu uses zero knowledge on PKI infrastructure to provide the worlds best privacy when you share files.  You share from your existing accounts but with more privacy.</div>
                    <div><strong>To share a file:</strong>
                    <br />
                    <ul>
                        <li>Open Zunu Drive.</li>
                        <li>Right-click on the file you want to share.</li>
                        <li>Click on Share online. ( You need to add a cloud account to share.)</li>
                        <li>Enter the email ID of the person.</li>
                        <li>Select the person from the drop down.</li>
                        <li>Hit on Save to share the file.</li>
                    </ul>
                    </div>
                    <div>The files that are shared to you can be seen in the “Shared with me” section. You need to select the sender to see the items in the email.</div>
                    `,
                ],
            },
            {
                id: `4`,
                title: `How to send an encrypted email using Zunu?`,
                contents: [
                    `<div>Zunu Mail gets you 100% Privacy for the emails you send from Gmail and Outlook. It makes your emails private. It provides real-time device encryption for emails and attachments. Your information is kept encrypted at the source and destination.</div>
                    <div><strong>To send a private email:</strong>
                    <br />
                    <ul>
                        <li>Open Zunu Mail.</li>
                        <li>Click on Compose.</li>
                        <li>Enter the recipient's ID. (Click on invite if the user is not on Zunu yet.)</li>
                        <li>Compose the email.</li>
                        <li>Hit Send.</li>
                    </ul>
                    </div>
                    <div>Zunu sends encrypted emails from your account. You can see the encrypted text in the sent items of your Gmail or Outlook account. The contents your email are kept hidden from your service provider.</div>
                    `,
                ],
            },
            {
                id: `5`,
                title: `How to add attachments to you email in Zunu?`,
                contents: [
                    `<div>Zunu keeps your attachments encrypted on device both at the source and destination. You attach files that are kept safe in Zunu Drive. It maintains device to device confidentiality for all your attachments.</div>
                    <div><strong>To attach a file to an email:</strong>
                    <br />
                    <ul>
                        <li>Open Zunu Mail and Go to Compose.</li>
                        <li>Click on the attachment icon.</li>
                        <li>Pick the file from the Drive dialog box.</li>
                        <li>Click on Select to add it as an attachment.</li>
                    </ul>
                    </div>
                    <div>Your attachments are kept private from all services that handle your email. Email services, network providers and data interceptors will not be able view the contents of your email.</div>
                    `,
                ],
            },
            {
                id: `6`,
                title: `How to see your downloaded attachments?`,
                contents: [
                    `<div>Zunu keeps your attachments encrypted on device. The attachments you download are kept safe in Drive. They can be found in the Mail folder in your Drive.</div>
                    <div><strong>To see attachments:</strong>
                    <br />
                    <ul>
                        <li>Open the email with the attachment.</li>
                        <li>Hit on the download button to save it to your device.</li>
                        <li>Open Zunu Drive and search for the attachment.</li>
                        <li>You can also see the attachment in the Mail folder.</li>
                    </ul>
                    </div>
                    `,
                ],
            },
            {
                id: `7`,
                title: `How to recover after a ransomware attack?`,
                contents: [
                    `<div>Your files in Zunu are always encrypted. You do not have to worry about the privacy of your files if you devices fall victim to viruses and malwares like ransomware. The files you sync are kept safe in your backups using file integrity checks. It prevents corrupted files from reaching your backups.</div>
                    <div><strong>To recover from a ransomware attack:</strong>
                    <br />
                    <ul>
                        <li>Reset your device.</li>
                        <li>Download and install Zunu.</li>
                        <li>Log into Zunu and sync your files from the cloud.</li>
                    </ul>
                    </div>
                    <div>Zunu uses a different key for every email and file. It keep you safer using the principles of privacy by design.</div>
                    `,
                ],
            },
            {
                id: `8`,
                title: `Can Zunu Users send email, text or pictures to a Non-Zunu user?`,
                contents: [
                    `<div>Absolutely. Zunu users can send/share emails, text and files to non-Zunu users. To do so, the Zunu user can:</div>
                    <br />
                    <div>
                    <ul>
                        <li>Launch the Zunu app and navigate to Zunu Drive.</li>
                        <li>Locate the file with Zunu’s common search feature.</li>
                        <li>Right-click and select the file they want to share.</li>
                        <li>Create a link and assign a phone number for OTP-based verification.</li>
                        <li>Share the link with the recipient.</li>
                        <li>Non-Zunu Pod users can access files using the provided link after OTP verification.</li>
                    </ul>
                    </div>
                    <div>However, when a Non-Zunu user downloads content from your email or text, it gets saved on the device, breaking the encryption. This is risky because almost all apps on your device have permission to access your files from local storage and use them based on their need. To ensure your data is secure, using Zunu to protect your information at all times is recommended.</div>
                    `,
                ],
            },
        ],
        security: [
            {
                id: `1`,
                title: `How does Zunu provide device to device encryption?`,
                contents: [
                    `<div>Zunu uses privacy by design principles to provide device to device encryption. Your information stays encrypted even after you log in. it uses Fully Homomorphic Encryption (FHE) along with zero-knowledge AES encryption to process data without decryption. It goes beyond end to end by extending Privacy to the device you are using.</div>`,
                ],
            },
            {
                id: `2`,
                title: `How does Zunu protect my files from Ransomware?`,
                contents: [
                    `<div><strong>Your files in Zunu are:</strong>
                    <br />
                    <ul>
                        <li>Always encrypted, so ransomware cannot read your data.</li>
                        <li>Secured by Integrity Checks to protect backups.</li>
                        <li>Protected and encrypted in cloud backups.</li>
                        <li>Recoverable to any device you own.</li>
                    </ul>
                    </div>`,
                ],
            },
            {
                id: `3`,
                title: `Can Google or Microsoft read my content anymore?`,
                contents: [
                    `<div>No. Zunu encrypts the data you share or save on them. Service providers such as Google or Microsoft won't be able to read, profile or run AI’s on your content.</div>
                    <div>Your attachments, emails, files and its metadata are entirely encrypted. No third-party application can access your information. It can be accessed only through Zunu.</div>`,
                ],
            },
            {
                id: `4`,
                title: `How is zero-knowledge better?`,
                contents: [
                    `<div>Zero-knowledge fixes many of the security weaknesses associated with password based protocols. Unlike Google or Microsoft, Zunu does not have the technical capability to see your data. It does not store passwords and cannot gain access to your content.</div>
                    `,
                ],
            },
            {
                id: `5`,
                title: `What is a friendly ID? What does it let me do?`,
                contents: [`
                <div>A friendly ID allows other users to locate you on ZUNU quickly. You can set your friendly ID within the profile settings section. Instead of searching for someone using their email address, you can quickly and conveniently find them by entering their friendly ID.</div>
                <div>Friendly ID enhances your privacy by anonymising your information to other users.</div>
                    `,
                ],
            },
            {
                id: `6`,
                title: `Why doesn’t Zunu store my passwords? `,
                contents: [`
                <div>In Zunu, your passwords are discarded when you logout. This keeps your information private from us, services and anyone trying to go through your things. If you lose your password it cannot be recovered. We recommend setting up device based authentication to keep your sessions safe.</div>
                    `,
                ],
            },
            {
                id: `7`,
                title: `Can you embed Zunu Applications with Outlook or Gmail?`,
                contents: [`
                <div>Yes, Zunu can be embedded with platforms like Outlook and Gmail. However, it raises privacy concerns. Any file downloaded from either platform gets stored in the device’s local storage. Apps on your phone can access these files, and in the event of a malware or virus attack, these files can also be exposed to third parties. Hence, an enclave that stores your personal files safely is the solution. However, Google and Microsoft don’t allow Zunu or any other third party to create an enclave within their system. Opting for Zunu as your communication platform guarantees that your data is shielded and encrypted, ensuring that only you can access it.</div>
                <br />
                <div>Zunu works on end-to-end, device-to-device encryption. Your files and communication remain encrypted and protected in transit and after delivery. When you download files from Zunu, they get stored within the Zunu enclave, ensuring no one can access them for their benefit. With Zunu, you get complete ownership and control over your data. Something that stands out with both Microsoft and Google.</div>
                    `,
                ],
            },
        ],
    },
    camera: {
        faq: [
            {
                id: `1`,
                title: `What is Zunu Camera?`,
                contents: [
                    `<div>Zunu Camera is a privacy-first Camera that keeps your photos and videos private. It keeps the trackers out when you click, save and backup your photos. Every click is protected on your device and cloud using zero-knowledge encryption.</div>`,
                ],
            },
            {
                id: `2`,
                title: `Why does Camera open the Play/ App store when I tap on a photo?`,
                contents: [
                    `<div>Zunu Camera saves your photos and videos in Zunu Drive. It lets you achieve 100% Privacy from your gallery and apps installed on your phone.</div>
                    <div>To see your clicks you need to download and sign in on Zunu Drive. It is your personal encrypted file manager that keeps your files private on the device and cloud.</div>
                    `,
                ],
            },
            {
                id: `3`,
                title: `Can Google or Microsoft see the photos I take with Zunu Camera?`,
                contents: [
                    `<div>No, the photos and videos you click with Zunu Camera cannot be seen by your service provider. It is stored using a secure file system that hides the file names, metadata and folder hierarchy. The encrypted cipher-text cannot used to track, process and profile your information.</div>
                    `,
                ],
            },
            {
                id: `4`,
                title: `Does Zunu Camera remove EXIF metadata and location tags from my photos?`,
                contents: [
                    `<div>By default, Zunu Camera removes all personal identifiers in your photos and videos. This includes all user specific information such as your location, device and hardware.</div>
                    `,
                ],
            },
            {
                id: `5`,
                title: `Can the apps on my phone see the photo I take with Zunu Camera?`,
                contents: [
                    `<div>No, the apps on your phone will not be able to see the photos you take with Zunu Camera. You can see it only through Zunu Drive. If you want to make a photo available to an app, you will have to download an unencrypted copy to make it available.</div>
                    `,
                ],
            },
        ],
        started: [
            {
                id: `1`,
                title: `What is Zunu Camera?`,
                contents: [
                    `<div>Zunu Camera is a privacy-first Camera that keeps your photos and videos private. It keeps the trackers out when you click, save and backup your photos. Every click is protected on your device and cloud using zero-knowledge encryption.</div>`,
                ],
            },
            {
                id: `2`,
                title: `How to install the Zunu Camera?`,
                contents: [
                    `<div>Zunu Camera is available for both Android and Apple iOS devices. It lets you capture moments with 100% Privacy.</div>
                    <div><strong>To install Zunu Camera:</strong>
                    <br />
                    <ul>
                        <li>Go to the Play Store/ App Store.</li>
                        <li>Search for Zunu Camera.</li>
                        <li>Download and install Zunu Camera on your device.</li>
                    </ul>
                    </div>
                    <div>The photos and videos you take from Zunu Camera are private by default. They are kept encrypted on your phone — away from your gallery and installed apps.</div>
                    `,
                ],
            },
            {
                id: `3`,
                title: `How do I sign-up for the Zunu Camera?`,
                contents: [
                    `<div>Zunu Camera lets you capture moments with 100% Privacy. Zunu shields your photos and videos from hackers and malware. Zunu provides device and platform encryption to keep your memories private.</div>
                    <div><strong>To sign-up for Zunu Camera:</strong>
                    <br />
                    <ul>
                        <li>Open Zunu Camera from your phone.</li> 
                        <li>Hit on Sign Up.</li>
                        <li>Enter your email ID.</li>
                        <li>Enter the OTP sent to your email.</li>
                        <li>Hit on Verify.</li>
                        <li>Set a Password for your account.</li>
                        <li>Hit on Create Account.</li>
                    </ul>
                    </div>
                    <div>Zunu Camera uses biometric authentication. After you set up your account, you can easily log in using the biometrics you have set up on your phone.</div>
                    <div>Your captures from Zunu Camera do not show up in your gallery. It is encrypted the moment you click. You can see you photos and videos on Zunu Drive.</div>
                    `,
                ],
            },
            {
                id: `4`,
                title: `How to sign out of Zunu Camera?`,
                contents: [
                    `<div>You can easily sign out of Zunu Camera by clicking on your profile icon in the app.</div>
                    <div><strong>To sign-out of Zunu Camera:</strong>
                    <br />
                    <ul>
                        <li>Click on the profile icon on the top left.</li>
                        <li>Select log out.</li>
                    </ul>
                    </div>
                    <div>Zunu does not store your passwords. Its is zero-knowledge service that keeps your data private to you.</div>
                    `,
                ],
            },
        ],
        using: [
            {
                id: `1`,
                title: `How to take a photo or video with Zunu Camera?`,
                contents: [
                    `<div>You can capture stunning photos and videos with Zunu Camera. You do not compromise on the privacy and quality in both primary and selfie modes. </div>
                    <div><strong>To take a photo/video using Zunu Camera:</strong>
                    <br />
                    <ul>
                        <li>Open the Zunu Camera.</li>
                        <li>Sign in using your biometrics or password.</li>
                        <li>On the top, toggle to Video mode.</li>
                        <li>Tap on record button.</li>
                    </ul>
                    </div>
                    <div>Your videos are encrypted when it is written to disk. To view the videos you take, tap on the thumbnail on the bottom left top open Zunu Drive.</div>
                    `,
                ],
            },
            {
                id: `2`,
                title: `Why does Camera open the Play/ App store when I tap on a photo?`,
                contents: [
                    `<div>Zunu Camera saves your photos and videos in Zunu Drive. It lets you achieve 100% Privacy from your gallery and apps installed on your phone. </div>
                    <divTo see your clicks you need to download and sign in on Zunu Drive. It is your personal encrypted file manager that keeps your files private on the device and cloud.</div>
                    `,
                ],
            },
            {
                id: `3`,
                title: `What is the highest resolution I can record in Zunu Camera?`,
                contents: [
                    `<div>Zunu Camera captures photos and videos using the hardware on your phone. So, the resolution would depend on the hardware’s capability. We constantly update the app to bring more features. If there is a specific feature you are interested in, please let us know.</div>
                    `,
                ],
            },
            {
                id: `4`,
                title: `How to see the photos taken on Zunu Camera?`,
                contents: [
                    `<div>The photos and videos you take with Zunu Camera are encrypted when stored. It is kept safe and private in Zunu Drive.</div>
                    <div><strong>To view your photos and videos :</strong>
                    <br />
                    <ul>
                        <li>Open Zunu Camera.</li>
                        <li>Tap on the thumbnail on the bottom left.</li>
                        <li>You can see your clicks in Zunu Drive. </li>
                    </ul>
                    </div>
                    <div>Your clicks are by default not synced to the cloud. You can see them in “This Device” section or Gallery inside Zunu Drive.</div>
                    `,
                ],
            },
            {
                id: `5`,
                title: `How to share the photos I took from Zunu Camera?`,
                contents: [
                    `<div>You can share the photos you take using Zunu Drive. Your shares are device to device encrypted.</div>
                    <div><strong>Share photos from Zunu Drive:</strong>
                    <br />
                    <ul>
                        <li>Open Zunu Drive.</li>
                        <li>Locate the picture in the gallery.</li>
                        <li>Tap and hold the file or tap on the three dots to open the drop down menu.</li>
                        <li>Select the "Share" option.</li>
                        <li>Enter the receiver’s email ID.</li>
                        <li>Tap on the person from the user list.</li>
                        <li>Hit on "Share".</li>
                    </ul>
                    </div>
                    <div>Your photo would be first synced to the default cloud before it is shared. Zunu keeps your shares encrypted on the sender’s and receiver’s end. </div>
                    `,
                ],
            },
        ],
        security: [
            {
                id: `1`,
                title: `How does using Zunu Camera keep me safe from hackers?`,
                contents: [
                    `<div>Zunu is built on Privacy by Design principles. It encrypts your photos and videos the moment you click them. Hackers and malware will not be able to see the files you keep in Zunu. Each file is encrypted with a unique key that is not stored anywhere. This layering keeps your information safe and confidential.</div>`,
                ],
            },
            {
                id: `2`,
                title: `Can Google or Microsoft see the photos I take with Zunu Camera?`,
                contents: [
                    `<div>No, the photos and videos you click with Zunu Camera cannot be seen by your service provider. It is stored using a secure file system that hides the file names, metadata and folder hierarchy. The encrypted cipher-text cannot used to track, process and profile your information.</div>`,
                ],
            },
            {
                id: `3`,
                title: `Does Zunu Camera remove EXIF metadata and location tags from my photos?`,
                contents: [
                    `<div>By default, Zunu Camera removes all personal identifiers in your photos and videos. This includes all user specific information such as your location, device and hardware.</div>`,
                ],
            },
            {
                id: `4`,
                title: `Can the apps on my phone see the photo I take with Zunu Camera?`,
                contents: [
                    `<div>No, the apps on your phone will not be able to see the photos you take with Zunu Camera. You can see it only through Zunu Drive. If you want to make a photo available to an app, you will have to download an unencrypted copy to make it available.</div>`,
                ],
            },
        ],
    },
    mail: {
        faq: [
            {
                id: `1`,
                title: `Can my email service read or profile the emails sent from Zunu Mail?`,
                contents: [
                    `<div>No, emails sent from Zunu are protected by zero-knowledge encryption. Only the sender and intender recipient will be able to see the Subject, Body and Attachments associated with the email. </div>`,
                ],
            },
            {
                id: `2`,
                title: `How does Zunu Mail provide better privacy than my email service?`,
                contents: [
                    `<div>Current email services provide security of emails in transport and storage. They do not provide security and privacy when emails are processed. The content index reveals a lot of information about the content of the email. Just like post cards, emails reveal all their content to every person who handles it. Zunu fixes this flaw by keeping the content of your emails away from all prying eyes.</div>`,
                ],
            },
            {
                id: `3`,
                title: `What are the security components of Zunu Mail?`,
                contents: [
                    `<div>Zunu Mail integrates with your current email account. Zunu components include an encrypted Content Index and a Key Manager. An Encrypted Content Index indexes the emails and stores the data of the emails in an encrypted form. It allows users to browse and search through encrypted content without decryption. The Key Manager is used for local management and encrypted storage of keys for the emails.</div>
                    <div>
                        <table>
                            <tr>
                                <th>Components</th>
                                <th>Description</th>
                            </tr>
                            <tr>
                                <td>Content Index</td>
                                <td>An Encrypted Content Index for storing indexed content of emails, including metadata, body, and attachments. Content Index is used for supporting “Search over Encrypted Text.”</td>
                            </tr>
                            <tr>
                                <td>Key Manager</td>
                                <td>Used to store User Keys. All keys stored in the Key Manager are stored in an encrypted form.</td>
                            </tr>
                        </table>
                    </div>
                    `,
                ],
            },
            {
                id: `4`,
                title: `How does Zunu Mail keep my attachment encrypted on devices?`,
                contents: [
                    `<div>Attachments in Zunu are always encrypted. When you download an attachment it is saved in Zunu Drive for safe keeping. Similarly, you add encrypted attachments that arekept safe in Zunu Drive. Attachments are always kept secure within the private enclave of Zunu. It keeps you safe even if a machine is lost or your email credentials are compromised.</div>`,
                ],
            },
            {
                id: `5`,
                title: `How to add a multiple email accounts to Zunu Mail?`,
                contents: [
                    `<div>Zunu Mails lets you connect multiple email accounts. You can access and achieve privacy for all your accounts using one Zunu account. Once linked, your account and email activities will be private and in sync.</div>
                    <div><strong>To add an additional account:</strong>
                    <br />
                    <ul>
                        <li>Open Zunu Mail.</li>
                        <li>Tap on your profile picture on the top right.</li>
                        <li>Select the add account option.</li>
                        <li>Select your email service.</li>
                        <li>In the new page that opens, provide Zunu the permissions required to compose, send and access encrypted emails.</li>
                    </ul>
                    </div>
                    <div>You can navigate between your accounts in Zunu Mail by clicking on the profile icon on the top right.</div>
                    `,
                ],
            },
        ],
        started: [
            {
                id: `1`,
                title: `What is Zunu Mail?`,
                contents: [
                    `<div>Zunu makes your emails from Gmail and Outlook private. It is your private email system that encrypts your email conversations. With Zunu, you get 100% Privacy for your emails and attachments.</div>
                    <div>Built on Privacy by Design principles, Zunu shields your emails from snoopers and hackers. Zunu provides device and platform encryption to keep your emails private.</div>
                    `,
                ],
            },
            {
                id: `2`,
                title: `How to install Zunu Mail?`,
                contents: [
                    `<div>With Zunu Mail, you get 100% Privacy for the emails you send from your Gmail and Outlook accounts. It is encryption that makes your conversations private to hackers and service providers. </div>
                    <div><strong>To install Zunu Mail:</strong>
                    <br />
                    <ul>
                        <li>Go to the Play Store or App Store.</li>
                        <li>Search and select “Zunu Mail”.</li>
                        <li>Tap on the Download button.</li>
                    </ul>
                    </div>
                    <div>You can also install Zunu Mail from <a href="https://zunuprivacy.com/download" >https://zunuprivacy.com/download</a>.</div>
                    `,
                ],
            },
            {
                id: `3`,
                title: `How do you sign up for Zunu Mail?`,
                contents: [
                    `<div>Signing up for Zunu Mail is incredibly easy. All you need to do is authenticate your email ID and set a password.</div>
                    <div><strong>To sign up for Zunu Mail:</strong>
                    <br />
                    <ul>
                        <li>Open Zunu Mail on your phone.</li>
                        <li>Click on Sign Up.</li>
                        <li>Enter your email ID.</li>
                        <li>Enter the 6-digit OTP Sent to the email ID.</li>
                        <li>Click on Proceed.</li>
                        <li>Set a password for your account.</li>
                        <li>Click on Proceed.</li>
                    </ul>
                    </div>
                    <div>You can add multiple email accounts to Zunu Mail. Simply tap on Add Account to link your email service.</div>
                    `,
                ],
            },
            {
                id: `4`,
                title: `How do I set-up my Zunu Mail account?`,
                contents: [
                    `<div>Zunu Mail achieves 100% Privacy for the emails you send. You can achieve this connecting your email services with Zunu.</div>
                    <div><strong>Setting up Zunu Mail:</strong>
                    <br />
                    <ul>
                        <li>Sign into Zunu Mail.</li>
                        <li>Click on Add Account.</li>
                        <li>Select your email provider.</li>
                        <li>In the new window, click on Continue to to give the permissions for encrypted emails.</li>
                    </ul>
                    </div>
                    <div>You can now use Zunu Mail to send and receive encrypted emails from any device.</div>
                    `,
                ],
            },
        ],
        using: [
            {
                id: `1`,
                title: `How to send a private email using Zunu Mail?`,
                contents: [
                    `<div>Zunu protects your emails with zero-knowledge encryption. Your emails can be seen only by intended recipients. </div>
                    <div><strong>To send a private email:</strong>
                    <br />
                    <ul>
                        <li>Open Zunu Mail.</li>
                        <li>Tap on Compose.</li>
                        <li>Enter the recipient’s email ID. (Hit on invite if the user is not on Zunu yet.)</li>
                        <li>Compose the email.</li>
                        <li>Tap on Send.</li>
                    </ul>
                    </div>
                    <div>Zunu Mail encrypts all parts of an email. The subject, body and attachments are encrypted before it is sent. Intruders and email services cannot read the emails you send. </div>
                    `,
                ],
            },
            {
                id: `2`,
                title: `How to read an email sent from Zunu Mail?`,
                contents: [
                    `<div>Emails sent with Zunu are encrypted to email platforms such as Google and Microsoft. You can easily recognise Zunu encrypted emails based on the subject line. It will have the term ZMENCRYPT. They cannot be read from your regular inbox as they are protected. You need into sign into Zunu Mail to read them.</div>`,
                ],
            },
            {
                id: `3`,
                title: `How do I change my password in Zunu Mail?`,
                contents: [
                    `<div>Zunu does not store the passwords of its users to provide the highest level of privacy. It is an encrypted zero-knowledge system. You must remember your old password to change it.</div>
                    <div><strong>To change your password in Zunu Mail:</strong>
                    <br />
                    <ul>
                        <li>Open Zunu Mail.</li>
                        <li>Tap on the hamburger menu on the top left corner.</li>
                        <li>Select the Settings option.</li>
                        <li>Tap on "Change Password".</li>
                        <li>Enter your old password followed by your new password. </li>
                        <li>Tap on "Confirm".</li>
                    </ul>
                    </div>
                    <div>Once you update your passwords, your current sessions on Zunu will expire. You will need to log in again.</div>
                    `,
                ],
            },
            {
                id: `4`,
                title: `How to add a multiple email accounts to Zunu Mail?`,
                contents: [
                    `<div>Zunu Mails lets you connect multiple email accounts. You can access and achieve privacy for all your accounts using one Zunu account. Once linked, your account and email activities will be private and in sync.</div>
                    <div><strong>To add an additional account:</strong>
                    <br />
                    <ul>
                        <li>Open Zunu Mail.</li>
                        <li>Tap on your profile picture on the top right.</li>
                        <li>Select the add account option.</li>
                        <li>Select your email service.</li>
                        <li>In the new page that opens, provide Zunu the permissions required to compose, send and access encrypted emails.</li>
                    </ul>
                    </div>
                    <div>You can navigate between your accounts in Zunu Mail by clicking on the profile icon on the top right.</div>
                    `,
                ],
            },
            {
                id: `5`,
                title: `How do I sign out from Zunu Mail?`,
                contents: [
                    `<div>Every session on Zunu is authenticated using your biometrics such as Face ID or fingerprint. You sessions are kept safe even if you don’t specifically sign out.</div>
                    <div><strong>To sign out of Zunu Mail:</strong>
                    <br />
                    <ul>
                        <li>Open Zunu Mail.</li>
                        <li>Tap on the hamburger menu on the top left.</li>
                        <li>Select the sign out option.</li>
                    </ul>
                    </div>
                    <div>You will be required to enter your email ID and password to sign back in.</div>
                    `,
                ],
            },
            {
                id: `6`,
                title: `Where can I find my deleted emails in Zunu?`,
                contents: [
                    `<div>Even the emails you delete and kept confidential using Zunu. It is encrypted and protected in the trash of your email service.</div>
                    <div><strong>To find deleted emails:</strong>
                    <br />
                    <ul>
                        <li>Open Zunu Mail.</li>
                        <li>Tap on the hamburger menu on the top left.</li>
                        <li>Go to the trash section.</li>
                    </ul>
                    </div>
                    <div>Zunu adds privacy to emails in the entirety of its journey. From it’s drafting, composition, archival and deletion.</div>
                    `,
                ],
            },
            {
                id: `7`,
                title: `How do I restore a deleted email in Zunu Mail?`,
                contents: [
                    `<div>You can easily restore an email from the trash section of your inbox.</div>
                    <div><strong>To restore an email:</strong>
                    <br />
                    <ul>
                        <li>Open the Zunu Mail App.</li>
                        <li>Tap on the hamburger menu on the top left.</li>
                        <li>Tap and hold on the email you want to restore.</li>
                        <li>Hit the restore icon on the top right.</li>
                    </ul>
                    </div>
                    <div>The deleted email will be restored back to your inbox.</div>
                    `,
                ],
            },
            {
                id: `8`,
                title: `How many days do my deleted mail stay in the trash?`,
                contents: [
                    `<div>Zunu Mails is your private layer of encryption on your current email services. The properties associated with an email would be the same as it is today. Both Google and Microsoft retain emails for 30 days. Hence, the same is applicable for the emails sent from Zunu. </div>`,
                ],
            },
            {
                id: `9`,
                title: `What is the maximum size on encrypted attachment in Zunu mail?`,
                contents: [
                    `<div>Zunu Mail currently supports encrypted attachment in Zunu Drive. Today, the maximum attachment size allowed is 15MB. This means you can attach any type of file up to 15MB.</div>`,
                ],
            },
        ],
        security: [
            {
                id: `1`,
                title: `Can my email service read or profile the emails sent from Zunu Mail?`,
                contents: [
                    `<div>No, emails sent from Zunu are protected by zero-knowledge encryption. Only the sender and intender recipient will be able to see the Subject, Body and Attachments associated with the email. </div>`,
                ],
            },
            {
                id: `2`,
                title: `What are the security components of Zunu Mail?`,
                contents: [
                    `<div>Zunu Mail integrates with your current email account. Zunu components include an encrypted Content Index and a Key Manager. An Encrypted Content Index indexes the emails and stores the data of the emails in an encrypted form. It allows users to browse and search through encrypted content without decryption. The Key Manager is used for local management and encrypted storage of keys for the emails.</div>
                    <div>
                        <table>
                            <tr>
                                <th>Components</th>
                                <th>Description</th>
                            </tr>
                            <tr>
                                <td>Content Index</td>
                                <td>An Encrypted Content Index for storing indexed content of emails, including metadata, body, and attachments. Content Index is used for supporting “Search over Encrypted Text.”</td>
                            </tr>
                            <tr>
                                <td>Key Manager</td>
                                <td>Used to store User Keys. All keys stored in the Key Manager are stored in an encrypted form.</td>
                            </tr>
                        </table>
                    </div>
                    `,
                ],
            },
            {
                id: `3`,
                title: `Are all email components encrypted by default?`,
                contents: [
                    `<div>Yes, all parts of an email are encrypted by default in Zunu. The Subject, Body and Attachments are encrypted. It lets you achieve the highest level of privacy against unauthorised access and profiling. It makes your current email accounts safer by adding a shield that keeps you safe even in case of a break-in.</div>`,
                ],
            },
            {
                id: `4`,
                title: `How does Zunu Mail keep my attachment encrypted on devices?`,
                contents: [
                    `<div>Attachments in Zunu are always encrypted. When you download an attachment it is saved in Zunu Drive for safe keeping. Similarly, you add encrypted attachments that arekept safe in Zunu Drive. Attachments are always kept secure within the private enclave of Zunu. It keeps you safe even if a machine is lost or your email credentials are compromised.</div>`,
                ],
            },
            {
                id: `5`,
                title: `How does Zunu Mail provide better privacy than my email service?`,
                contents: [
                    `<div>Current email services provide security of emails in transport and storage. They do not provide security and privacy when emails are processed. The content index reveals a lot of information about the content of the email. Just like post cards, emails reveal all their content to every person who handles it. Zunu fixes this flaw by keeping the content of your emails away from all prying eyes.</div>`,
                ],
            },
        ],
    },
    message: {
        faq: [
            {
                id: `1`,
                title: `What is Zunu Message?`,
                contents: [
                    `<div>Zunu Message makes your texts private by adding a layer of zero knowledge encryption. You get 100% Privacy for the texts you send. Be it your friends or family, your conversations stay hidden from all prying eyes.</div>
                    <div>We use patented technology to keep messages private from your carrier and the apps installed on your phone.</div>
                    `,
                ],
            },
            {
                id: `2`,
                title: `How to invite someone to Zunu Message?`,
                contents: [
                    `<div>Zunu Message prevents your messages from being tracked or profiled. It keeps your conversations the way it should be —private to you and your friends. </div>
                    <div><strong>To invite a friend:</strong>
                    <br />
                    <ul>
                        <li>Open the Zunu Message.</li>
                        <li>Tap on the + button on the bottom right.</li>
                        <li>Search for the contact you want to invite.</li>
                        <li>Hit on the Invite option.</li>
                    </ul>
                    </div>
                    <div>It will compose an invite text that you can send to your contact.</div>
                    `,
                ],
            },
            {
                id: `3`,
                title: `How does Zunu Message encrypt the texts I send?`,
                contents: [
                    `<div>Zunu is built on Privacy by Design principles. It uses Fully Homomorphic Encryption (FHE) along with the industry standard of AES encryption, in a zero-knowledge environment. This shield achieves  privacy of your texts from hackers , malware and nosy services. </div>`,
                ],
            },
            {
                id: `4`,
                title: `Can my network carrier see the texts sent from Zunu Message?`,
                contents: [
                    `<div>No, your texts are encrypted from one device to the other. The phone’s operating system, apps and network carrier will not be able to make sense of the messages you send. </div>`,
                ],
            },
            {
                id: `5`,
                title: `Can the apps on my phone see my texts in Zunu Message?`,
                contents: [
                    `<div>No, the apps installed on your phone cannot see the texts sent from Zunu. It is encrypted within the app before being stored and sent from the device.</div>`,
                ],
            },
        ],
        started: [
            {
                id: `1`,
                title: `What is Zunu Message?`,
                contents: [
                    `<div>Zunu Message makes your texts private by adding a layer of zero knowledge encryption. You get 100% Privacy for the texts you send. Be it your friends or family, your conversations stay hidden from all prying eyes.</div>
                    <div>We use patented technology to keep messages private from your carrier and the apps installed on your phone.</div>
                    `,
                ],
            },
            {
                id: `2`,
                title: `How do I Install the Zunu Message?`,
                contents: [
                    `<div>With Zunu Message, you enjoy a private messaging experience on all your Android devices. You get encryption that ensures the safety of your texts from hackers and malware. </div>
                    <div><strong>To install Zunu Message: </strong>
                    <br />
                    <ul>
                        <li>Go to the Play Store.</li>
                        <li>Search and select “Zunu Message”.</li>
                        <li>Tap on the Install button.</li>
                    </ul>
                    </div>
                    <div>You can also install Zunu Message from <a href="https://zunuprivacy.com/download" >https://zunuprivacy.com/download</a>.</div>
                    `,
                ],
            },
            {
                id: `3`,
                title: `How do I sign up for the Zunu Message ?`,
                contents: [
                    `<div>Signing up for Zunu Message is incredibly easy. All you need to do is authenticate your email ID and set a password. </div>
                    <div><strong>To sign up on Zunu Message:</strong>
                    <br />
                    <ul>
                        <li>Open the Zunu Message .</li>
                        <li>Click on the Sign Up Button.</li>
                        <li>Enter your email ID.</li>
                        <li>Enter the 6-digit OTP sent to your email.</li>
                        <li>Set a password for your account.</li>
                        <li>Click on Proceed.</li>
                        <li>Enter your Mobile Number.</li>
                    </ul>
                    </div>
                    <div>Zunu Message uses biometric authentication. After you set up your account, you can easily log in using the biometrics you have set up on your phone.</div>
                    `,
                ],
            },
        ],
        using: [
            {
                id: `1`,
                title: `How to start a New Conversation in the Zunu Message?`,
                contents: [
                    `<div>Zunu keeps your texts confidential — beyond the reach of hackers, malwares and installed apps. Every message you send is encrypted, keeping it safe on the phone and with your carrier.</div>
                    <div><strong>To start a new conversation:</strong>
                    <br />
                    <ul>
                        <li>Open the Zunu Message.</li>
                        <li>Tap on the + button on the bottom right.</li>
                        <li>Search and select the recipient. (You can also send invites here).</li>
                        <li>Tap on their name to compose a private message.</li>
                        <li>Hit on the Send icon.</li>
                    </ul>
                    </div>
                    <div>Zunu provides device to device encryption for your messages. Your texts are protected on both the sender’s and receiver’s device.</div>
                    `,
                ],
            },
            {
                id: `2`,
                title: `How to update my number in Zunu Message?`,
                contents: [
                    `<div>In order to link a new number, you must first deregister the number linked to your Zunu account. You can manage your number in the profile settings.</div>
                    <div><strong>To deregister a number: </strong>
                    <br />
                    <ul>
                        <li>Open the Zunu Message.</li>
                        <li>Tap on the Profile Picture in the top right.</li>
                        <li>Select the Unregister Number option.</li>
                        <li>Tap on the Confirm Button to deregister.</li>
                    </ul>
                    </div>
                    <div>You can now link a new number with your Zunu account.</div>
                    `,
                ],
            },
            {
                id: `3`,
                title: `How do I sign out from the Zunu Message?`,
                contents: [
                    `<div>Zunu Message takes strong measures to safeguard the privacy of your texts. It requires your biometrics to open Zunu after every exit. Your texts stay private even if you don’t sign out.</div>
                    <div><strong>To sign out of Zunu:</strong>
                    <br />
                    <ul>
                        <li>Open the Zunu Message.</li>
                        <li>Tap on the Profile Picture in the top right.</li>
                        <li>Select the Sign Out option.</li>
                        <li>Click on the Confirm.</li>
                    </ul>
                    </div>
                    <div>After sign out, you will need to enter your Zunu email ID and password to log back in. </div>
                    `,
                ],
            },
            {
                id: `4`,
                title: `How to invite someone to Zunu Message?`,
                contents: [
                    `<div>Zunu Message prevents your messages from being tracked or profiled. It keeps your conversations the way it should be —private to you and your friends. </div>
                    <div><strong>To invite a friend:</strong>
                    <br />
                    <ul>
                        <li>Open the Zunu Message.</li>
                        <li>Tap on the + button on the bottom right.</li>
                        <li>Search for the contact you want to invite.</li>
                        <li>Hit on the Invite option.</li>
                    </ul>
                    </div>
                    <div>It will compose an invite text that you can send to your contact.</div>
                    `,
                ],
            },
        ],
        security: [
            {
                id: `1`,
                title: `How does Zunu Message encrypt the texts I send?`,
                contents: [
                    `<div>Zunu is built on Privacy by Design principles. It uses Fully Homomorphic Encryption (FHE) along with the industry standard of AES encryption, in a zero-knowledge environment. This shield achieves  privacy of your texts from hackers , malware and nosy services. </div>`,
                ],
            },
            {
                id: `2`,
                title: `Can my network carrier see the texts sent from Zunu Message?`,
                contents: [
                    `<div>No, your texts are encrypted from one device to the other. The phone’s operating system, apps and network carrier will not be able to make sense of the messages you send. </div>`,
                ],
            },
            {
                id: `3`,
                title: `Can the apps on my phone see my texts in Zunu Message?`,
                contents: [
                    `<div>No, the apps installed on your phone cannot see the texts sent from Zunu. It is encrypted within the app before being stored and sent from the device.</div>`,
                ],
            },
        ],
    },
    drive: {
        faq: [
            {
                id: `1`,
                title: `How does Zunu Drive protect my files from Ransomware?`,
                contents: [
                    `<div><strong>Your files in Zunu are:</strong>
                    <br />
                    <ul>
                        <li>Always encrypted, so ransomware cannot read your data.</li>
                        <li>Secured by Integrity Checks to protect backups.</li>
                        <li>Protected and encrypted in cloud backups.</li>
                        <li>Recoverable to any device you own.</li>
                    </ul>
                    </div>`,
                ],
            },
            {
                id: `2`,
                title: `How does Zunu Drive make files invisible to trackers?`,
                contents: [
                    `<div>The files you add to Zunu are encrypted by default. The trackers on your systems and accounts wont be able to go through your data.</div>
                    <div>File identifiers, attributes and metadata are hidden at the OS level. So, the content, name, type, and folder structures are not visible.</div>
                    <div>Here is a detailed description of the things that are kept safe using the zero-knowledge framework.</div>
                    <div>
                        <table>
                            <tr>
                                <th>Encrypted Components</th>
                                <th>Description</th>
                            </tr>
                            <tr>
                                <td>File blocks</td>
                                <td>Each file is encrypted with a unique key. Only the encrypted data is written to storage.</td>
                            </tr>
                            <tr>
                                <td>Metadata inclusive of Namespace</td>
                                <td>File identifiers and applicable attributes like filename, file type, and folder hierarchy</td>
                            </tr>
                            <tr>
                                <td>Content Index</td>
                                <td>The encrypted Content Index for storing indexed content of files, Content Index is used for supporting "Search over Encrypted Text".</td>
                            </tr>
                            <tr>
                                <td>Key Manager</td>
                                <td>Used to store keys. All keys stored in the Key Manager are stored in an encrypted form.</td>
                            </tr>
                        </table>
                    </div>
                    <div>All components of Zunu are encrypted and private to you.</div>
                    `,
                ],
            },
            {
                id: `3`,
                title: `How does Zunu Drive protect me from all kinds of data theft?`,
                contents: [
                    `<div>Zunu is a proactive measure to protect your files from all kinds of data theft. It keeps your files encrypted on your device and backups. In case of a data theft, your information cannot be seen or opened by anyone else.</div>
                    <div>Your information is kept private to you.</div>
                    `
                ]
            },
            {
                id: `4`,
                title: `How to use Zunu to make Google Drive and One Drive safer?`,
                contents: [
                    `<div>Zunu keeps files encrypted on Google Drive and One Drive. Your information is kept safe from account breaches and invasive profiling. It is also encrypted on your device. </div>
                    <div>Zunu gets you privacy when you save, sync and share.</div>
                    `
                ]
            },
            {
                id: `5`,
                title: `How to add Zunu Drive to my USB storage?`,
                contents: [
                    `<div>You can use Zunu to make your USBs private. The portable version requires no installation. You simply extract and run Zunu directly from your USB.</div>
                    <div><strong>Add Zunu to USB device:</strong>
                        <ul>
                            <li>Download Zunu Portable from <a href='https://zunuprivacy.com/downloads' >zunuprivacy.com/downloads</a>.</li>
                            <li>Extract the file to your USB device.</li>
                            <li>Run the Zunu application.</li>
                            <li>On USBs, you can use Zunu to share and get files in confidence.</li>
                        </ul>
                    </div>
                    `
                ]
            },
        ],
        started: [
            {
                id: `1`,
                title: `What is Zunu Drive?`,
                contents: [
                    `<div>Zunu Drive is your private file manager that encrypts your devices and cloud accounts. You get 100% Privacy for your photos, videos and documents. All your backups to Google Drive and OneDrive are encrypted to keep your information private.</div>
                    <div>We use patented technology that lets you save, sync and share privately from all your devices.</div>
                    <div>Built on Privacy by Design principles, Zunu keeps invaders, malwares and curious apps out of your files.</div>
                    `,
                ],
            },
            {
                id: `2`,
                title: `How to get Zunu Drive on my phone?`,
                contents: [
                    `<div>Zunu works with both iOS and Android phones. It comes with a built-in camera that keeps trackable metadata off your photos and videos.</div>
                    <div><strong>Install Zunu Drive on your:</strong>
                    <ul>
                        <li>Go to the App Store/ Play Store.</li>
                        <li>Search for Zunu Drive</li>
                        <li>Click on Get/ Install.</li>
                        <li>Launch the application to Sign In or Sign Up.</li>
                    </ul>
                    </div>
                    `,
                ],
            },
            {
                id: `3`,
                title: `How to add Zunu Drive to my USB storage?`,
                contents: [
                    `<div>You can use Zunu to make your USBs private. The portable version requires no installation. You simply extract and run Zunu directly from your USB.</div>
                    <div><strong>Add Zunu to USB device:</strong>
                    <ul>
                        <li>Download Zunu Portable from <a href=https://zunuprivacy.com/downloads >zunuprivacy.com/downloads</a>.</li>
                        <li>Extract the file to your USB device.</li>
                        <li>Run the Zunu application.</li>
                    </ul>
                    </div>
                    <div>On USBs, you can use Zunu to share and get files in confidence.</div>
                    `,
                ],
            },
            {
                id: `4`,
                title: `How do I sign up for Zunu Drive?`,
                contents: [
                    `<div>Zunu provides device to device encryption for all your files. It takes end to end encryption one step forward by protecting your data on your device as well. Your information is kept encrypted to your operating system. </div>
                    <div>You can easily sign-up for Zunu with your email address. We authenticate your email address before we create an account.</div>
                    <div><strong>To Create an Account:</strong>
                    <ul>
                        <li>Launch Zunu Drive and Click on the <strong>SignUp</strong> Button.</li>
                        <li>Enter the Email Address.</li>
                        <li>Check the Terms and Conditions and click on Proceed.</li>
                        <li>Enter the 6-digit OTP from your email.</li>
                        <li>Click on Proceed.</li>
                        <li>Create a password for your profile.</li>
                        <li>Click on Proceed.</li>
                    </ul>
                    </div>
                    `,
                ],
            },
            {
                id: `5`,
                title: `How do I set-up my Zunu Drive account?`,
                contents: [
                    `<div>Zunu Drives achieves 100% privacy to your files. In order to achieve this, you have to connect your accounts and devices to Zunu.</div>
                    <div><strong>Setting up Zunu Drive:</strong>
                    <ul>
                        <li>Sign into Zunu Drive.</li>
                        <li>Click on Add Cloud.</li>
                        <li>Select your cloud provider.</li>
                        <li>Set a name for your cloud account.</li>
                        <li>In the new window, click on Continue to to give the permission to keep encrypted files.</li>
                    </ul>
                    </div>
                    <div>You can now use Zunu to sync, save and share files safely.</div>
                    `,
                ],
            },
            {
                id: `6`,
                title: `How do I set up a profile photo and name in Zunu Drive?`,
                contents: [`
                    <div>You can personalise your Zunu account by setting your name and profile picture. Your profile photo is visible to other users in Zunu.</div>
                    <div><strong>Set up your profile photo in Zunu Drive:</strong>
                    <ul>
                        <li>Open Zunu Drive on your phone.</li>
                        <li>Tap on the hamburger menu, on the top left corner.</li>
                        <li>Tap on the arrow next to your email ID.</li>
                        <li>The profile settings page will open. </li>
                        <li>Click on "Change Photo" to set a new profile picture.</li>
                        <li>Select a photo from your device.</li>
                        <li>To set your name, go to the "NAME" section.</li>
                        <li>You can also set a friendly ID for your profile here.</li>
                        <li>Click "SAVE CHANGES” to update your profile.</li>
                    </ul>
                    </div>
                    <div>Your friends can use your friendly ID to easily share files with you.</div>
                    `,
                ],
            },
        ],
        using: [
            {
                id: `1`,
                title: `How do you add a file to Zunu Drive?`,
                contents: [
                    `<div>Making your files private with Zunu is easy. You can upload, open, share, and edit files with Zunu.</div>
                    <div><strong>Adding Files to Zunu Drive:</strong>
                    <br />
                    <ul>
                        <li>Open the Zunu Drive application on your device.</li>
                        <li>Locate and tap on "this device." This action will navigate you inside "this device."</li>
                        <li>Find the "Add" button located at the bottom right corner. Click on that button to reveal options.</li>
                        <li>From the options displayed, select "Upload File."</li>
                        <li>A popup will appear, asking whether to keep the original files after uploading. Select Yes/No according to your preferences.</li>
                        <li>The device's file explorer will now open, displaying your files and folders.</li>
                        <li>Browse through your files and select either a single file or a set of files that you want to secure on Zunu Drive. After selecting the files, tap on the "Select" button.</li>
                        <li>The selected files will begin uploading to Zunu Drive. You can monitor the upload progress as the files are being transferred.</li>
                    </ul>
                    </div>
                    `,
                ],
            },
            {
                id: `2`,
                title: `How to upload from my gallery into Zunu Drive?`,
                contents: [`
                <div>You can use Zunu to protect all the photo and videos on your phone. You can create backups on your favorite cloud and free up space on the device.</div>
                <div><strong>Adding files from Gallery:</strong>
                <br />
                <ul>
                    <li>Open Zunu Drive on your phone.</li>
                    <li>Go to the Gallery section.</li>
                    <li>Tap on the + button and select media.</li>
                    <li>Click on No to remove its copy from your gallery.</li>
                    <li>You can upload directly by tapping on + in the cloud section.</li>
                </ul>
                </div>
                <div>When you upload to the cloud, it happens on the default cloud you have set.</div>
                `],
            },
            {
                id: `3`,
                title: `How to share a file in Zunu drive?`,
                contents: [`
                <div>With Zunu Drive, you can privately share your files with friends and family.</div>
                <div><strong>Share files from Zunu Drive (Android) :</strong>
                <br />
                <ul>
                    <li>Open Zunu Drive.</li>
                    <li>If you haven't added a cloud to Zunu Drive yet, please refer to the instructions at [...] since files will sync before sharing.</li>
                    <li>Select the files you intend to share. Tap the vertical three dots option to open the Menu, and select "Share."</li>
                    <li>A page will appear asking for an email address.</li>
                    <li>On the page, enter the email address or friendly ID of the recipient with whom you wish to share the files. Then, tap on "SHARE".</li>
                    <li>Your selected files are securely shared and will be delivered in a fully encrypted format.</li>
                </ul>
                </div>
                <div>When you upload to the cloud, it happens on the default cloud you have set.</div>
                `],
            },
            {
                id: `4`,
                title: `How do I remove a file that is shared with me?`,
                contents: [`
                <div>Files that are shared with you can be seen in the “Shared” section of Zunu. You can view, open and download it to your device. If you wish, you can remove yourself from the share.</div>
                <div><strong>To remove a shared file: </strong>
                <br />
                <ul>
                    <li>Open Zunu Drive.</li>
                    <li>Navigate to the “Shared” section, to see all the files shared to you.</li>
                    <li>Click on the three dots next to the file.</li>
                    <li>Select the Unshare option from the dropdown.</li>
                    <li>Select the Yes Button to Unshare a File.</li>
                </ul>
                </div>
                <div>If you have downloaded the share, you can find a copy of the file in the “This Device” section of Zunu.</div>
                `],
            },
            {
                id: `5`,
                title: `How to set a default cloud in Zunu Drive?`,
                contents: [`
                <div>In Zunu, you can set the preferred cloud for sync and share. By default, all your sync and share will happen though this account. You can always change the default cloud in the cloud settings section.</div>
                <div><strong>To set a default cloud: </strong>
                <br />
                <ul>
                    <li>Open Zunu Drive.</li>
                    <li>Tap on the hamburger menu on the top left.</li>
                    <li>Select the Cloud Settings option.</li>
                    <li>You will see the list of cloud accounts.</li>
                    <li>You can add, remove or make it default by tapping the three dots next to the account.</li>
                </ul>
                </div>
                `],
            },
            {
                id: `6`,
                title: `How to scan and recover files corrupted by ransomware?`,
                contents: [`
                <div>Zunu makes sure that your files are not accessible to malwares and viruses. It detects and blocks the corrupted files from syncing to your backups. It lets you recover a safe copy of the file. If your system is attacked by a ransomware, then you can recover your files by logging into a new device.</div>
                <div><strong>To scan and recover corrupted files: </strong>
                <br />
                <ul>
                    <li>Open Zunu Drive on your desktop.</li>
                    <li>Click on the File Integrity button on the top right.</li>
                    <li>Zunu will scan and flag the corrupted files.</li>
                    <li>Double click and select Sync from cloud to recover the file.</li>
                </ul>
                </div>
                <div>To access a file on a new device, you can just log in and navigate to your clouds. Zunu can recover the files you have synced to your clouds. Files that are not synced will not be available on other devices.</div>
                `],
            },
            {
                id: `7`,
                title: `How do I create a folder in Zunu Drive?`,
                contents: [`
                <div>Zunu creates a secure file system that is private to the user. The folder name, contents and attributes are kept hidden to the OS of the device. File key and hierarchy management is done completely on the client side. </div>
                <div><strong>Create a folder in Zunu Drive: </strong>
                <br />
                <ul>
                    <li>Go to This Device</li>
                    <li>Click on the “+”.</li>
                    <li>Click on Create Folder.</li>
                    <li>Name the Folder according to your preferences.</li>
                    <li>Click on Create.</li>
                </ul>
                </div>
                <div>No process or application will be able to see your files without your consent. Every file and folder is private in Zunu.</div>
                `],
            },
            {
                id: `8`,
                title: `What file types are supported in Zunu Drive?`,
                contents: [`
                <div>Zunu supports over 300 different file types  including PDF, .txt, .docx, jpegs and so on. It supports files of any type and size lesser than 200MB. The speed and performance would depend on the system configuration.</div>
                `],
            },
            {
                id: `9`,
                title: `How do I change my password in ZUNU?`,
                contents: [`
                <div>In Zunu, your passwords are discarded when you logout. This keeps your information private from us, services and anyone trying to go through your things. If you lose your password it cannot be recovered. We recommend setting up device based authentication to keep your sessions safe.</div>
                <div><strong>To change your password in Zunu Drive: </strong>
                <br />
                <ul>
                    <li>Open Zunu Drive on your phone.</li>
                    <li>Tap on the hamburger menu, on the top left.</li>
                    <li>Go to "Your Profile".</li>
                    <li>Next, tap on "Change Password". </li>
                    <li>Enter your old password followed by your new password.</li>
                    <li>Tap on "Save Changes".</li>
                </ul>
                </div>
                <div>We recommend using a unique password that is not  associated with any other account. This enhances Zunu’s capability to keep your files private.</div>
                `],
            },
            {
                id: `10`,
                title: `How to delete the files from Zunu Drive?`,
                contents: [`
                <div>Files in Zunu are encrypted when they are written to disk. When you delete a file, the encrypted file is deleted. Recovering deleted file blocks from disk will only give encrypted data. </div>
                <div><strong>To change your password in Zunu Drive: </strong>
                <br />
                <ul>
                    <li>Open Zunu Drive.</li>
                    <li>Locate the file that you want to delete.</li>
                    <li>Tap and hold the file for file options.</li>
                    <li>Hit on Delete. </li>
                </ul>
                </div>
                <div>Zunu maintains the confidentiality of the files you delete.</div>
                `],
            },
            {
                id: `11`,
                title: `How do I see my photos and documents in the cloud?`,
                contents: [`
                <div>Zunu aggregates your files stored across your clouds and devices. You can access all your images and videos from the gallery section. Similarly, all your documents can be accessed from the Document section. You can access, share and edit all your files from a single interface.</div>
                `],
            },
        ],
        security: [
            {
                id: `1`,
                title: `Who has access to my encrypted data?`,
                contents: [
                    `<div>Zunu Drive uses zero-knowledge encryption which means that we identify you and verify your credentials when you log in without ever knowing your password. The same protocol is applied to the keys of the files.</div>`,
                ],
            },
            {
                id: `2`,
                title: `How is my search encrypted in Zunu Drive? `,
                contents: [
                    `<div>Zunu applies Fully Homomorphic Encryption (FHE) to create zero knowledge search indexes. You search and get results without decrypting. FHE fixes privacy gaps to help you keep data confidential.</div>`,
                ],
            },
            {
                id: `3`,
                title: `How does Zunu Drive protect my files from Ransomware?`,
                contents: [
                    `<div><strong>Your files in Zunu are:</strong>
                    <br />
                    <ul>
                        <li>Always encrypted, so ransomware cannot read your data.</li>
                        <li>Secured by Integrity Checks to protect backups.</li>
                        <li>Protected and encrypted in cloud backups.</li>
                        <li>Recoverable to any device you own. </li>
                    </ul>
                    </div>`,
                ],
            },
            {
                id: `4`,
                title: `How does Zunu Drive make files invisible to trackers?`,
                contents: [
                    `<div>The files you add to Zunu are encrypted by default. The trackers on your systems and accounts wont be able to go through your data. </div>
                    <div>File identifiers, attributes and metadata are hidden at the OS level. So, the content, name, type, and folder structures are not visible.</div>
                    <div>Here is a detailed description of the things that are kept safe using the zero-knowledge framework.</div>
                    <div>
                        <table>
                            <tr>
                                <th>Encrypted Components</th>
                                <th>Description</th>
                            </tr>
                            <tr>
                                <td>File blocks</td>
                                <td>Each file is encrypted with a unique key. Only the encrypted data is written to storage.</td>
                            </tr>
                            <tr>
                                <td>Metadata inclusive of Namespace</td>
                                <td>File identifiers and applicable attributes like filename, file type, and folder hierarchy</td>
                            </tr>
                            <tr>
                                <td>Content Index</td>
                                <td>The encrypted Content Index for storing indexed content of files, Content Index is used for supporting "Search over Encrypted Text".</td>
                            </tr>
                            <tr>
                                <td>Key Manager</td>
                                <td>Used to store keys. All keys stored in the Key Manager are stored in an encrypted form.</td>
                            </tr>
                        </table>
                    </div>
                    <div>All components of Zunu are encrypted and private to you.</div>
                    `,
                ],
            },
            {
                id: `5`,
                title: `How does Zunu Drive protect me from all kinds of data theft?`,
                contents: [`
                    <div>Zunu is a proactive measure to protect your files from all kinds of data theft. It keeps your files encrypted on your device and backups. In case of a data theft, your information cannot be seen or opened by anyone else.  </div>
                    <div>Your information is kept private to you.</div>
                `],
            },
            {
                id: `6`,
                title: `How to use Zunu to make Google Drive and One Drive safer?`,
                contents: [`
                    <div>Zunu keeps files encrypted on Google Drive and One Drive. Your information is kept safe from account breaches and invasive profiling. It is also encrypted on your device.</div>
                    <div>Zunu gets you privacy when you save, sync and share.</div>
                `],
            },
            {
                id: `7`,
                title: `How does Zunu Drive protect me if my cloud credentials are stolen?`,
                contents: [`
                    <div>Stolen credentials is the primary cause in most data breaches. It exploits the human element to gain unauthorised access. </div>
                    <div>When you save a file with Zunu, you make data invisible in your backups and devices. It can only be accessed from Zunu. Anyone using stolen credentials will not be able to view or use the data.</div>
                `],
            },
        ],
    },
}