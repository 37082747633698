import * as React from 'react';
import styles from './zunuPortable.module.scss'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import PortableMac from '../../assets/webp/portableMac.webp';
import { ReactComponent as CloudDevice } from '../../assets/svg/CloudDevice.svg'
import { ReactComponent as FileLocks } from '../../assets/svg/FileLock.svg'
import { ReactComponent as Allfiles } from '../../assets/svg/allDevices.svg'
import { ReactComponent as PrivacyLock } from '../../assets/svg/PrivacyLock.svg'
import PortableImg1 from '../../assets/webp/portableImg1.webp'
import PortableImg2 from '../../assets/webp/portableImg2.webp'
import PortableImg3 from '../../assets/webp/portableImg3.webp'
import PortableImg4 from '../../assets/webp/portableImg4.webp'
// import PortableImg5 from '../../assets/webp/portableImg5.webp'
import PortableImg5 from '../../assets/webp/portableImg5_1.webp'
import PortableImg6 from '../../assets/webp/portableImg6.webp'
import PortableImg7 from '../../assets/webp/portableImg7.webp'
import { Typewriter } from 'react-simple-typewriter'
import { Fade } from 'react-awesome-reveal';
import { useDownloadBtnStore } from '../../StateManager/DownloadBtnStore';

function ZunuPortable() {

 const { isDownloadBtn, setIsDownloadBtn} = useDownloadBtnStore();
 
    useEffect(() => {
        setIsDownloadBtn(true)

    }, [])


    return (
        <div className={styles.portablePageContainer}>

            <Helmet>
                <meta name="description" content="Privacy-Preserving enclave to protect files on USB devices." />
                <title>Zunu Portable - Privacy-Preserving enclave to protect files on USB devices.</title>
                
                <meta property="og:site_name" content="Zunu" />
                <meta property="og:title" content="Zunu Portable - Privacy-Preserving enclave to protect files on USB devices." />
                <meta property="og:url" content="https://zunuprivacy.com/portable" />
                <meta property="og:description" content="Privacy-Preserving enclave to protect files on USB devices.." />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://zunuprivacy.com/icon192.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@zirohlabs" />
                <meta name="twitter:title" content="Zunu: Software-defined security stack with privacy-preserving, performant cryptographic algorithms." />
                <meta name="twitter:description" content="Privacy-Preserving enclave to protect files on USB devices." />
                <meta name="twitter:image"
                    content="https://zunuprivacy.com/icon192.png" />
            </Helmet>

            <div className={styles.portablePageHeader}>
                <div className={styles.headerMain}>
                     <Fade> 
                    <p className={styles.pageheader}> Your
                        <span>
                            <Typewriter
                                words={['files.', 'Identity.']}
                                loop={true}
                                typeSpeed={50}
                                delaySpeed={1000}
                                cursor={true}
                                cursorBlinking={true}
                            />
                        </span> 
                    </p>
                    <p className={styles.portableSubheader}>Under your <span> Control </span></p>
                    </Fade>
                </div>
                <div className={styles.macImage}>
                    <figure>
                        <img src={PortableMac} alt='Device'></img>
                    </figure>
                </div>
            </div>

            <div className={styles.portableMain}>
                <div className={styles.privacyAndSecurityContent} >
                    <div className={styles.privacyContainer}>
                        <Fade>
                        <p className={styles.privacyHeaderDesktop}>
                            Ultimate Privacy and Confidentiality <br /> for Data on
                            <span> Portable Devices </span>
                        </p>
                        </Fade>

                    </div>
                    <div className={styles.privacyContents}>
                        <div className={styles.privacyContentCards}>
                            <CloudDevice />
                            Complete Privacy On Device and in Cloud
                        </div>
                        <div className={styles.privacyContentCards}>
                            <FileLocks />
                            Ransomeware, Malware and Virus protection for Files
                        </div>

                        <div className={styles.privacyContentCards}>
                            <Allfiles />
                            A single place to access all your files
                        </div>
                        <div className={styles.privacyContentCards}>
                            <PrivacyLock />
                            Secure, private, and under your control.
                        </div>

                    </div>
                </div>
            </div>
            <div className={styles.zunuPortableContents}>
                <Fade>
                <p> What does <span>Zunu</span> Portable provide you?</p>
                </Fade>
                <div className={styles.portableContainer}>
                    <div className={styles.imgTextContainer}>

                        <div className={styles.textContents}>
                            <Fade>
                            <p className={styles.textContentsHeader}>Privacy like never before</p>
                             </Fade>
                            <div className={styles.textLists}>
                                <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>
                                    <li>Device to Device End-to-End Encryption for your files</li>
                                    <li>Search over Encrypted files with Encrypted Queries conveniently.</li>
                                    <li> More than 300 file types are supported by default</li>
                                    <li>Quickly search, work, and share files anywhere</li>
                                </Fade>
                                </ul>
                            </div>
                        </div>

                        <div className={styles.ImageContent}>
                            <figure>
                                <img src={PortableImg1} alt='Zunu Drive'></img>
                            </figure>
                        </div>
                    </div>

                    <div className={styles.imgTextContainer}>

                        <div className={styles.textContents}>
                            <Fade>
                            <p className={styles.textContentsHeader}>Work On Multiple Clouds Seamlessly</p>
                            </Fade>
                            <div className={styles.textLists}>
                                <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>
                                    <li>Work on any file and any cloud, conveniently</li>
                                    <li>Manage different cloud accounts from a single platform</li>
                                    <li> Get more storage space by adding unlimited cloud accounts</li>
                                    <li>Compatible with all the leading cloud storage providers</li>
                                </Fade>
                                </ul>
                            </div>
                        </div>
                        <div className={styles.ImageContent}>
                            <figure>
                                <img src={PortableImg2} alt='Works on Multiple Clouds'></img>
                            </figure>
                        </div>
                    </div>

                    <div className={styles.imgTextContainer}>
                        <div className={styles.textContents}>
                            <Fade>
                            <p className={styles.textContentsHeader}>A single Password to Navigate through All the Clouds</p>
                            </Fade>
                            <div className={styles.textLists}>
                                <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>
                                    <li>One password for your cloud accounts</li>
                                    <li>Unified access to all your files</li>
                                    <li>Enhanced privacy for files stored in any cloud</li>
                                    <li> Works on any device and any browser</li>
                                </Fade>
                                </ul>
                            </div>
                        </div>
                        <div className={styles.ImageContent}>
                            <figure>
                                <img src={PortableImg3} alt='Single Password for all Clouds'></img>
                            </figure>
                        </div>
                    </div>

                    <div className={styles.imgTextContainer}>

                        <div className={styles.textContents}>
                            <Fade>
                            <p className={styles.textContentsHeader}>Sharing Files,<br /> Secured and Simplified</p>
                            </Fade>
                            <div className={styles.textLists}>
                                <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>
                                    <li>Easily share files online and offline</li>
                                    <li>Send and receive files without signing up</li>
                                    <li>Share files across clouds and devices</li>
                                    <li>Exchange files securely with non-Zunu users using a passphrase</li>
                                </Fade>
                                </ul>
                            </div>
                        </div>
                        <div className={styles.ImageContent}>
                            <figure>
                                <img src={PortableImg4} alt='Easy and Secure File Sharing'></img>
                            </figure>
                        </div>
                    </div>

                    <div className={styles.imgTextContainer}>
                        <div className={styles.textContents}>
                            <Fade>
                            <p className={styles.textContentsHeader}>Round The Clock Ransomware Protection</p>
                            </Fade>
                            <div className={styles.textLists}>
                                <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>
                                    <li>Files can no longer make you vulnerable</li>
                                    <li>Guarantees file safety even in a breached environment</li>
                                    <li>Secure files with file integrity checks</li>
                                    <li>Defends against malware & cyber attacks</li>
                                 </Fade>
                                </ul>
                            </div>
                        </div>
                        <div className={styles.ImageContent}>
                            <figure>
                                <img src={PortableImg5} alt='Clock Ransomware Protection'></img>
                            </figure>
                        </div>
                    </div>

                    <div className={styles.imgTextContainer}>
                        <div className={styles.textContents}>
                            <Fade>
                            <p className={styles.textContentsHeader}>Absolute Security From Beginning To End</p>
                            </Fade>
                            <div className={styles.textLists}>
                                <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>

                                    <li>Unlimited protection for all your files everywhere</li>
                                    <li>Freedom and peace of mind to focus on value creation</li>
                                    <li>Avoid profiling by denying unauthorized access</li>
                                    <li>Reliable storage for your photos, videos, & confidential documents</li>
                                </Fade>
                                </ul>

                            </div>
                        </div>
                        <div className={styles.ImageContent}>
                            <figure>
                                <img src={PortableImg6} alt='Cloud Drive'></img>
                            </figure>
                        </div>
                    </div>

                    <div className={styles.imgTextContainer}>
                        <div className={styles.textContents}>
                            <Fade>
                            <p className={styles.textContentsHeader}>Preserve Data Privacy With Intelligent Sync</p>
                            </Fade>
                            <div className={styles.textLists}>
                                <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>                             
                                    <li>Device level tamper detection</li>
                                    <li>Leverage in-built virus and malware protection for your files</li>
                                    <li>Block corrupted files from syncing with back-ups in the cloud</li>
                                    <li>Ensure a safe copy of your back-ups and get back to business</li>
                                </Fade>
                                </ul>
                            </div>
                        </div>
                        <div className={styles.ImageContent}>
                            <figure>
                                <img src={PortableImg7} alt='Cloud Drive'></img>
                            </figure>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default ZunuPortable