import { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { downloadService } from "../../App.registration";
import { useLocalizationStore } from "../../StateManager/LocalizationStore";
import { useOsStore } from "../../StateManager/OsStore";
import { useThemeStore } from "../../StateManager/ThemeStore";
import { PLATFORM } from "../../_shared/constants/constants";
import { useDownloadBtnStore } from "../../StateManager/DownloadBtnStore";

export const useHomehook = (
 headerRef?: any
) => {
  const { theme, setTheme } = useThemeStore();
  const { localization, setLocalization } = useLocalizationStore();
  const { os, setOs } = useOsStore();
  const { isDownloadBtn, setIsDownloadBtn} = useDownloadBtnStore();
  const [y, setY] = useState(window.scrollY);
  var buttonYOffset: any = 0;
  const [a, setA] = useState(-48);
  const [screenHeight, setScreenHeight] = useState(0);

  const root = document.documentElement;

  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    setScreenHeight(window.screen.height)
    root.style.setProperty(`--screen_height`, `${window.screen.height}px`);
    setTimeout(() => {
      buttonYOffset = headerRef.current.getBoundingClientRect().bottom;
      setA(headerRef.current.getBoundingClientRect().bottom - 48);
      console.log(buttonYOffset, 'offset');
      // console.log(window.screen.height, 'screen hright');
           
      root.style.setProperty(
        `--page_yoffset`,
        `${
          a === 0
            ? os === "ios"
              ? buttonYOffset - 48
              : buttonYOffset - 48
            : buttonYOffset - 48
        }px`
      );

      // root.style.setProperty(`--page_yoffset`, `${buttonYOffset}px`)

      // console.log(buttonYOffset, 'offset');
    }, 1000);
  }, []);

  useEffect(() => {
    if (window.screen.width > 1200) {
      // isButtonVisible.setIsButtonVisible(inView);
     setIsDownloadBtn(true);
    }
  }, [inView]);

  const handleNavigation = useCallback(
    (e: { currentTarget: any }) => {
      const window = e.currentTarget;
      // const top =document.getElementById('download')?.getBoundingClientRect().top
      if (y > window.scrollY) {
        if (window.scrollY < 5) { 
            const pos = a;
            root.style.setProperty(`--page_yoffset`, `${a}px`);
        }
        // console.log('scrolling up');
        // console.log(window.scrollY);
      } else if (y < window.scrollY) {
        // console.log(window.scrollY);
        if (window.scrollY > 5) {
          if (os === PLATFORM.IOS ){
            root.style.setProperty(`--page_yoffset`, `76vh`);
            return
          }
          else{
            root.style.setProperty(`--page_yoffset`, `80vh`);
            return
          }
        }
      }
      setY(window.scrollY);
    },
    [window.scrollY]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);


  return {
    theme,
    localization,
    ref,
  };
};
