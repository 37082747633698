export const TERMS ={
    camera:[
        {
            title: `1. DEFINITION`,
            contents: [`<strong>“You”</strong> and <strong>“Your”</strong> means the individual or legal entity or licensee of the Software under this EULA. <strong>“Use”</strong> or <strong>“Using”</strong> means to download, install, activate, access, or otherwise use the application “Zunu Camera" developed by Ziroh Labs. <strong>“Software”</strong> means the “Zunu Camera" developed by Ziroh Labs and any Upgrades made available by Ziroh Labs.`],
        },
        {
            title: `2. ACCEPTANCE OF TERMS`,
            contents: [`By Using the Software, you agree to be bound by the terms of the EULA. If you enter this EULA on behalf of an entity, you represent that you have the authority to bind that entity.`]
        },
        {
            title: `3. LICENSE RESTRICTIONS`,
            contents: [`Ziroh Labs grants You a limited, non-exclusive, and non-transferable license to Use object code versions of the Software and the Documentation solely for Your internal operations and following the Entitlement and the Documentation but not for the following purpose:
                <br /><br />
                (i) reproduce or copy the Software, except that You may make one (1) copy of the Software solely for archival purposes, provided that You agree to reproduce all copyright and other proprietary right notices on the archival copy made available by Ziroh Labs; or
                <br />
                (ii) use, cause or permit the use of, the Software in whole or in part for any purpose other than as permitted under this EULA; or
                <br />
                (iii) distribute, sell, lease, license or otherwise make the Software available to any third party without the prior written consent of Ziroh Labs; or
                <br />
                (iv) decompile, disassemble, decrypt, extract or otherwise reverse-engineer the Software.
                <br /><br />
                The Software “Zunu Camera” is owned by Ziroh Labs, and Ziroh Labs retains all right, title, and interest in and to the Software. You have the right only to use the Software in the manner and to the extent expressly licensed within this EULA and nothing in this EULA shall be construed as conferring any license to you of Ziroh Labs’s intellectual property rights, whether by estoppel, implication, or otherwise.
            `]
        },
        {
            title: `4. SUPPORT`,
            contents: [`During the EULA, Ziroh Labs shall support the Software in such manner, including providing updates, bug fixes, builds, or error corrections, as Ziroh Labs deems fit and proper (collectively “<strong>Software Updates</strong>”). If Ziroh Labs, at its sole option, provides Software Updates to you, the Software Updates will be considered part of the Software and subject to the terms and conditions of this EULA.`]
        },
        {
            title: `5. DISCLAIMER OF WARRANTIES`,
            contents: [`You acknowledge that the Software is provided “as is, with all faults,” without any maintenance, debugging, support, or improvement. Ziroh Labs MAKES NO REPRESENTATIONS AND EXTENDS NO WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED IN RESPECT OF THE SOFTWARE. FURTHERMORE, ZIROH LABS DISCLAIMS ALL EXPRESS OR IMPLIED CONDITIONS, REPRESENTATIONS AND WARRANTIES, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT IN RESPECT OF THE SOFTWARE. You shall not make any statements, representations, or warranties to any person or entity that are inconsistent with any limitation or disclaimer included in this Paragraph 7.`]
        },
        {
            title: `6. LIMITATION OF LIABILITY`,
            contents: [`You assume the entire risk as to the quality, results, performance, and/or non-performance of the Software. You shall be responsible for adequately protecting and backing up your data or equipment used in connection with the Software. In no event shall Ziroh Labs (including its officers, employees, consultants, and agents) be responsible or liable for any direct, indirect, special, consequential, incidental, punitive, or other damages whatsoever (including lost profits, business, revenue, use, data, or another economic advantage) in connection with, arising out of, or related to this EULA, regardless of the theory of liability, whether for breach or in tort (including negligence), even if Ziroh Labs may have been previously advised of the possibility of such damage. You shall not make any statements or accept any liabilities or responsibilities for any person or entity that are inconsistent with any limitation included in this Paragraph 8.`]
        },{
            title: `7. NO OBLIGATION`,
            contents: [`This EULA does not represent or imply either: (a) any commitment whatsoever by you to purchase, acquire, commercially license, or otherwise use (according to other appropriate agreements) the Software and any other products or services of Ziroh Labs, or (b) any encouragement or inducement whatsoever to either You or Ziroh Labs to expend funds or other resources in the development or modification of the Software or any other products or services.`]
        },
        {
            title: `8. CONFIDENTIALITY`,
            contents: [`You acknowledge that the Software is proprietary and confidential to Ziroh Labs. Accordingly, any non-public information obtained by You regarding the Software or relating to Ziroh Labs <strong>(“Confidential Information”)</strong>, shall be kept in confidence by You for the term of this EULA and thereafter until such information is made available by Ziroh Labs to the public domain. You agree to protect the Software and any feedback provided by You from unauthorized disclosure, use, or release and to treat the Software and feedback with at least the same level of care as You use to protect You own proprietary computer programs and/or confidential information, but in no event less than a reasonable standard of care. If You becomes aware of any unauthorized licensing, copying, or use of the Software, you shall promptly notify Ziroh Labs.`]
        },
        {
            title: `9. TERM AND TERMINATION`,
            contents: [`This Agreement is effective upon Licensee's acceptance of the terms hereof, and the Agreement will remain in effect until termination. In the event that You breaches any of Your obligations under this EULA which breach is capable of being remedied, and if such breach has not been remedied within ten (10) Calendar days after the date of notice in writing of such breach, Ziroh Labs may terminate this EULA immediately by written notice to you. Upon expiration or termination of this EULA, You shall (i) promptly cease use of the Software and (ii) return to Ziroh Labs the original and all copies of the Software in your possession or, at the option of Ziroh Labs, You shall destroy all such Software, with such destruction certified in writing to Ziroh Labs by a responsible representative of Licensor. Rights and obligations under this EULA that by their natures should survive will remain in effect after the termination or expiration of this EULA.`]
        },
        {
            title: `10. ASSIGNMENT`,
            contents: [`You may not assign or otherwise transfer any of its rights or obligations under this EULA without the prior written consent of Ziroh Labs.`]
        },
        {
            title: `11. ENTIRE AGREEMENT`,
            contents: [`This EULA, including any appendices or exhibits, represents the parties’ entire agreement relating to the EULA. This EULA may not be extended, amended, or modified, in whole or in part, except by further specific written agreement of the parties signed by their respective authorized officials.`]
        },
        {
            title: `12. ENFORCEMENT`,
            contents: [`This EULA shall be governed by the laws of the Republic of India. Suppose any provision of this Agreement is held to be invalid or unenforceable. In that case, such invalidity or unenforceability shall not affect the validity enforceability of the remaining provisions. All legal and/or dispute resolution proceedings, whether formal or otherwise, arising under or consequent to this EULA shall be conducted in San Francisco, California, United States of America.`]
        },
        {
            title: `13. WAIVER`,
            contents: [`The failure of either party to enforce any provision of this EULA shall not constitute a waiver of that right or future enforcement of that or any other provision.`]
        }
    ],

    mail: [
        {
            title: `1. DEFINITION`,
            contents: [`<strong>“You”</strong> and <strong>“Your”</strong> means the individual or legal entity or licensee of the Software under this EULA. <strong>“Use”</strong> or <strong>“Using”</strong> means to download, install, activate, access, or otherwise use the application “Zunu Mail" developed by Ziroh Labs. <strong>“Software”</strong> means the “Zunu Mail" developed by Ziroh Labs and any Upgrades made available by Ziroh Labs.`],
        },
        {
            title: `2. ACCEPTANCE OF TERMS`,
            contents: [`By Using the Software, you agree to be bound by the terms of the EULA. If you enter this EULA on behalf of an entity, you represent that you have the authority to bind that entity.`]
        },
        {
            title: `3. LICENSE RESTRICTIONS`,
            contents: [`Ziroh Labs grants You a limited, non-exclusive, and non-transferable license to Use object code versions of the Software and the Documentation solely for Your internal operations and following the Entitlement and the Documentation but not for the following purpose:
                <br /><br />
                (i) reproduce or copy the Software, except that You may make one (1) copy of the Software solely for archival purposes, provided that You agree to reproduce all copyright and other proprietary right notices on the archival copy made available by Ziroh Labs; or
                <br />
                (ii) use, cause or permit the use of, the Software in whole or in part for any purpose other than as permitted under this EULA; or
                <br />
                (iii) distribute, sell, lease, license or otherwise make the Software available to any third party without the prior written consent of Ziroh Labs; or
                <br />
                (iv) decompile, disassemble, decrypt, extract or otherwise reverse-engineer the Software.
                <br /><br />
                The Software “Zunu Mail” is owned by Ziroh Labs, and Ziroh Labs retains all right, title, and interest in and to the Software. You have the right only to use the Software in the manner and to the extent expressly licensed within this EULA and nothing in this EULA shall be construed as conferring any license to you of Ziroh Labs’s intellectual property rights, whether by estoppel, implication, or otherwise.
            `]
        },
        {
            title: `4. SUPPORT`,
            contents: [`During the EULA, Ziroh Labs shall support the Software in such manner, including providing updates, bug fixes, builds, or error corrections, as Ziroh Labs deems fit and proper (collectively “<strong>Software Updates</strong>”). If Ziroh Labs, at its sole option, provides Software Updates to you, the Software Updates will be considered part of the Software and subject to the terms and conditions of this EULA.`]
        },
        {
            title: `5. DISCLAIMER OF WARRANTIES`,
            contents: [`You acknowledge that the Software is provided “as is, with all faults,” without any maintenance, debugging, support, or improvement. Ziroh Labs MAKES NO REPRESENTATIONS AND EXTENDS NO WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED IN RESPECT OF THE SOFTWARE. FURTHERMORE, ZIROH LABS DISCLAIMS ALL EXPRESS OR IMPLIED CONDITIONS, REPRESENTATIONS AND WARRANTIES, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT IN RESPECT OF THE SOFTWARE. You shall not make any statements, representations, or warranties to any person or entity that are inconsistent with any limitation or disclaimer included in this Paragraph 7.`]
        },
        {
            title: `6. LIMITATION OF LIABILITY`,
            contents: [`You assume the entire risk as to the quality, results, performance, and/or non-performance of the Software. You shall be responsible for adequately protecting and backing up your data or equipment used in connection with the Software. In no event shall Ziroh Labs (including its officers, employees, consultants, and agents) be responsible or liable for any direct, indirect, special, consequential, incidental, punitive, or other damages whatsoever (including lost profits, business, revenue, use, data, or another economic advantage) in connection with, arising out of, or related to this EULA, regardless of the theory of liability, whether for breach or in tort (including negligence), even if Ziroh Labs may have been previously advised of the possibility of such damage. You shall not make any statements or accept any liabilities or responsibilities for any person or entity that are inconsistent with any limitation included in this Paragraph 8.`]
        },{
            title: `7. NO OBLIGATION`,
            contents: [`This EULA does not represent or imply either: (a) any commitment whatsoever by you to purchase, acquire, commercially license or otherwise use (pursuant to other appropriate agreements) the Software and any other products or services of Ziroh Labs, or (b) any encouragement or inducement whatsoever to either You or Ziroh Labs to expend funds or other resources in the development or modification of the Software or any other products or services.`]
        },
        {
            title: `8. CONFIDENTIALITY`,
            contents: [`You acknowledge that the Software is proprietary and confidential to Ziroh Labs. Accordingly, any non-public information obtained by You regarding the Software or relating to Ziroh Labs <strong>(“Confidential Information”)</strong>, shall be kept in confidence by You for the term of this EULA and thereafter until such information is made available by Ziroh Labs to the public domain. You agree to protect the Software and any feedback provided by You from unauthorized disclosure, use, or release and to treat the Software and feedback with at least the same level of care as You use to protect You own proprietary computer programs and/or confidential information, but in no event less than a reasonable standard of care. If You becomes aware of any unauthorized licensing, copying, or use of the Software, you shall promptly notify Ziroh Labs.`]
        },
        {
            title: `9. TERM AND TERMINATION`,
            contents: [`This Agreement is effective upon Licensee's acceptance of the terms hereof, and the Agreement will remain in effect until termination. In the event that You breaches any of Your obligations under this EULA which breach is capable of being remedied, and if such breach has not been remedied within ten (10) calendar days after the date of notice in writing of such breach, Ziroh Labs may terminate this EULA immediately by written notice to you. Upon expiration or termination of this EULA, You shall (i) promptly cease use of the Software and (ii) return to Ziroh Labs the original and all copies of the Software in your possession or, at the option of Ziroh Labs, You shall destroy all such Software, with such destruction certified in writing to Ziroh Labs by a responsible representative of Licensor. Rights and obligations under this EULA that by their natures should survive will remain in effect after the termination or expiration of this EULA.`]
        },
        {
            title: `10. ASSIGNMENT`,
            contents: [`You may not assign or otherwise transfer any of its rights or obligations under this EULA without the prior written consent of Ziroh Labs.`]
        },
        {
            title: `11. ENTIRE AGREEMENT`,
            contents: [`This EULA, including any appendices or exhibits, represents the parties’ entire agreement relating to the EULA. This EULA may not be extended, amended, or modified, in whole or in part, except by further specific written agreement of the parties signed by their respective authorized officials.`]
        },
        {
            title: `12. ENFORCEMENT`,
            contents: [`This EULA shall be governed by the laws of the Republic of India. Suppose any provision of this Agreement is held to be invalid or unenforceable. In that case, such invalidity or unenforceability shall not affect the validity or enforceability of the remaining provisions. All legal and/ or dispute resolution proceedings, whether formal or otherwise, arising under or consequent to this EULA shall be conducted in San Francisco, California, United States of America.`]
        },
        {
            title: `13. WAIVER`,
            contents: [`The failure of either party to enforce any provision of this EULA shall not constitute a waiver of that right or future enforcement of that or any other provision.`]
        }
    ],

    messgae: [
        {
            title: `1. DEFINITION`,
            contents: [`<strong>“You”</strong> and <strong>“Your”</strong> means the individual or legal entity or licensee of the Software under this EULA. <strong>“Use”</strong> or <strong>“Using”</strong> means to download, install, activate, access, or otherwise use the application “Zunu Message" developed by Ziroh Labs. <strong>“Software”</strong> means the “Zunu Message" developed by Ziroh Labs and any Upgrades made available by Ziroh Labs.`],
        },
        {
            title: `2. ACCEPTANCE OF TERMS`,
            contents: [`By Using the Software, you agree to be bound by the terms of the EULA. If you enter this EULA on behalf of an entity, you represent that you have the authority to bind that entity.`]
        },
        {
            title: `3. LICENSE RESTRICTIONS`,
            contents: [`Ziroh Labs grants You a limited, non-exclusive, and non-transferable license to Use object code versions of the Software and the Documentation solely for Your internal operations and following the Entitlement and the Documentation but not for the following purpose:
                <br /><br />
                (i) reproduce or copy the Software, except that You may make one (1) copy of the Software solely for archival purposes, provided that You agree to reproduce all copyright and other proprietary right notices on the archival copy made available by Ziroh Labs; or
                <br />
                (ii) use, cause or permit the use of, the Software in whole or in part for any purpose other than as permitted under this EULA; or
                <br />
                (iii) distribute, sell, lease, license or otherwise make the Software available to any third party without the prior written consent of Ziroh Labs; or
                <br />
                (iv) decompile, disassemble, decrypt, extract or otherwise reverse-engineer the Software.
                <br /><br />
                The Software “Zunu Message” is owned by Ziroh Labs, and Ziroh Labs retains all right, title, and interest in and to the Software. You have the right only to use the Software in the manner and to the extent expressly licensed within this EULA and nothing in this EULA shall be construed as conferring any license to you of Ziroh Labs’s intellectual property rights, whether by estoppel, implication, or otherwise.
            `]
        },
        {
            title: `4. SUPPORT`,
            contents: [`During the EULA, Ziroh Labs shall support the Software in such manner, including providing updates, bug fixes, builds, or error corrections, as Ziroh Labs deems fit and proper (collectively “<strong>Software Updates</strong>”). If Ziroh Labs, at its sole option, provides Software Updates to you, the Software Updates will be considered part of the Software and subject to the terms and conditions of this EULA.`]
        },
        {
            title: `5. DISCLAIMER OF WARRANTIES`,
            contents: [`You acknowledge that the Software is provided “as is, with all faults,” without any maintenance, debugging, support, or improvement. Ziroh Labs MAKES NO REPRESENTATIONS AND EXTENDS NO WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED IN RESPECT OF THE SOFTWARE. FURTHERMORE, ZIROH LABS DISCLAIMS ALL EXPRESS OR IMPLIED CONDITIONS, REPRESENTATIONS AND WARRANTIES, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT IN RESPECT OF THE SOFTWARE. You shall not make any statements, representations, or warranties to any person or entity that are inconsistent with any limitation or disclaimer included in this Paragraph 7.`]
        },
        {
            title: `6. LIMITATION OF LIABILITY`,
            contents: [`You assume the entire risk as to the quality, results, performance, and/or non-performance of the Software. You shall be responsible for adequately protecting and backing up your data or equipment used in connection with the Software. In no event shall Ziroh Labs (including its officers, employees, consultants, and agents) be responsible or liable for any direct, indirect, special, consequential, incidental, punitive, or other damages whatsoever (including lost profits, business, revenue, use, data, or another economic advantage) in connection with, arising out of, or related to this EULA, regardless of the theory of liability, whether for breach or in tort (including negligence), even if Ziroh Labs may have been previously advised of the possibility of such damage. You shall not make any statements or accept any liabilities or responsibilities for any person or entity that are inconsistent with any limitation included in this Paragraph 8.`]
        },{
            title: `7. NO OBLIGATION`,
            contents: [`This EULA does not represent or imply either: (a) any commitment whatsoever by you to purchase, acquire, commercially license, or otherwise use (according to other appropriate agreements) the Software and any other products or services of Ziroh Labs, or (b) any encouragement or inducement whatsoever to either You or Ziroh Labs to expend funds or other resources in the development or modification of the Software or any other products or services.`]
        },
        {
            title: `8. CONFIDENTIALITY`,
            contents: [`You acknowledge that the Software is proprietary and confidential to Ziroh Labs. Accordingly, any non-public information obtained by You regarding the Software or relating to Ziroh Labs <strong>(“Confidential Information”)</strong>, shall be kept in confidence by You for the term of this EULA and thereafter until such information is made available by Ziroh Labs to the public domain. You agree to protect the Software and any feedback provided by You from unauthorized disclosure, use, or release and to treat the Software and feedback with at least the same level of care as You use to protect You own proprietary computer programs and/or confidential information, but in no event less than a reasonable standard of care. If You becomes aware of any unauthorized licensing, copying, or use of the Software, you shall promptly notify Ziroh Labs.`]
        },
        {
            title: `9. TERM AND TERMINATION`,
            contents: [`This Agreement is effective upon Licensee's acceptance of the terms hereof, and the Agreement will remain in effect until termination. In the event that You breaches any of Your obligations under this EULA which breach is capable of being remedied, and if such breach has not been remedied within ten (10) Calendar days after the date of notice in writing of such breach, Ziroh Labs may terminate this EULA immediately by written notice to you. Upon expiration or termination of this EULA, You shall (i) promptly cease use of the Software and (ii) return to Ziroh Labs the original and all copies of the Software in your possession or, at the option of Ziroh Labs, You shall destroy all such Software, with such destruction certified in writing to Ziroh Labs by a responsible representative of Licensor. Rights and obligations under this EULA that by their natures should survive will remain in effect after the termination or expiration of this EULA.`]
        },
        {
            title: `10. ASSIGNMENT`,
            contents: [`You may not assign or otherwise transfer any of its rights or obligations under this EULA without the prior written consent of Ziroh Labs.`]
        },
        {
            title: `11. ENTIRE AGREEMENT`,
            contents: [`This EULA, including any appendices or exhibits, represents the parties’ entire agreement relating to the EULA. This EULA may not be extended, amended, or modified, in whole or in part, except by further specific written agreement of the parties signed by their respective authorized officials.`]
        },
        {
            title: `12. ENFORCEMENT`,
            contents: [`This EULA shall be governed by the laws of the Republic of India. Suppose any provision of this Agreement is held to be invalid or unenforceable. In that case, such invalidity or unenforceability shall not affect the validity enforceability of the remaining provisions. All legal and/or dispute resolution proceedings, whether formal or otherwise, arising under or consequent to this EULA shall be conducted in San Francisco, California, United States of America.`]
        },
        {
            title: `13. WAIVER`,
            contents: [`The failure of either party to enforce any provision of this EULA shall not constitute a waiver of that right or future enforcement of that or any other provision.`]
        }
    ],

    drive: [
        {
            title: `1. DEFINITION`,
            contents: [`<strong>“You”</strong> and <strong>“Your”</strong> means the individual or legal entity or licensee of the Software under this EULA. <strong>“Use”</strong> or <strong>“Using”</strong> means to download, install, activate, access, or otherwise use the application “Zunu Drive" developed by Ziroh Labs. <strong>“Software”</strong> means the “Zunu Drive" developed by Ziroh Labs and any Upgrades made available by Ziroh Labs.`],
        },
        {
            title: `2. ACCEPTANCE OF TERMS`,
            contents: [`By Using the Software, you agree to be bound by the terms of the EULA. If you are entering into this EULA on behalf of an entity, you represent that you have authority to bind that entity.`]
        },
        {
            title: `3. LICENSE RESTRICTIONS`,
            contents: [`Ziroh Labs grants You a limited, non-exclusive and non-transferable license to Use object code versions of the Software and the Documentation solely for Your internal operations and in accordance with the Entitlement and the Documentation but not for the following purpose:
                <br /><br />
                (i) reproduce or copy the Software, except that You may make one (1) copy of the Software solely for archival purposes, provided that You agree to reproduce all copyright and other proprietary right notices on the archival copy made available by Ziroh Labs; or
                <br />
                (ii) use, cause or permit the use of, the Software in whole or in part for any purpose other than as permitted under this EULA; or
                <br />
                (iii) distribute, sell, lease, license or otherwise make the Software available to any third party without the prior written consent of Ziroh Labs; or
                <br />
                (iv) decompile, disassemble, decrypt, extract or otherwise reverse-engineer the Software.
                <br /><br />
                The Software “Zunu Drive” is owned by Ziroh Labs, and Ziroh Labs retains all right, title, and interest in and to the Software. You have the right only to use the Software in the manner and to the extent expressly licensed within this EULA and nothing in this EULA shall be construed as conferring any license to you of Ziroh Labs’s intellectual property rights, whether by estoppel, implication, or otherwise.
            `]
        },
        {
            title: `4. SUPPORT`,
            contents: [`During the EULA, Ziroh Labs shall support the Software in such manner, including providing updates, bug fixes, builds, or error corrections, as Ziroh Labs deems fit and proper (collectively “<strong>Software Updates</strong>”). If Ziroh Labs, at its sole option, provides Software Updates to you, the Software Updates will be considered part of the Software, and subject to the terms and conditions of this EULA.`]
        },
        {
            title: `5. DISCLAIMER OF WARRANTIES`,
            contents: [`You acknowledge that the Software is provided “as is, with all faults”, without any maintenance, debugging, support or improvement. Ziroh Labs MAKES NO REPRESENTATIONS AND EXTENDS NO WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED IN RESPECT OF THE SOFTWARE. FURTHERMORE, ZIROH LABS DISCLAIMS ALL EXPRESS OR IMPLIED CONDITIONS, REPRESENTATIONS AND WARRANTIES, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT IN RESPECT OF THE SOFTWARE. You shall not make any statements, representations or warranties with respect to any person or entity that are inconsistent with any limitation or disclaimer included in this Paragraph 7.`]
        },
        {
            title: `6. LIMITATION OF LIABILITY`,
            contents: [`You assume the entire risk as to the quality, results, performance and/or non-performance of the Software. You shall have the sole responsibility to adequately protect and backup your data or equipment used in connection with the Software. In no event shall Ziroh Labs (including its officers, employees, consultants and/ or agents) be responsible or liable for any direct, indirect, special, consequential, incidental, punitive or other damages whatsoever (including lost profits, business, revenue, use, data, or other economic advantage) in connection with, arising out of, or related to this EULA, regardless of the theory of liability, whether for breach or in tort (including negligence), even if Ziroh Labs may have been previously advised of the possibility of such damage. You shall not make any statements or accept any liabilities or responsibilities with respect to any person or entity that are inconsistent with any limitation included in this Paragraph 8.`]
        },{
            title: `7. NO OBLIGATION`,
            contents: [`This EULA does not represent or imply either: (a) any commitment whatsoever by you to purchase, acquire, commercially license or otherwise use (pursuant to other appropriate agreements) the Software and any other products or services of Ziroh Labs, or (b) any encouragement or inducement whatsoever to either You or Ziroh Labs to expend funds or other resources in the development or modification of the Software or any other products or services.`]
        },
        {
            title: `8. CONFIDENTIALITY`,
            contents: [`You acknowledge that the Software is proprietary and confidential to Ziroh Labs. Accordingly, any non-public information obtained by You regarding the Software or relating to Ziroh Labs <strong>(“Confidential Information”)</strong>, shall be kept in confidence by You for the term of this EULA and thereafter until such information is made available by Ziroh Labs to the public domain. You agree to protect the Software and any feedback provided by You from unauthorized disclosure, use, or release and to treat the Software and feedback with at least the same level of care as You use to protect You own proprietary computer programs and/or confidential information, but in no event less than a reasonable standard of care. If You becomes aware of any unauthorized licensing, copying, or use of the Software, you shall promptly notify Ziroh Labs.`]
        },
        {
            title: `9. TERM AND TERMINATION`,
            contents: [`This Agreement is effective upon Licensee's acceptance of the terms hereof, and the Agreement will remain in effect until termination. In the event that You breaches any of Your obligations under this EULA which breach is capable of being remedied, and if such breach has not been remedied within ten (10) calendar days after the date of notice in writing of such breach, Ziroh Labs may terminate this EULA immediately by written notice to you. Upon expiration or termination of this EULA, You shall (i) promptly cease use of the Software; and (ii) return to Ziroh Labs the original and all copies of the Software in your possession or, at the option of Ziroh Labs, You shall destroy all such Software, with such destruction certified in writing to Ziroh Labs by a responsible representative of Licensor. Rights and obligations under this EULA that by their natures should survive will remain in effect after termination or expiration of this EULA.`]
        },
        {
            title: `10. ASSIGNMENT`,
            contents: [`You may not assign or otherwise transfer any of its rights or obligations under this EULA without the prior written consent of Ziroh Labs.`]
        },
        {
            title: `11. ENTIRE AGREEMENT`,
            contents: [`This EULA, including any appendices or exhibits, represents the parties’ entire agreement relating to the EULA. This EULA may not be extended, amended, modified, in whole or in part, except by further specific written agreement of the parties signed by their respective authorized officials.`]
        },
        {
            title: `12. ENFORCEMENT`,
            contents: [`This EULA shall be governed by the laws of the Republic of India. If any provision of this Agreement is held to be invalid or unenforceable, such invalidity or unenforceability shall not in any way affect the validity or enforceability of the remaining provisions. All legal and/ or dispute resolution proceedings, whether formal or otherwise, arising under or consequent to this EULA shall be conducted in the city of San Francisco, California, United States of America.`]
        },
        {
            title: `13. WAIVER`,
            contents: [`The failure of either party to enforce any provision of this EULA shall not constitute a waiver of that right or future enforcement of that or any other provision.`]
        }
    ],
}