//--------------------CAMERA-----------------
import vinayak from '../../../../assets/webp/testimonial_users/camera/vinayak_abhishek.webp';
import bharadwaj from '../../../../assets/webp/testimonial_users/camera/bharadwaj_c.webp';
import amith from '../../../../assets/webp/testimonial_users/camera/amith_n.webp';
import sunil from '../../../../assets/webp/testimonial_users/camera/sunil_n.webp';
import ankur from '../../../../assets/webp/testimonial_users/camera/ankur_b.webp';
import ashish from '../../../../assets/webp/testimonial_users/camera/ashish_kejriwal.webp';
//-----------------MAIL--------------
import devid from '../../../../assets/webp/testimonial_users/mail/devid_patel.webp';
import shubham from '../../../../assets/webp/testimonial_users/mail/shubham_varshney.webp';
import abhishek from '../../../../assets/webp/testimonial_users/mail/abhishek_chattar.webp';
import shaswath from '../../../../assets/webp/testimonial_users/mail/shaswath_ranjan_nath.webp';
import rajesh from '../../../../assets/webp/testimonial_users/mail/rajesh.webp';
import subhakanta from '../../../../assets/webp/testimonial_users/mail/subhankanta_t.webp';
import ravi from '../../../../assets/webp/testimonial_users/mail/ravi_g.webp';
import prabhat from '../../../../assets/webp/testimonial_users/mail/prabhat_g.webp';
import yudhisthir from '../../../../assets/webp/testimonial_users/mail/yudhisthir_singh_gour.webp';
//------------------------MESSAGE-------------------
import ayushi from '../../../../assets/webp/testimonial_users/message/ayushi_b.webp';
import manoj from '../../../../assets/webp/testimonial_users/message/manoj.webp';
import vaksho from '../../../../assets/webp/testimonial_users/message/vaksho_v.webp';
import nikhil from '../../../../assets/webp/testimonial_users/message/nikhil.webp';
import ajay from '../../../../assets/webp/testimonial_users/message/ajay_d.webp';
import ved from '../../../../assets/webp/testimonial_users/message/ved.webp';
import thanush from '../../../../assets/webp/testimonial_users/message/thanush.webp';
import kapil from '../../../../assets/webp/testimonial_users/message/kapil.webp';
import sangkhajit from '../../../../assets/webp/testimonial_users/message/sangkhajit.webp';
//----------------------DRIVE-----------------------------
import asha from '../../../../assets/webp/testimonial_users/drive/asha.webp';
import himanshu from '../../../../assets/webp/testimonial_users/drive/himanshu_g.webp';
import nilesh from '../../../../assets/webp/testimonial_users/drive/nilesh_z.webp';
import akansha from '../../../../assets/webp/testimonial_users/drive/akansha_s.webp';
import rahul from '../../../../assets/webp/testimonial_users/drive/rahul_g.webp';
import biswajeet from '../../../../assets/webp/testimonial_users/drive/biswajeet_s.webp';
import raghav from '../../../../assets/webp/testimonial_users/drive/raghav_m.webp';
import chetan from '../../../../assets/webp/testimonial_users/drive/chetan_c.webp';
import akshay from '../../../../assets/webp/testimonial_users/drive/akshay_k.webp';


export const TESTIMONIALS = {
	ALL: [
		{
			testimonial: `This app is awesome. I never imagined this was possible.`,
			color: `#7EC10E`,
			bg: `#DDFFD4`,
			user: `Robert A.`,
		},
		{
			testimonial: `Zunu camera function helps me keep my parties away from my Gallery.`,
			color: `#F85959`,
			bg: `#FFD3E5`,
			user: `Thomas C.`
		},
		{
			testimonial: `The other app I had made me use a different password for every file.`,
			color: `#EBFF00`,
			bg: `#DBC5FF`,
			user: `Susan D.`
		},
		{
			testimonial: `Amazing way to store, retrieve, access, and restrict access to an amazing quantity of data. So many ways to use it.`,
			color: `#6AA6FF`,
			bg: `#D0F1FF`,
			user: `Victor F.`
		},
		{
			testimonial: `Great app for managing, uploading, and downloading remote files. Great alternative to the Cloud, DropBox, etc. The prices and added storage are very reasonable.`,
			color: `#45BAFC`,
			bg: `#FAFFC2`,
			user: `Marjorie G.`
		},
		{
			testimonial: `This is the only “free” way to use Cloud Services. Microsoft’s OneDrive will steal your content and then hold it hostage if you don’t pay their arbitrary fees. Definitely a hassle.`,
			color: `#FF6E40`,
			bg: `#FFD5B7`,
			user: `Albert L.`
		},
		{
			testimonial: `This SAVES TIME so I don’t have to mark up pictures before using them in reports.`,
			color: `#6B88FF`,
			bg: `#D6CCFF`,
			user: `Teresa K.`
		},
		{
			testimonial: `Use this for work ALL the time. I take thousands of pictures of facilities conditions.`,
			color: `#FF4F4F`,
			bg: `#FFC5C5`,
			user: `Charles J.`
		},
		{
			testimonial: `Amazing. All of my photos, music and Word/ Excel/ Note/ Office etc. Adobe and most every type of file is savable and accessible here. Sync is automatic, and files can be saved for offline use.`,
			color: `#FFEAB3`,
			bg: `#C4C6FF`,
			user: `Jerome M.`
		}
	],
	MAIL: [
		{
			testimonial: `Wow! Just wow! Zunu Mail provides the perfect privacy. Encrypted emails in it are fascinating. Emails appear normal in Zunu Mail,  but when I open it from Gmail, it's encrypted. So cool 👍`,
			color: `#7EC10E`,
			bg: `#DDFFD4`,
			user: `Devid P.`,
			img: devid,
		},
		{
			testimonial: `I've used Proton before, but setting up a new email account just for privacy was a hassle. With Zunu, it's much simpler and easier. I can use my current email accounts and still keep my emails private.`,
			color: `#F85959`,
			bg: `#FFD3E5`,
			user: `Shubam V.`,
			img: shubham,
		},
		{
			testimonial: `Zunu really impresses me by going beyond end-to-end encryption. Whenever I download an attachment in Zunu Mail, it stays encrypted and can only be seen within Zunu anytime. It never gets decrypted and stored on my device. It's Privacy all the way. Amazing stuff!`,
			color: `#EBFF00`,
			bg: `#DBC5FF`,
			user: `Abhishek C.`,
			img: abhishek,
		},
		{
			testimonial: `Wow you guys deserve a medal for this 🎖️. My favorite feature is I can keep using my existing gmail acounts and google can't read my emails. I'm never leaving Zunu 😂`,
			color: `#6AA6FF`,
			bg: `#D0F1FF`,
			user: `Shaswath R.`,
			img: shaswath,
		},
		{
			testimonial: `My emails and attachments remain safe even if I lose my device or even if it's breached. So awesome!`,
			color: `#45BAFC`,
			bg: `#FAFFC2`,
			user: `Rajesh.`,
			img: rajesh,
		},
		{
			testimonial: `This app has everything! They even let you switch back to the old services. They really care about their customers. Good luck, Team Zunu!`,
			color: `#FF6E40`,
			bg: `#FFD5B7`,
			user: `Subhankanta T.`,
			img: subhakanta,
		},
		{
			testimonial: `I Love this App. It not only encrypts my emails but also encrypts my email subject, which reveals the email's crux. `,
			color: `#FF4F4F`,
			bg: `#FFC5C5`,
			user: `Ravi G.`,
			img: ravi,
		},
		{
			testimonial: `I liked Microsoft's Outbox but didn't like that they read my emails without my permission. With Zunu, I get the same convenience but with privacy. I can work from anywhere, and my emails will be sent as soon as I'm back online.`,
			color: `#FFEAB3`,
			bg: `#C4C6FF`,
			user: `Prabhat G.`,
			img: prabhat,
		},
		{
			testimonial: `First of all, huge props to Team Zunu for their innovative thinking and introducing this amazing app 👏🏻. I've never seen feature to migrate to old services anywhere before. Very unique and secure app, keep up the work 👍🏻`,
			color: `#6B88FF`,
			bg: `#D6CCFF`,
			user: `Yudhisthir G.`,
			img: yudhisthir,
		}
	],
	DRIVE: [
		{
			testimonial: `OMG, my files sync in an encrypted way without any leaks to Google. Mindblowing privacy!`,
			color: `#7EC10E`,
			bg: `#DDFFD4`,
			user: `Asha.`,
			img: asha,
		},
		{
			testimonial: `No need for a new account on Zunu Drive. Zunu can make my current cloud accounts private. I just need to add them to Zunu. It's a really refreshing user experience!`,
			color: `#F85959`,
			bg: `#FFD3E5`,
			user: `Himanshu G.`,
			img: himanshu,
		},
		{
			testimonial: `Wow, this is incredible! I added my OneDrive to Zunu, kept using it, and Microsoft can't read my uploads. This is just epic!`,
			color: `#EBFF00`,
			bg: `#DBC5FF`,
			user: `Nilesh Z.`,
			img: nilesh,
		},
		{
			testimonial: `File sharing is super secure here. Cloud providers can't see what's being shared. That's Cool.`,
			color: `#6AA6FF`,
			bg: `#D0F1FF`,
			user: `Akansha S.`,
			img: akansha,
		},
		{
			testimonial: `Great app, quick and responsive. Helps me organize files effortlessly. No worries about storing tons of HD videos and pics. I can add as many cloud services as I want!`,
			color: `#45BAFC`,
			bg: `#FAFFC2`,
			user: `Rahul G.`,
			img: rahul,
		},
		{
			testimonial: `Manage Share is super handy. It shows which files are shared and who can view and edit them.`,
			color: `#FF6E40`,
			bg: `#FFD5B7`,
			user: `Biswajeet S.`,
			img: biswajeet,
		},
		{
			testimonial: `The app's clean and user-friendly interface, along with its stability and seamless performance across various platforms and systems, is truly impressive.`,
			color: `#FF4F4F`,
			bg: `#FFC5C5`,
			user: `Raghav M.`,
			img: raghav,
		},
		{
			testimonial: `Zunu means no more password hassles. No remembering, no resetting. Just amazing!`,
			color: `#FFEAB3`,
			bg: `#C4C6FF`,
			user: `Chetan C.`,
			img: chetan,
		},
		{
			testimonial: `Zunu gives me unlimited storage by letting me add all my cloud accounts to it. It's so easy to access everything in one place. Life's much simpler now!`,
			color: `#6B88FF`,
			bg: `#D6CCFF`,
			user: `Akshay K.`,
			img: akshay,
		}
	],
	CAMERA: [
		{
			testimonial: `With Zunu Camera, my photo collection is truly my own. It's liberating to capture moments without them piling up in my gallery and also without worrying about what if someone sees any of my private pictures.`,
			color: `#7EC10E`,
			bg: `#DDFFD4`,
			user: `Vinayak A.`,
			img: vinayak,
		},
		{
			testimonial: `I've finally found the camera app I can trust. Zunu's zero-knowledge encryption means my photos are for my eyes only. Say goodbye to invasive data collection.`,
			color: `#F85959`,
			bg: `#FFD3E5`,
			user: `Bharadwaj C.`,
			img: bharadwaj,
		},
		{
			testimonial: `No one can see my photos, even if my phone is lost or hacked or its password is compromised. Wow, this is amazing!`,
			color: `#EBFF00`,
			bg: `#DBC5FF`,
			user: `Ashish K.`,
			img: ashish,
		},
		{
			testimonial: `This is huge for me because my phone has been hacked in the past, so it's just a good feeling to know that at least that part of my privacy is esteemed`,
			color: `#6AA6FF`,
			bg: `#D0F1FF`,
			user: `Amith N.`,
			img: amith,
		},
		{
			testimonial: `As someone who values their privacy, I can't recommend Zunu Camera enough. It's not just about taking stunning photos; it's about knowing your memories are hack-proof. The ultimate trusty companion for every photo enthusiast.`,
			color: `#45BAFC`,
			bg: `#FAFFC2`,
			user: `Sunil N.`,
			img: sunil,
		},
		{
			testimonial: `The sharing in Zunu Camera is awesome. I choose who sees my photos after sharing. It's all up to me!`,
			color: `#FF6E40`,
			bg: `#FFD5B7`,
			user: `Ankur B.`,
			img: ankur,
		}
	],
	MESSAGE: [
		{
			testimonial: `Love the feature where the zunu automatically locks the app even when running in the background. Privacy to the core.`,
			color: `#7EC10E`,
			bg: `#DDFFD4`,
			user: `Ayushi B.`,
			img: ayushi,
		},
		{
			testimonial: `I control messages on both my and the recipient's phones. Amazing!`,
			color: `#F85959`,
			bg: `#FFD3E5`,
			user: `Manoj.`,
			img: manoj,
		},
		{
			testimonial: `Zunu Messages safeguards messages during Ransomware, Malware, and Virus attacks. Total Privacy.`,
			color: `#EBFF00`,
			bg: `#DBC5FF`,
			user: `Vaksho V.`,
			img: vaksho,
		},
		{
			testimonial: `Zunu Message eases my fear by ensuring that intimate conversations between me and my loved ones stay private.`,
			color: `#6AA6FF`,
			bg: `#D0F1FF`,
			user: `Nikhil G.`,
			img: nikhil,
		},
		{
			testimonial: `I use Zunu Message for private chats. Messages are free and encrypted. It's cool!`,
			color: `#45BAFC`,
			bg: `#FAFFC2`,
			user: `Ajay D.`,
			img: ajay,
		},
		{
			testimonial: `Great messaging app! I switched from my default unsecured one to Zunu Messages. It promises complete privacy`,
			color: `#FF6E40`,
			bg: `#FFD5B7`,
			user: `Ved P.`,
			img: ved,
		},
		{
			testimonial: `Love the fact that the app is automatically locked even when running in the background. Privacy to the core.`,
			color: `#FF4F4F`,
			bg: `#FFC5C5`,
			user: `Thanush G.`,
			img: thanush,
		},
		{
			testimonial: `Now, I don't need to add contacts manually. The app automatically syncs my contacts, making it easier for me to reach out to my friends. All this convenience on top of top notch Privacy.`,
			color: `#FFEAB3`,
			bg: `#C4C6FF`,
			user: `Kapil N.`,
			img: kapil,
		},
		{
			testimonial: `I no longer worry about my messages after sending them to my friends. Zunu Message's device-to-device encryption keeps everything private on other devices, too. I recommend this app.`,
			color: `#6B88FF`,
			bg: `#D6CCFF`,
			user: `Sangkhajit.`,
			img: sangkhajit,
		}
	]
};
