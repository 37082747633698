import styles from "./contactUs.module.scss";
import { ReactComponent as Illustration } from "../../assets/svg/contact_us_illustration.svg";
import { ReactComponent as IconPin } from "../../assets/svg/icon_location_pin.svg";
import { useForm } from "react-hook-form";
import { FormFields } from "./_types/formFields";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createTicketService } from "../../App.registration";
import { useDownloadBtnStore } from "../../StateManager/DownloadBtnStore";
import { Helmet } from "react-helmet";

//LAZY LOADING
import { lazily } from 'react-lazily';
const { InputField } = lazily(() => import('./components/InputField/inputField'));
const { FileUpload } = lazily(() => import('./components/FileUpload/fileUpload'));

export default function ContactUs() {

  const { isDownloadBtn, setIsDownloadBtn} = useDownloadBtnStore();
  const initialValues: FormFields = {
    name: "",
    email: "",
    message: "",
    attachments: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm({
    mode: "all",
    defaultValues: initialValues,
  });

  const attachmentsWatch = watch("attachments");
  const attachmentName = attachmentsWatch?.[0]?.name;
  const attachmentSize = attachmentsWatch?.[0]?.size;

  // console.log({ attachmentsWatch, attachmentName, attachmentSize });

  const onFormSubmit = async (data: FormFields) => {
    setisLoading(true);
    // console.log("[handleFormSubmit]", { data });

    const formData = new FormData();

    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("subject", "Zunu Contact Us");
    formData.append("description", data.message);
    formData.append("status", "2");
    formData.append("priority", "1");
    formData.append("group_id", "150000373202");
    if (data?.attachments) {
      formData.append("attachment", data?.attachments[0]);
    }

    try {
      const response = await createTicketService.CreateTicket(formData);

      if (response?.status === 200) {
        toast.success("Your Query has been registered, we will get back to you soon!");
        reset();
      } else {
        toast.error("Failed to register, please try again later");
      }
    }
    catch (error) {
      console.log(error);

    } finally {
      setisLoading(false)
    }
    
  };

  const [isLoading, setisLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsDownloadBtn(true);
  }, []);

  return (
    <section className={styles.contact_us_section}>
       <Helmet>
                <meta name="description" content="If you have any questions, concerns or issues , we're here to help you. Check here to find the answers to your questions and get in touch with us if you need to. " />
                <title>Zunu Contact-Us - Talk to us</title>
                
                <meta property="og:site_name" content="Zunu" />
                <meta property="og:title" content="Zunu Contact-Us - Talk to us" />
                <meta property="og:url" content="https://zunuprivacy.com/contact-us" />
                <meta property="og:description" content="If you have any questions, concerns or issues , we're here to help you. Check here to find the answers to your questions and get in touch with us if you need to. " />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://zunuprivacy.com/icon192.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@zirohlabs" />
                <meta name="twitter:title" content="Zunu: Software-defined security stack with privacy-preserving, performant cryptographic algorithms." />
                <meta name="twitter:description" content="If you have any questions, concerns or issues , we're here to help you. Check here to find the answers to your questions and get in touch with us if you need to." />
                <meta name="twitter:image"
                    content="https://zunuprivacy.com/icon192.png" />
            </Helmet>

      <div className={styles.contact_us_backdrop}></div>
      <div className={styles.contact_us_container}>
        <div className={styles.contact_us_header}>
          <h3>Contact Us</h3>
          <p>Our team will help you with your enquiries.</p>
        </div>
        <div className={styles.contact_us_main}>
          <div className={styles.contact_us_illustration}>
            <article className={styles.contact_us_registered_article}>
              <h3>Our Registered Offices</h3>
              <div className={styles.contact_us_registered_locations}>
                <div className={styles.contact_us_registered_locations_cards}>
                  <h4>
                    <IconPin className={styles.icon_pin} /> USA
                  </h4>
                  <p>335 Bryant St, Palo Alto, CA 94301, USA</p>
                </div>
                <div className={styles.contact_us_registered_locations_cards}>
                  <h4>
                    <IconPin className={styles.icon_pin} /> India
                  </h4>
                  <p>
                    21, 2nd Main Rd, Electronics City Phase 1, Bengaluru,
                    Karnataka 560100
                  </p>
                </div>
              </div>
            </article>
            <Illustration />
          </div>
          <form
            className={styles.contact_us_form}
            onSubmit={handleSubmit(onFormSubmit)}
          >
            <InputField
              label="Name"
              id="name"
              register={register}
              registerKey="name"
              validationSchema={{ required: "Required" }}
              errors={errors}
              required
            />
            <InputField
              label="Email"
              id="email"
              register={register}
              registerKey="email"
              validationSchema={{ required: "Required" }}
              errors={errors}
              type="email"
            />
            <FileUpload
              label="Attach Screenshot"
              id="attachments"
              register={register}
              registerKey="attachments"
              // validationSchema={{ required: "Required" }}
              errors={errors}
              type="file"
              fileNames={attachmentName}
              fileSizes={attachmentSize}
              handleFileRemove={() => {
                setValue("attachments", []);
              }}
            />
            <InputField
              label="Message"
              id="message"
              register={register}
              registerKey="message"
              validationSchema={{ required: "Required" }}
              errors={errors}
              isTextArea={true}
            />
            <button
              type="submit"
              className={styles.contact_us_form_button}
              disabled={isLoading}
            >
              {!isLoading ? (
                "Submit"
              ) : (
                <span className={styles.button_loader}>
                  <span>&bull;</span>
                  <span>&bull;</span>
                  <span>&bull;</span>
                </span>
              )}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}
