import message_thumbnail from '../../../../assets/webp/message_whitepaper_thumbnail.webp';
import zunu_thumbnail from '../../../../assets/webp/zunu_whitepaper_thumbnail.webp';
import mail_thumbnail from '../../../../assets/webp/mail_whitepaper_thumbnail.webp';
import drive_thumbnail from '../../../../assets/webp/drive_whitepaper_thumbnail.webp';
import gateway_thumbnail from '../../../../assets/webp/gateway_whitepaper_thumbnail.webp';
import camera_thumbnail from '../../../../assets/webp/camera_whitepaper_thumbnail.webp';

export const WHITEPAPERS={
    MAIL: [
        {
            title: `No one can Breach Your Inbox`,
            textPreview: ` This whitepaper discusses the prevalent use of email
            communication in today's digital age, highlighting its
            various purposes and challenges.`,
            readTime: `12 min read`,
            author: `Ziroh Labs`,
            publishDate: `3 May '24`,
            thumbnail: mail_thumbnail,
            url: 'https://websiteserver-svc.vault.ziroh.com/api/v1/whitepapers/Zunu_Mail_WP_preview.pdf',
        },
    ],

    DRIVE: [
        {
            title: `Unbreakable security for your Drive`,
            textPreview: `The modern digital landscape presents numerous
            challenges to privacy, with vast amounts of personal
            data generated.`,
            readTime: `15 min read`,
            author: `Ziroh Labs`,
            publishDate: `3 May '24`,
            thumbnail: drive_thumbnail,
            url: 'https://websiteserver-svc.vault.ziroh.com/api/v1/whitepapers/Zunu_Drive_preview.pdf',
        }, 
        // {
        //     title: `Unbreakable security for your Drive`,
        //     textPreview: `The modern digital landscape presents numerous
        //     challenges to privacy, with vast amounts of personal
        //     data generated.`,
        //     readTime: `15 min read`,
        //     author: `Ziroh Labs`,
        //     publishDate: `3 May '24`,
        //     thumbnail: drive_thumbnail,
        //     url: 'https://websiteserver-svc.vault.ziroh.com/api/v1/whitepapers/Zunu_Drive_preview.pdf',
        // }, 
        // {
        //     title: `Unbreakable security for your Drive`,
        //     textPreview: `The modern digital landscape presents numerous
        //     challenges to privacy, with vast amounts of personal
        //     data generated.`,
        //     readTime: `15 min read`,
        //     author: `Ziroh Labs`,
        //     publishDate: `3 May '24`,
        //     thumbnail: drive_thumbnail,
        //     url: 'https://websiteserver-svc.vault.ziroh.com/api/v1/whitepapers/Zunu_Drive_preview.pdf',
        // }, 
        // {
        //     title: `Unbreakable security for your Drive`,
        //     textPreview: `The modern digital landscape presents numerous
        //     challenges to privacy, with vast amounts of personal
        //     data generated.`,
        //     readTime: `15 min read`,
        //     author: `Ziroh Labs`,
        //     publishDate: `3 May '24`,
        //     thumbnail: drive_thumbnail,
        //     url: 'https://websiteserver-svc.vault.ziroh.com/api/v1/whitepapers/Zunu_Drive_preview.pdf',
        // }, 
    ],

    MESSAGE: [
        {
            title: `Ensuring Message Privacy with Zunu Message`,
            textPreview: `Messaging has evolved from traditional SMS to
            modern IP messaging, maintaining convenience and
            widespread use.`,
            readTime: `12 min read`,
            author: `Ziroh Labs`,
            publishDate: `3 May '24`,
            thumbnail: message_thumbnail,
            url: 'https://websiteserver-svc.vault.ziroh.com/api/v1/whitepapers/Zunu_Message_White_Paper_preview.pdf',
        }, 
    ],

    CAMERA: [
        {
            title: `Safeguarding Your memories and Loved One's Privacy`,
            textPreview: `Users capture and share photos and videos daily,
            often without realizing that their personal and cherished
            moments are exposed to`,
            readTime: `8 min read`,
            author: `Ziroh Labs`,
            publishDate: `3 May '24`,
            thumbnail: camera_thumbnail,
            url: 'https://websiteserver-svc.vault.ziroh.com/api/v1/whitepapers/Zunu_Camera_White_Paper_preview.pdf',
            // url: 'https://vault-svc1.dev.ziroh.com/websiteserver/api/v1/whitepapers/Zunu_Camera_White_Paper_preview.pdf',
        }, 
    ],

    GATEWAY: [
        {
            title: `Breach of Personal Data by Banking Institutions`,
            textPreview: `In today's digital age, the privacy of individuals is
            being infringed upon every minute of every day.`,
            readTime: `14 min read`,
            author: `Ziroh Labs`,
            publishDate: `3 May '24`,
            thumbnail: gateway_thumbnail,
            url: 'https://websiteserver-svc.vault.ziroh.com/api/v1/whitepapers/Break_of_Privacy_Banks_and_Financial_Institutions_preview.pdf',
        }, 
    ],

    ZUNU: [
        {
            title: `Zunu Technology`,
            textPreview: `Zunu is a secure and highly lightweight virtual machine with Always Encrypted Storage and
            Always Encrypted Computing.`,
            readTime: `21 min read`,
            author: `Ziroh Labs`,
            publishDate: `3 May '24`,
            thumbnail: zunu_thumbnail,
            url: 'https://websiteserver-svc.vault.ziroh.com/api/v1/whitepapers/Zunu_Technical_Primer_preview.pdf',
            // url: 'https://vault-svc1.dev.ziroh.com/websiteserver/api/v1/whitepapers/Zunu_Camera_White_Paper_preview.pdf'
        }, 
    ],
}