import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { mailSenderService } from '../../../../App.registration';
import { ReactComponent as IconClose } from "../../../../assets/svg/close.svg";
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import styles from './registrationModal.module.scss'
import 'react-phone-number-input/style.css';

function RegistrationModal(data:any) {


    const [phoneNumber, setPhoneNumber] = useState('')
    const [isRegistering, setIsRegistering] = useState(false);
    const [country, setCountry] = useState<any | undefined>('US')
    const [subject, setSubject] = useState<string>("")

   

    useEffect(() => {
        setSubject(data.subject)
        let location = sessionStorage.getItem("Localizaton")

        if (location == '0') {
            setCountry('IN')
        }
        if (location == '1') {
            setCountry('US')
        }
        if (location == '2') {
            setCountry('DE')
        }
        if (location == '3') {
            setCountry('JP')
        }
    }, [sessionStorage.getItem("Localizaton")])


    


    async function sendMailFromAPI(name: string, email: string, phone: string) {
        try {
            const response = await mailSenderService.MailSender(name, email, phone, subject);

            if (response?.status == 200) {
                toast.success("Your details are successfully Registered")
                clearForm()
                setIsRegistering(false)
                setTimeout(() => {
                    data.setIsModal(false)
                }, 1500);
            }
            else {
                toast.error("Could not register, try again later");
                setIsRegistering(false)
            }
        }
        catch (error) {
            console.log(error);

        } finally {
        }
    }

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = (data: any) => {
        setIsRegistering(true)
        sendMailFromAPI(data.Name, data.Email, data.PhoneNo)
    }


    function clearForm() {
        reset()
        setPhoneNumber('')
    }

    function handleCloseClick(){
        clearForm()
        data.setIsModal(false);
    }

    return (
        <div className={styles.modalContainer}>
            <div className={styles.modal}>
                <div className={styles.modalHeader}>
                    <p>Registration Form</p>
                    <IconClose className={styles.closeIcon} onClick={handleCloseClick} />
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.inputWrapper}>
                        <p className={styles.inputHeading}>Your Name</p>
                        <div className={styles.inputContainer}>
                            <input type="text" {...register("Name")} required />
                        </div>
                    </div>
                    <div className={styles.inputWrapper}>
                        <p className={styles.inputHeading}>Your Email</p>
                        <div className={styles.inputContainer}>
                            <input type="email" {...register("Email")} required />
                        </div>
                    </div>
                    <div className={styles.inputWrapper}>
                        <p className={styles.inputHeading}>Phone No.</p>
                        <div className={`${styles.inputContainer} ${errors.PhoneNo ? `${styles.errorBorder}` : ''}`}>
                            <PhoneInput
                                defaultCountry={country}
                                value={phoneNumber}
                                addInternationalOption={false}
                                limitMaxLength={true}
                                international
                                rules={{ required: true }}
                                countryCallingCodeEditable={false}
                                {...register("PhoneNo", { required: true, max: 6, min: 13 })}
                                onChange={(number: any) => { setPhoneNumber(number); }}
                                error={phoneNumber ? (isPossiblePhoneNumber(phoneNumber) ? undefined : 'Invalid phone number') : 'Phone number required'}
                            />
                        </div>
                        {errors.PhoneNo && <p className={styles.errorMessage}>enter valid phone number</p>}
                    </div>

                    <input disabled={isRegistering} className={`${styles.submitBtn} ${isRegistering ? `${styles.submitBtnDisabled}` : ``}`} type="submit" />
                </form>

            </div>
        </div>
    )
}

export  {RegistrationModal}