import * as React from 'react';
import styles from './zunuMessage.module.scss'
import { ReactComponent as MessageTransferDoodle } from '../../assets/svg/message_transfer_doodle.svg';
import { ReactComponent as UserBlue } from '../../assets/svg/user_blue_shadow.svg';
import { ReactComponent as UserOrange } from '../../assets/svg/user_orange_shadow.svg';
import { ReactComponent as CardIcon1 } from '../../assets/svg/message_card_icon1.svg';
import { ReactComponent as CardIcon2 } from '../../assets/svg/message_card_icon2.svg';
import { ReactComponent as CardIcon3 } from '../../assets/svg/message_card_icon3.svg';
import { ReactComponent as CardIcon4 } from '../../assets/svg/message_card_icon4.svg';
import { ReactComponent as CardIcon5 } from '../../assets/svg/message_card_icon5.svg';
import { ReactComponent as CardIcon6 } from '../../assets/svg/message_card_icon6.svg';
import { ReactComponent as CardIcon7 } from '../../assets/svg/message_card_icon7.svg';
import { ReactComponent as CardIcon8 } from '../../assets/svg/message_card_icon8.svg';
import { ReactComponent as CardIcon9 } from '../../assets/svg/message_card_icon9.svg';
import { ReactComponent as CardIcon10 } from '../../assets/svg/message_card_icon10.svg';
import { ReactComponent as CardIcon11 } from '../../assets/svg/message_card_icon11.svg';
import { ReactComponent as CardIcon12 } from '../../assets/svg/message_card_icon12.svg';
import { ReactComponent as CardIcon13 } from '../../assets/svg/message_card_icon13.svg';
import USP1 from '../../assets/webp/message_usp1.webp';
import USP2 from '../../assets/webp/message_usp2.webp';
import USP1Mobile from '../../assets/webp/drive_usp1_mobile.webp';
import USP2Mobile from '../../assets/webp/drive_usp2_mobile.webp';
// import BannerImage from '../../assets/webp/message_banner_image.webp';
import BannerImage from '../../assets/webp/message_banner_image_2.webp';
import BannerImageMobile from '../../assets/webp/message_banner_image_mobile.webp';
import SetApaprtImage from '../../assets/webp/message_setapart_image.webp';
import SetApaprtImageMobile from '../../assets/webp/message_setapart_image_mobile.webp';
import MessageFeat1 from '../../assets/webp/message_feat1.webp';
import MessageFeat2 from '../../assets/webp/message_feat2.webp';
import MessageFeat3 from '../../assets/webp/message_feat3.webp';
import MessageFeat4 from '../../assets/webp/message_feat4.webp';
import MessageFeat5 from '../../assets/webp/message_feat5.webp';
import MessageFeat6 from '../../assets/webp/message_feat6.webp';
import MessageFeat7 from '../../assets/webp/message_feat7.webp';
import { useThemeStore } from '../../StateManager/ThemeStore';
import { DEVICE, THEME } from '../../_shared/constants/constants';
import { Helmet } from 'react-helmet';
import { useEffect, useRef } from 'react';
import { QUESTIONS } from '../Support/questions_data';
import { TESTIMONIALS } from '../Home/components/Testimonials/testimonials_data';
import { useDeviceStore } from '../../StateManager/DeviceStore';
import { Fade, AttentionSeeker } from "react-awesome-reveal";
import { useDownloadBtnStore } from '../../StateManager/DownloadBtnStore';
import { WHITEPAPERS } from '../_shared/components/WhitePapers/whitePapersData';


//LAZY LOADING
import { lazily } from 'react-lazily';
const { Testimonials } = lazily(() => import('../Home/components/Testimonials/testimonials'));
const { WhitePapers } = lazily(() => import('../_shared/components/WhitePapers/whitePapers.component'));
const { FloaterDownload } = lazily(() => import('../_shared/components/FloaterDownload/floaterDownload.component'));
const { FloaterNavigator } = lazily(() => import('../_shared/components/floaterNavigator/floaterNavigator.component'));
const { ProductFaqs } = lazily(() => import('../_shared/components/productFaqs/productFaqs.component'));

export function ZunuMessage() {

    const { theme } = useThemeStore()
    const { device, setDevice } = useDeviceStore();
    const { isDownloadBtn, setIsDownloadBtn} = useDownloadBtnStore();
    const headerRef: any = useRef();

    useEffect(() => {
        if (device != DEVICE.MOBILE) {
            setIsDownloadBtn(true)
            return
        }
        else {
            setIsDownloadBtn(false)
            return
        }
    }, [device])

    return (
        <div className={styles.productPage}>

            <Helmet>
                <meta name="description" content="Unlock the power of Private Messaging with 100% Privacy." />
                <title>Zunu Message - Privacy-First Messaging App.</title>
                
                <meta property="og:site_name" content="Zunu" />
                <meta property="og:title" content="Zunu Camera - Privacy-First Camera App" />
                <meta property="og:url" content="https://zunuprivacy.com/message" />
                <meta property="og:description" content="Unlock the power of Private Messaging with 100% Privacy." />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://zunuprivacy.com/icon192.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@zirohlabs" />
                <meta name="twitter:title" content="Zunu: Software-defined security stack with privacy-preserving, performant cryptographic algorithms." />
                <meta name="twitter:description" content="Unlock the power of Private Messaging with 100% Privacy." />
                <meta name="twitter:image"
                    content="https://zunuprivacy.com/icon192.png" />
            </Helmet>

            <div className={styles.productIntro}>
                <div className={styles.productIntroText} ref={headerRef}>
                    <Fade delay={200} className={styles.introTextWrapper}>
                        <p>Talk Worry-Free. Seamless<br /> Conversation with Total <br /><span>Privacy</span> Online and Offline.</p>
                    </Fade>
                </div>
                <MessageTransferDoodle className={styles.fileTransferDoodle} />
                <UserOrange className={styles.userOrange} />
                <UserBlue className={styles.userBlue} />

                <FloaterNavigator />
            </div>

            <FloaterDownload />


            <div className={styles.productUSP}>
                <p className={styles.uspHeading}>Zunu Message: Keep your Messages 100% <span>Private.</span></p>

                <div className={`${styles.uspContainer} ${styles.usp1}`}>
                    <div className={styles.usptextContainer}>
                        <Fade fraction={0.2} cascade damping={0.2}>
                            <div className={styles.uspText}>

                                <p>Privacy Risks</p>
                                <p>in <span>Unencrypted</span> Messaging Services.</p>
                            </div>
                        </Fade>
                        {device != DEVICE.MOBILE &&
                            <div className={styles.uspTextCardsConatiner}>
                                <div className={styles.uspTextCard}><CardIcon1 /><p>Message confidentiality is not guaranteed as they are <span>exposed to third parties</span> such as <span>Network</span> and <span>Mobile Operators</span>.</p></div>
                                <div className={styles.uspTextCard}><CardIcon2 /><p>Applications on the phone <span>granted access</span> to the SMS Inbox to <span>read</span> all sent and <span>received</span> messages.</p></div>
                                <div className={styles.uspTextCard}><CardIcon3 /><p>Confidentiality of Messages is at <span>risk</span> if the phone is <span>stolen</span> or its password is <span>compromised.</span></p></div>
                                <div className={styles.uspTextCard}><CardIcon4 /><p>There is <span>no control</span> over messages once <span>delivered</span> to the recipient. They can be <span>shared</span> with anyone.</p></div>
                                <div className={styles.uspTextCard}><CardIcon5 /><p>Messages are <span>vulnerable</span> to <span>Ransomware, Malware</span>, and <span>Virus Attacks</span> on the phone.</p></div>
                                <div className={styles.uspTextCard}><CardIcon13 /><p><span>Photos & Videos</span> communicated over messages are <span>not private</span> after they are downloaded on devices.</p></div>
                            </div>
                        }
                    </div>
                    <figure>
                        <img src={device == DEVICE.MOBILE ? `${USP1}` : `${USP1}`} alt="Zunu Mail USP" />
                    </figure>

                    {device == DEVICE.MOBILE &&
                        <div className={styles.uspTextCardsConatiner}>
                            <div className={styles.uspTextCard}><CardIcon1 /><p>Message confidentiality is not guaranteed as they are <span>exposed to third parties</span> such as <span>Network</span> and <span>Mobile Operators</span>.</p></div>
                            <div className={styles.uspTextCard}><CardIcon2 /><p>Applications on the phone <span>granted access</span> to the SMS Inbox to <span>read</span> all sent and <span>received</span> messages.</p></div>
                            <div className={styles.uspTextCard}><CardIcon3 /><p>Confidentiality of Messages is at <span>risk</span> if the phone is <span>stolen</span> or its password is <span>compromised.</span></p></div>
                            <div className={styles.uspTextCard}><CardIcon4 /><p>There is <span>no control</span> over messages once <span>delivered</span> to the recipient. They can be <span>shared</span> with anyone.</p></div>
                            <div className={styles.uspTextCard}><CardIcon5 /><p>Messages are <span>vulnerable</span> to <span>Ransomware, Malware</span>, and <span>Virus Attacks</span> on the phone.</p></div>
                            <div className={styles.uspTextCard}><CardIcon13 /><p><span>Photos & Videos</span> communicated over messages are <span>not private</span> after they are downloaded on devices.</p></div>
                        </div>
                    }
                </div>

                <div className={`${styles.uspContainer} ${styles.usp2}`}>
                    <div className={styles.usptextContainer}>
                        <Fade fraction={0.2} cascade damping={0.2}>
                            <div className={styles.uspText}>

                                <p>Ultimate Message Privacy with Zunu Message</p>
                                <p><span>Device-to-Device End-to-End Encryption</span></p>
                            </div>
                        </Fade>
                        {device != DEVICE.MOBILE &&
                            <div className={styles.uspTextCardsConatiner}>
                                <div className={styles.uspTextCard}><CardIcon6 /><p>Photos and videos communicated via Zunu Message, when downloaded and stored in the gallery, <span>remain invisible</span> to 3rd-party apps with Gallery access permission.</p></div>
                                <div className={styles.uspTextCard}><CardIcon7 /><p>3rd party Apps with permission to access SMS Inbox <span>cannot read</span> Messages.</p></div>
                                <div className={styles.uspTextCard}><CardIcon8 /><p>Messages sent and received are invisible to <span>telecom service providers</span> and <span>mobile operators</span>.</p></div>
                                <div className={styles.uspTextCard}><CardIcon10 /><p>Complete <span>Control</span> over messages even after they are <span>delivered</span>.</p></div>
                                <div className={styles.uspTextCard}><CardIcon11 /><p>Messages on devices remain <span>safe</span> from <span>Ransomware, Malware</span>, and <span>Virus attacks</span>.</p></div>
                                <div className={styles.uspTextCard}><CardIcon12 /><p>Message confidentiality <span>remains intact</span> when the device is lost or its <span>password is compromised.</span></p></div>
                            </div>
                        }
                    </div>
                    <figure>
                        <img src={device == DEVICE.MOBILE ? `${USP2}` : `${USP2}`} alt="Zunu Mail USP" />
                    </figure>
                    {device == DEVICE.MOBILE &&
                        <div className={styles.uspTextCardsConatiner}>
                            <div className={styles.uspTextCard}><CardIcon6 /><p>Photos and videos communicated via Zunu Message, when downloaded and stored in the gallery, <span>remain invisible</span> to 3rd-party apps with Gallery access permission.</p></div>
                            <div className={styles.uspTextCard}><CardIcon7 /><p>3rd party Apps with permission to access SMS Inbox <span>cannot read</span> Messages.</p></div>
                            <div className={styles.uspTextCard}><CardIcon8 /><p>Messages sent and received are invisible to <span>telecom service providers</span> and <span>mobile operators</span>.</p></div>
                            <div className={styles.uspTextCard}><CardIcon10 /><p>Complete <span>Control</span> over messages even after they are <span>delivered</span>.</p></div>
                            <div className={styles.uspTextCard}><CardIcon11 /><p>Messages on devices remain <span>safe</span> from <span>Ransomware, Malware</span>, and <span>Virus attacks</span>.</p></div>
                            <div className={styles.uspTextCard}><CardIcon12 /><p>Message confidentiality <span>remains intact</span> when the device is lost or its <span>password is compromised.</span></p></div>
                        </div>
                    }
                </div>

            </div>


            <div className={styles.productBanner}>
                <Fade cascade damping={0.4} fraction={0.1}>
                    <p className={styles.bannerHeading}>Express Without Filtering. You Message Stays <span>Private</span> and <span>Protected.</span></p>
                    <figure>
                        <img src={device == DEVICE.MOBILE ? `${BannerImageMobile}` : `${BannerImage}`} alt="Zunu Mail on all Devices" />
                    </figure>
                </Fade>
            </div>

            {/* <div className={styles.productCardsConatiner}>
                <div className={styles.productCard}>
                    <CardIcon1 />
                    <AttentionSeeker effect='pulse'>
                        <p>Experience Privacy beyond <span>End-to-End Encryption</span></p>
                    </AttentionSeeker>
                </div>
                <div className={styles.productCard}>
                    <CardIcon2 />
                    <AttentionSeeker effect='pulse' delay={400}>
                        <p>Add Zunu's <span>100% Privacy</span> to your current Email Address.</p>
                    </AttentionSeeker>
                </div>
                <div className={styles.productCard}>
                    <CardIcon3 />
                    <AttentionSeeker effect='pulse' delay={800}>
                        <p>Search and Quickly Retrieve Emails in the most privacy preserving way.</p>
                    </AttentionSeeker>
                </div>
                <div className={styles.productCard}>
                    <CardIcon4 />
                    <AttentionSeeker effect='pulse' delay={1200}>
                        <p>Emails are <span>Secured</span> from the moment you start composing. Privacy from the Go. </p>
                    </AttentionSeeker>
                </div>
                <div className={styles.productCard}>
                    <CardIcon5 />
                    <AttentionSeeker effect='pulse' delay={1600}>
                        <p><span>Emails are private</span> when your phone is lost, its password is compromised, and even when your email account is hacked.</p>
                    </AttentionSeeker>
                </div>
                <div className={styles.productCard}>
                    <CardIcon6 />
                    <AttentionSeeker effect='pulse' delay={2000}>
                        <p><span>Email Confidentiality</span> During Ransomware, Malware and Virus Attacks.</p>
                    </AttentionSeeker>
                </div>
            </div> */}

            <div className={styles.setApart}>
                <div className={styles.setApartHead}>
                    <Fade cascade damping={0.2}>
                        <p className={styles.heading}>What sets Zunu Message Apart?</p>
                        <p className={styles.subHeading}>It's Unmatched Device-to-Device End-to-End Encryption for 100% Privacy.</p>
                    </Fade>
                </div>
                <figure>
                    <img src={device == DEVICE.MOBILE ? `${SetApaprtImageMobile}` : `${SetApaprtImage}`} alt="Zunu Drive explaination" />
                </figure>
            </div>

            <div className={styles.productFeatures}>
                <Fade>
                    <p className={styles.productFeaturesHeading}>Chat with <span>Confidence</span>. Your Messages are 100% <span>Private</span>.</p>
                </Fade>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Fade>
                            <p className={styles.featureHeading}>Protected, Invisible Messages</p>
                        </Fade>
                        <ul>
                            <Fade cascade damping={0.2} delay={100} triggerOnce>
                                <li>Experience messaging the way it should be – secure, private, and under your control. </li>
                                <li>Complete Confidentiality in all Communications.</li>
                            </Fade>
                        </ul>
                    </div>

                    <figure>
                        <img src={MessageFeat1} alt="Safe from Dark web" />
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Fade>
                            <p className={styles.featureHeading}>Secure on Device</p>
                        </Fade>
                        <ul>
                            <Fade cascade damping={0.2} delay={100} triggerOnce>
                                <li>Messages are stored encrypted on your devices.</li>
                                <li>3rd party apps with inbox access cannot read messages.</li>
                                <li>Message confidentiality is guaranteed at all times.</li>
                            </Fade>
                        </ul>
                    </div>

                    <figure>
                        <img src={MessageFeat2} alt="Safe from Dark web" />
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Fade>
                            <p className={styles.featureHeading}>Secure over Network</p>
                        </Fade>
                        <ul>
                            <Fade cascade damping={0.2} delay={100} triggerOnce>
                                <li>Messages travel securely over the network, hidden from telecom service providers.</li>
                                <li>Express freely without any unauthorized access to your messages.</li>
                            </Fade>
                        </ul>
                    </div>

                    <figure>
                        <img src={MessageFeat3} alt="Safe from Dark web" />
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Fade>
                            <p className={styles.featureHeading}>Complete Control, Zero Compromises, Easy-to-use</p>
                        </Fade>
                        <ul>
                            <Fade cascade damping={0.2} delay={100} triggerOnce>
                                <li>Control messages even after they are received.</li>
                                <li>Message boldly without filtering your thoughts.</li>
                            </Fade>
                        </ul>
                    </div>

                    <figure>
                        <img src={MessageFeat4} alt="Safe from Dark web" />
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Fade>
                            <p className={styles.featureHeading}>Protected from Hackers</p>
                        </Fade>
                        <ul>
                            <Fade cascade damping={0.2} delay={100} triggerOnce>
                                <li>On-device messages are safeguarded from Ransomware, Malware, and Viruses.</li>
                                <li>Enjoy seamless confidentiality and privacy for Messages.</li>
                            </Fade>
                        </ul>
                    </div>

                    <figure>
                        <img src={MessageFeat5} alt="Safe from Dark web" />
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Fade>
                            <p className={styles.featureHeading}>Write with Confidence</p>
                        </Fade>
                        <ul>
                            <Fade cascade damping={0.2} delay={100} triggerOnce>
                                <li>Messages stay private, even if you or the recipient lose the device.</li>
                                <li>Messages remain secure, even if passwords of your or the recipients device are compromised.</li>
                            </Fade>
                        </ul>
                    </div>

                    <figure>
                        <img src={MessageFeat6} alt="Safe from Dark web" />
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Fade>
                            <p className={styles.featureHeading}>Effortless Messaging in Online and Offline Modes.</p>
                        </Fade>
                        <ul>
                            <Fade cascade damping={0.2} delay={100} triggerOnce>
                                <li>Uninterrupted Texting: Strong, Weak, or No Internet – Always On.</li>
                                <li>Both modes are in One App.</li>
                            </Fade>
                        </ul>
                    </div>

                    <figure>
                        <img src={MessageFeat7} alt="Safe from Dark web" />
                    </figure>
                </div>


            </div>

            <WhitePapers whitepapers={WHITEPAPERS.MESSAGE} />
            <ProductFaqs data={QUESTIONS.message.faq} heading="Frequently asked questions" background="#D1F5DB" url="/support?p=message" />
            <Testimonials data={TESTIMONIALS.MESSAGE} heading="What our Users say about Zunu Message" />

        </div>
    )
}