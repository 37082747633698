import React, { RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react'
import styles from './support.module.scss'
import useSupportHook from './support.hook';
import parse from "html-react-parser";
import { ReactComponent as Expand } from '../../assets/svg/arrow-down.svg';
import { ReactComponent as VideoIcon } from '../../assets/svg/video_cam_icon.svg';
import { QUESTIONS } from './questions_data';
import joinIllustrationLight from '../../assets/webp/join_session_illustration_light.webp';
import joinIllustrationDark from '../../assets/webp/join_session_illustration_dark.webp';
import gettingStartedIllustrationLight from '../../assets/webp/gettingStarted_illustration_light.webp';
import gettingStartedIllustrationDark from '../../assets/webp/gettingStarted_illustration_dark.webp';
import usingZunuIllustrationLight from '../../assets/webp/usingZunu_illustration_light.webp';
import usingZunuIllustrationDark from '../../assets/webp/usingZunu_illustration_dark.webp';
import securityIllustrationLight from '../../assets/webp/security_illustration_light.webp';
import securityIllustrationDark from '../../assets/webp/security_illustration_dark.webp';
import { THEME, ZUNU_SUPPORT_GRP_ID } from '../../_shared/constants/constants';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ReactComponent as Mail } from '../../assets/svg/mailAccount.svg';
import { ReactComponent as Zunu_icon } from '../../assets/svg/zunu_icon.svg';
import { ReactComponent as FlagT } from '../../assets/svg/flagTopic.svg';
import { ReactComponent as ImpDevices } from '../../assets/svg/important_devices.svg';
import { ReactComponent as FeedBack } from '../../assets/svg/messageFeedback.svg';
import { ReactComponent as Attachsvg } from '../../assets/svg/attachmentS.svg';
import { ReactComponent as MailAc } from '../../assets/svg/zunuMailAcc.svg';
import { ReactComponent as CloseX } from '../../assets/svg/closeX.svg';
import { createTicketService } from '../../App.registration';
import { toast } from 'react-toastify';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import { useDownloadBtnStore } from '../../StateManager/DownloadBtnStore';


const Support = () => {
    const { isDownloadBtn, setIsDownloadBtn} = useDownloadBtnStore();

    const {
        isDropdown, setIsDropdown,
        products, activeProduct,
        handleProductSelect,
        isZunu, isCamera, isMail, isMessage, isDrive,
        topics, isGettingStarted, isUsing, isSecurity,
        handleTopicClick, question_query,
        theme, isMobile, activeTopic,
    } = useSupportHook();

    const [modal, setModal] = useState(false);
    const toggleModal = () => {
        setModal(!modal);
    };

    const [mail, setMail] = useState('')
    const [account, setAccount] = useState('')
    const [product, setProduct] = useState('')
    const [os, setOs] = useState('')
    const [topic, setTopic] = useState('')
    const [message, setMessage] = useState('')
    const [ss, setSs] = useState<File | null>(null)
    const [error, setError] = useState('')
    const [count, setCount] = useState(0)
    const [errorm, setErrorM] = useState('')
    const [isloading, setIsloading] = useState(false);


    const handleMailChange = (e: any) => {
        setMail(e.target.value);
    };

    const handleAccChange = (e: any) => {
        setAccount(e.target.value);
    };

    const handleProductChange = (e: any) => {
        setProduct(e.target.value);
    };

    const handleOSChange = (e: any) => {
        setOs(e.target.value);
    };

    // const handleTopicChange = (e:any) => {
    //  setTopic(e.target.value);
    //};

    const handleMessageChange = (e: any) => {
        if (e.target.value.length) {
            let charCount = 50 - e.target.value.length;
            if (charCount >= 0) {
                setCount(charCount);
            }
            else {
                setCount(0);
            }
        }
        setMessage(e.target.value);

        const minLength = 50;
        if (message.length < minLength) {
            setError(`**message should be atleast 50 characters long ,
              `)
        }
        else {
            setError('');
        }
    };


    const changeHandler = (e: any) => {
        e.preventDefault()
        if (e.target.files.length > 0) {
            let filename = e.target.files[0].name;
            const maxsize = 20 * 1024 * 1024; //20mb 
            if (e.target.files[0].size < maxsize) {
                setSs(e.target.files[0]);
                setErrorM('');
            }
            else {
                e.target.value = null;
                setSs(null);
                setErrorM(`**file should be less than 20mb`);
            }
        }

    }

    const fileRef = useRef<HTMLInputElement>(null);

    const Clearform = () => {
        setMail('')
        setAccount('')
        setProduct('')
        setOs('')
        // setTopic('')
        setMessage('')
        setSs(null)

        if (fileRef.current) {
            fileRef.current.value = '';
        }

    };

    const SubmitHandle = (e: any) => {
        e.preventDefault();

        const minLength = 50;
        if (message.length < minLength) {
            setError(`**message should be atleast 50 characters long ,
              `)
        }
        else {
            setError('');

            console.log(mail);
            console.log(account);
            console.log(product);
            console.log(os);
            // console.log(topic);
            console.log(message);
            // console.log(e.target[5].files[0]);
            console.log(ss);
            setIsloading(true);
            createTicket(mail, message, account, product, os, ss)

        }

    }


    async function createTicket(email: string, description: string, zunuAccount: string, product: string, os: string, attachments?: any) {

        var formData = new FormData();
        formData.append("name", email);
        formData.append("email", email);
        formData.append("subject", "Zunu Support");
        formData.append("description", description);
        formData.append("status", "2");
        formData.append("priority", "1");
        formData.append("group_id", ZUNU_SUPPORT_GRP_ID);
        if (attachments) {
            formData.append("attachment", attachments);
        }
        formData.append("custom_fields[cf_zunu_account]", zunuAccount)
        formData.append("custom_fields[cf_product]", product)
        formData.append("custom_fields[cf_os]", os)


        try {
            const response = await createTicketService.CreateTicket(formData);

            if (response?.status === 200) {
                toast.success("Your Query has been registered, we will get back to you soon!");
                Clearform();
                setTimeout(() => {
                    setModal(false)
                }, 1500);
                setIsloading(false)
            } else {
                toast.error("Failed to register, please try again later");
                setIsloading(false)
            }
        }
        catch (error) {
            console.log(error);

        } finally {
            setIsloading(false)
        }
    }

    useLayoutEffect(() => {

        setTimeout(() => {
            var element: HTMLElement | null = document.getElementById("scroll");
            element?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }, 500);

    }, [])

    function FAQArticle({ id, title, contents }: { id: string, title: string, contents: any }) {
        const [isClicked, setIsClicked] = useState(false);
        const [isScroll, setIsScroll] = useState(false);

        useEffect(() => {
            if (question_query && question_query.toLowerCase() === title.toLowerCase()) {
                setIsClicked(true)
                setIsScroll(true)
            }
        }, [])

        return (
            <article
                className={styles.faq_article_container}
                onClick={() => {
                    setIsClicked(!isClicked);
                }}
                id={isScroll ? 'scroll' : ''}
            >
                <div className={`${styles.title_div} ${isClicked ? `${styles.title_active}` : ''}`}>
                    <div>{id}. {title}</div>
                    <span>
                        <Expand
                            className={`${styles.expand_img} ${isClicked ? `${styles.expand_img_rotated}` : ''}`}
                        />
                    </span>
                </div>

                {isClicked && (
                    <article className={`${styles.faq_article_contents}`} onClick={(e) => {
                        e.stopPropagation();
                    }}>

                        {contents.map((item: any) => {
                            return item.imgSrc ? (
                                <img
                                    src={item.imgSrc.src}
                                    alt={item.altText}
                                    className={styles.faq_article_contents_img}
                                />
                            ) : (
                                parse(item)
                            );
                        })}
                    </article>
                )}
            </article>
        );
    }



    return (
        <div className={styles.supportPage}>
            <Helmet>
                <meta name="description" content="Get Support" />
                <title>Help & Support - Zunu</title>
                
                <meta property="og:site_name" content="Zunu" />
                <meta property="og:title" content="Help & Support - Zunu" />
                <meta property="og:url" content="https://zunuprivacy.com" />
                <meta property="og:description" content="Get Support" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://zunuprivacy.com/icon192.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@zirohlabs" />
                <meta name="twitter:title" content="Zunu: Software-defined security stack with privacy-preserving, performant cryptographic algorithms." />
                <meta name="twitter:description" content="Get Support" />
                <meta name="twitter:image"
                    content="https://zunuprivacy.com/icon192.png" />
                <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
                {/* <script src='//fw-cdn.com/10922385/3684030.js'></script> */}
            </Helmet>

            {/* Pop-up modal */}
            {modal && (
                <div className={styles.modall_Container}>
                    <div className={styles.modall}>
                        <div onClick={toggleModal}> </div>

                        <div className={styles.Close}>
                            <button onClick={toggleModal}
                                className={styles.ClsBtn}> <CloseX className={styles.CloseForm} onClick={Clearform}></CloseX></button>
                        </div>
                        <div className={styles.Heading1}>
                            <div className={styles.Heading}>
                                <p>How Can We Help You?</p>
                            </div>
                            <hr className={styles.separatorLine1}></hr>
                        </div>


                        <div className={styles.contents}>
                            <form className={styles.form} onSubmit={SubmitHandle}>
                                <div className={styles.FormNameAc}>
                                    <div className={styles.formname}>

                                        <div className={styles.Name1}>
                                            <Mail />
                                            <p>Email Address</p>
                                            {/* <p className={styles.EmailMobile}>Email Address </p> */}
                                        </div>

                                        <input type="email" placeholder="let us know where we can reach you" value={mail}
                                            onChange={handleMailChange}
                                            required></input>
                                    </div>
                                    <hr className={styles.separatorLineMobile}></hr>


                                    <div className={styles.formAcc}>
                                        <div className={styles.Account1}>
                                            <div className={styles.MailAccount}>
                                                <MailAc className={styles.ZunuMailAC} />

                                            </div>
                                            <p>Zunu Account</p>
                                            {/* <p className={styles.ZunuAccMobile}>Zunu Account</p> */}
                                        </div>
                                        <input type="email" placeholder="your account linked to zunu" value={account} onChange={handleAccChange}
                                            required ></input>

                                    </div>

                                </div>
                                <hr className={styles.separatorLine} />

                                <div className={styles.FormNameAc}>
                                    <div className={styles.FormOs}>
                                        <div className={styles.Product1}>
                                            <Zunu_icon />
                                            <p> Zunu Product </p>
                                        </div>

                                        <select id="dropdown" required value={product} onChange={handleProductChange}>
                                            <option value="" disabled selected hidden > in which product are you facing issues </option>
                                            <option value="Camera" >Camera</option>
                                            <option value="Drive" >Drive</option>
                                            <option value="Mail" >Mail</option>
                                            <option value="Message" >Message</option>
                                            <option value="Portable" >Portable</option>
                                        </select>
                                    </div>

                                    <hr className={styles.separatorLineMobile}></hr>

                                    <div className={styles.FormOs}>
                                        <div className={styles.Os1}>
                                            <ImpDevices />
                                            <p>Your OS</p>
                                            <p className={styles.OSMobile}>Your O.S.</p>
                                        </div>

                                        <select required value={os} onChange={handleOSChange}>
                                            <option value="" disabled selected hidden> your device OS </option>
                                            {product !== 'Camera' && product !== 'Message' &&
                                                <option value="Windows">Windows</option>
                                            }
                                            <option value="Android">Android</option>
                                            <option value="IOS">IOS</option>
                                            {product !== 'Camera' && product !== 'Message' &&
                                                <option value="Mac">MAC</option>
                                            }
                                            {product !== 'Camera' && product !== 'Message' &&
                                                <option value="Linux">Linux</option>
                                            }
                                        </select>
                                    </div>
                                </div>
                                <hr className={styles.separatorLine} />

                                {/*  <div className={styles.FormOs}>
                 <div className={styles.Topic1}>
                 <FlagT />
                 <p> Topic </p>
                 </div>
                                    
                <div className={styles.Text1}>                                      
                    <select required  value={topic}  onChange={handleTopicChange}>
                      <option value="" disabled selected hidden > select your topic </option>
                      <option value="topic 1" className='topics1'>Topic 1</option>
                      <option value="topic 2" >Topic 2</option>
                      <option value="topic 3" >Topic 3</option>
                      <option value="topic 4" >Topic 4</option>
                   </select>                   
                    </div>                  
                   </div>
               <hr className={styles.separatorLine} />
            */}

                                <div className={styles.FormMsg}>
                                    <div className={styles.MsgL}>
                                        <FeedBack />
                                        <p>Message</p>
                                    </div>

                                    <div className={`${styles.Text1} ${error && `${styles.ErrorBorder}`}`}>
                                        <textarea placeholder="please describe your issue and include any error messages you got" value={message}
                                            onChange={handleMessageChange} required></textarea>
                                        <p>Min 50 char.</p>
                                    </div>


                                    {error && (
                                        <div className={styles.ErrorMsgD}>
                                            <p className={styles.ErrorMsg}>{error} {count} characters remaining</p>
                                        </div>
                                    )}
                                </div>

                                <hr className={styles.separatorLine} />

                                <div className={styles.FormAttch}>
                                    <div className={styles.Attach1}>
                                        <Attachsvg />
                                        <p>Add Attachments</p>
                                    </div>

                                    {!errorm ? (
                                        <div className={styles.Text1}>
                                            <input type="file" ref={fileRef}
                                                onChange={(e) => { changeHandler(e) }}>
                                            </input>

                                            {ss == null ? <p> screenshots or screen recordings that can help us</p> : <p className={styles.FileColor}> {ss.name} </p>}

                                            <div className={styles.ATTACH2}>
                                                <Attachsvg />
                                            </div>
                                        </div>

                                    ) : (

                                        <div className={styles.fileErrorColor}>
                                            <input type="file" ref={fileRef}
                                                onChange={(e) => { changeHandler(e) }}>
                                            </input>

                                            {ss == null ? <p>screenshots or screen recordings that can help us</p> : <p className={styles.FileColor}> {ss.name} </p>}

                                            <div className={styles.ATTACH1}>
                                                <Attachsvg />
                                            </div>
                                        </div>
                                    )}

                                    <div className={styles.ErrorMsgD}>
                                        <p className={styles.ErrorMsg}>{errorm}</p>
                                    </div>
                                </div>

                                <div className={styles.ClearDwnld}>
                                    <button className={styles.ClrForm} onClick={Clearform}>Clear form</button>
                                    <button disabled={isloading || error.length > 0 || errorm.length > 0} className={`${styles.Buttn} ${isloading || error.length > 0 || errorm.length > 0 ? `${styles.Buttn_disabled}` : ''}`} type='submit'>Submit request</button>
                                </div>

                            </form>

                        </div>

                    </div>
                </div>
            )
            }


            <p className={styles.pageHeading}>Help & Support</p>

            {isMobile &&
                <div className={styles.dropdownWrapper}>
                    {activeProduct.map(({ title, Icon }, i) => {
                        return (<div className={`${styles.dropdownBar}`} onClick={() => { setIsDropdown(!isDropdown) }}><div><Icon />{title}</div><Expand className={isDropdown ? `${styles.rotate}` : ''} /></div>)
                    })}

                    {isDropdown &&
                        <div className={styles.dropdownOptions}>
                            {products.map(({ title, Icon }, i) => {
                                return (<div key={i} className={styles.dropdownOption} onClick={() => { handleProductSelect(title, i) }}><Icon />{title}</div>)
                            })}
                        </div>
                    }
                </div>
            }

            {!isMobile &&
                <div className={styles.productsSelector}>
                    {products.map(({ title, Icon }, i) => {
                        return (
                            <div key={i} className={`${styles.product} ${activeProduct[0].title.toLowerCase() == title.toLowerCase() ? `${styles.product_active}` : ''}`} onClick={() => { handleProductSelect(title, i) }}><Icon />{title}</div>
                        )
                    })}
                </div>
            }
            <div className={styles.faqContainer}>
                <div className={styles.joinASession}>
                    <figure>
                        <img src={theme == THEME.LIGHT ? `${joinIllustrationLight}` : `${joinIllustrationDark}`} alt="" />
                    </figure>
                    <p className={styles.sessionHeading}>Let us show you how.</p>
                    <a href='https://calendly.com/gozunu/experience' target='__blank' ><button><VideoIcon /><p>Join a Session</p></button></a>
                </div>
                <div className={styles.faqWrapper}>
                    <p className={styles.faqHeading}>Frequently Asked Questions</p>
                    <div className={styles.questionsWrapper}>
                        {isZunu &&
                            <div className={styles.faqQuestions}>
                                {QUESTIONS.zunu.faq.map((question) => {
                                    return (<FAQArticle id={question.id} title={question.title} contents={question.contents} />)
                                })}
                            </div>
                        }
                        {isCamera &&
                            <div className={styles.faqQuestions}>
                                {QUESTIONS.camera.faq.map((question) => {
                                    return (<FAQArticle id={question.id} title={question.title} contents={question.contents} />)
                                })}
                            </div>
                        }
                        {isMail &&
                            <div className={styles.faqQuestions}>
                                {QUESTIONS.mail.faq.map((question) => {
                                    return (<FAQArticle id={question.id} title={question.title} contents={question.contents} />)
                                })}
                            </div>
                        }
                        {isMessage &&
                            <div className={styles.faqQuestions}>
                                {QUESTIONS.message.faq.map((question) => {
                                    return (<FAQArticle id={question.id} title={question.title} contents={question.contents} />)
                                })}
                            </div>
                        }
                        {isDrive &&
                            <div className={styles.faqQuestions}>
                                {QUESTIONS.drive.faq.map((question) => {
                                    return (<FAQArticle id={question.id} title={question.title} contents={question.contents} />)
                                })}
                            </div>
                        }

                    </div>
                </div>
            </div>


            <div className={styles.topicsContainer}>
                <div className={styles.topicsSelectionWrapper}>
                    {topics.map(({ title, Icon, IconActive, IconActiveDark }, i) => {
                        return (<div key={i} className={`${styles.topic} ${activeTopic[0].title.toLowerCase() == title.toLowerCase() ? `${styles.topic_active}` : ''}`} onClick={() => { handleTopicClick(title) }}>
                            {activeTopic[0].title.toLowerCase() == title.toLowerCase() ?
                                <>
                                    {theme == THEME.LIGHT ?
                                        <IconActive /> :
                                        <IconActiveDark />
                                    }
                                </>

                                :
                                <Icon />
                            }
                            {title} {i == 1 && <>{activeProduct[0].title}</>}
                        </div>)
                    })}
                </div>

                <div className={styles.topicQuestionsWrapper}>
                    {isMobile &&
                        <div className={styles.topicImgWrapper}>
                            {isGettingStarted &&
                                <figure className={styles.topicImgWrapper}>
                                    <img className={styles.topicImg} src={theme == THEME.LIGHT ? `${gettingStartedIllustrationLight}` : `${gettingStartedIllustrationDark}`} alt="Getting Started" />
                                </figure>
                            }
                            {isUsing &&
                                <figure className={styles.topicImgWrapper}>
                                    <img className={styles.topicImg} src={theme == THEME.LIGHT ? `${usingZunuIllustrationLight}` : `${usingZunuIllustrationDark}`} alt="Using Zunu" />
                                </figure>
                            }
                            {isSecurity &&
                                <figure className={styles.topicImgWrapper}>
                                    <img className={styles.topicImg} src={theme == THEME.LIGHT ? `${securityIllustrationLight}` : `${securityIllustrationDark}`} alt="security" />
                                </figure>
                            }
                        </div>
                    }

                    <div className={styles.questionsContainer}>
                        {isZunu && isGettingStarted &&
                            <div className={styles.topicQuestions}>
                                {QUESTIONS.zunu.started.map((question) => {
                                    return (<FAQArticle id={question.id} title={question.title} contents={question.contents} />)
                                })}
                            </div>
                        }
                        {isZunu && isUsing &&
                            <div className={styles.topicQuestions}>
                                {QUESTIONS.zunu.using.map((question) => {
                                    return (<FAQArticle id={question.id} title={question.title} contents={question.contents} />)
                                })}
                            </div>
                        }
                        {isZunu && isSecurity &&
                            <div className={styles.topicQuestions}>
                                {QUESTIONS.zunu.security.map((question) => {
                                    return (<FAQArticle id={question.id} title={question.title} contents={question.contents} />)
                                })}
                            </div>
                        }

                        {isCamera && isGettingStarted &&
                            <div className={styles.topicQuestions}>
                                {QUESTIONS.camera.started.map((question) => {
                                    return (<FAQArticle id={question.id} title={question.title} contents={question.contents} />)
                                })}
                            </div>
                        }
                        {isCamera && isUsing &&
                            <div className={styles.topicQuestions}>
                                {QUESTIONS.camera.using.map((question) => {
                                    return (<FAQArticle id={question.id} title={question.title} contents={question.contents} />)
                                })}
                            </div>
                        }
                        {isCamera && isSecurity &&
                            <div className={styles.topicQuestions}>
                                {QUESTIONS.camera.security.map((question) => {
                                    return (<FAQArticle id={question.id} title={question.title} contents={question.contents} />)
                                })}
                            </div>
                        }

                        {isMail && isGettingStarted &&
                            <div className={styles.topicQuestions}>
                                {QUESTIONS.mail.started.map((question) => {
                                    return (<FAQArticle id={question.id} title={question.title} contents={question.contents} />)
                                })}
                            </div>
                        }
                        {isMail && isUsing &&
                            <div className={styles.topicQuestions}>
                                {QUESTIONS.mail.using.map((question) => {
                                    return (<FAQArticle id={question.id} title={question.title} contents={question.contents} />)
                                })}
                            </div>
                        }
                        {isMail && isSecurity &&
                            <div className={styles.topicQuestions}>
                                {QUESTIONS.mail.security.map((question) => {
                                    return (<FAQArticle id={question.id} title={question.title} contents={question.contents} />)
                                })}
                            </div>
                        }

                        {isMessage && isGettingStarted &&
                            <div className={styles.topicQuestions}>
                                {QUESTIONS.message.started.map((question) => {
                                    return (<FAQArticle id={question.id} title={question.title} contents={question.contents} />)
                                })}
                            </div>
                        }
                        {isMessage && isUsing &&
                            <div className={styles.topicQuestions}>
                                {QUESTIONS.message.using.map((question) => {
                                    return (<FAQArticle id={question.id} title={question.title} contents={question.contents} />)
                                })}
                            </div>
                        }
                        {isMessage && isSecurity &&
                            <div className={styles.topicQuestions}>
                                {QUESTIONS.message.security.map((question) => {
                                    return (<FAQArticle id={question.id} title={question.title} contents={question.contents} />)
                                })}
                            </div>
                        }

                        {isDrive && isGettingStarted &&
                            <div className={styles.topicQuestions}>
                                {QUESTIONS.drive.started.map((question) => {
                                    return (<FAQArticle id={question.id} title={question.title} contents={question.contents} />)
                                })}
                            </div>
                        }
                        {isDrive && isUsing &&
                            <div className={styles.topicQuestions}>
                                {QUESTIONS.drive.using.map((question) => {
                                    return (<FAQArticle id={question.id} title={question.title} contents={question.contents} />)
                                })}
                            </div>
                        }
                        {isDrive && isSecurity &&
                            <div className={styles.topicQuestions}>
                                {QUESTIONS.drive.security.map((question) => {
                                    return (<FAQArticle id={question.id} title={question.title} contents={question.contents} />)
                                })}
                            </div>
                        }


                    </div>

                </div>
            </div>


            <div className={styles.banner}>
                <div className={styles.bannerText}>
                    <p className={styles.bannerHeading}>Still Facing Issues?</p>
                    <p className={styles.bannerContent}>You can write to us directly anytime. And we will get back to you with a solution</p>
                </div>
                <div className={styles.bannerButtonWrapper}>

                    <button onClick={toggleModal}
                        className={styles.bannerButton}>Submit a request</button>



                </div>




            </div>



        </div>


    )
}

export default Support