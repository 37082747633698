import React from 'react'
import {useDownloadHook} from './download.hook';
import styles from './downloads.module.scss';
import { ReactComponent as AppleIcon } from '../../assets/svg/apple_icon.svg';
import { ReactComponent as WindowsIcon } from '../../assets/svg/windows_icon.svg';
import { ReactComponent as LinuxIcon } from '../../assets/svg/linux_icon.svg';
import { ReactComponent as PortableIcon } from '../../assets/svg/zunu_portable_icon_wo_bg.svg';
import { ReactComponent as AndroidIcon } from '../../assets/svg/android_icon.svg';
import { ReactComponent as AppStoreLogo } from '../../assets/svg/appStore_logo.svg';
import { ReactComponent as PlayStoreLogo } from '../../assets/svg/playStore_logo.svg';
import { ReactComponent as IndusAppStoreLogo } from '../../assets/svg/IndusAppstore_logo.svg';
import { ReactComponent as DownloadIcon } from '../../assets/svg/download_icon.svg';
import { ReactComponent as PlaystoreQr } from '../../assets/svg/playstore_qr.svg';
import { ReactComponent as AppstoreQr } from '../../assets/svg/appstore_qr.svg';
import IndusAppStoreQR from '../../assets/png/indusappstore_qr.png';
import { DEVICE, PLATFORM } from '../../_shared/constants/constants';
import { Helmet } from 'react-helmet';
import { useDownloadBtnStore } from '../../StateManager/DownloadBtnStore';
import { useDeviceStore } from '../../StateManager/DeviceStore';


export default function Downloads() {

  const { isDownloadBtn, setIsDownloadBtn } = useDownloadBtnStore();
  const { device, setDevice } = useDeviceStore();
  const {
    handleBuildDownload,
  } = useDownloadHook();

  return (
    <div className={styles.downloadPage}>

      <Helmet>
        <meta name="description" content="Download Zunu" />
        <title>Download Zunu - Keep your private life private.</title>
        
        <meta property="og:site_name" content="Zunu" />
        <meta property="og:title" content="Zunu Camera - Keep your private life private." />
        <meta property="og:description" content="Download Zunu" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://zunuprivacy.com/icon192.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zirohlabs" />
        <meta name="twitter:title" content="Zunu: Software-defined security stack with privacy-preserving, performant cryptographic algorithms." />
        <meta name="twitter:description" content="Download Zunu" />
        <meta name="twitter:image"
          content="https://zunuprivacy.com/icon192.png" />
      </Helmet>
      <p className={styles.pageHeading}>Download Zunu Apps</p>

      <div className={styles.DownloadsWrapper}>
        <p className={styles.platformsHeading}>Download for Desktop</p>
        <div className={styles.platformsWrapper}>
          <div className={styles.platformDownloadContainer}>

            <div className={styles.platformDownloadText}>
              <div className={styles.platformHeader}>
                <div className={`${styles.platformIconWrapper} ${styles.appleIconWrapper}`}>
                  <AppleIcon className={`${styles.platformIcon} ${styles.appleIcon}`} />
                </div>
                <div className={styles.platformName}>
                  <p className={styles.platformHeading}>Zunu for Mac</p>
                  <div className={styles.buildsWrapper}>
                    <div className={styles.platformBuild} onClick={() => { handleBuildDownload(PLATFORM.MAC) }}>
                      <p className={styles.buildName}>Intel Processor</p>
                      <DownloadIcon className={styles.downloadIcon} />
                    </div>

                    <div className={styles.platformBuild} onClick={() => { handleBuildDownload(PLATFORM.MAC) }}>
                      <p className={styles.buildName}>Apple Silicon</p>
                      <DownloadIcon className={styles.downloadIcon} />
                    </div>
                  </div>
                </div>

              </div>



            </div>

          </div>

          <div className={styles.platformDownloadContainer}>

            <div className={styles.platformDownloadText}>
              <div className={styles.platformHeader}>
                <div className={`${styles.platformIconWrapper} ${styles.windowsIconWrapper}`}>
                  <WindowsIcon className={`${styles.platformIcon}`} />
                </div>
                <div className={styles.platformName}>
                  <p className={styles.platformHeading}>Zunu for Windows</p>
                  <div className={styles.buildsWrapper}>
                    <div className={styles.platformBuild} onClick={() => { handleBuildDownload(PLATFORM.WIN) }}>
                      <p className={styles.buildName}>64 bit</p>
                      <DownloadIcon className={styles.downloadIcon} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className={styles.platformDownloadContainer}>
            <div className={styles.platformDownloadText}>
              <div className={styles.platformHeader}>
                <div className={`${styles.platformIconWrapper} ${styles.linuxIconWrapper}`}>
                  <LinuxIcon className={`${styles.platformIcon}`} />
                </div>
                <div className={styles.platformName}>
                  <p className={styles.platformHeading}>Zunu for Linux</p>
                  <div className={styles.buildsWrapper}>
                    <div className={styles.platformBuild} onClick={() => { handleBuildDownload(PLATFORM.LINUX18) }}>
                      <p className={styles.buildName}>Ubuntu 18(X64)</p>
                      <DownloadIcon className={styles.downloadIcon} />
                    </div>

                    <div className={styles.platformBuild} onClick={() => { handleBuildDownload(PLATFORM.LINUX22) }}>
                      <p className={styles.buildName}>Ubuntu 22(X64)</p>
                      <DownloadIcon className={styles.downloadIcon} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <p className={styles.platformsHeading}>Download for Mobile</p>
        <div className={styles.platformsWrapper}>

          <div className={styles.platformDownloadContainer}>
            <div className={styles.platformDownloadText}>
              <div className={styles.platformHeader}>
                <div className={`${styles.platformIconWrapper} ${styles.appleIconWrapper}`}>
                  <AppleIcon className={`${styles.platformIcon} ${styles.appleIcon}`} />
                </div>
                <div className={styles.platformName}>
                  <p className={styles.platformHeading}>Zunu for iOS</p>
                  <div className={styles.buildsWrapper}>
                    <div className={styles.platformBuild} onClick={() => { handleBuildDownload(PLATFORM.IOS) }}>
                      <div className={`${styles.platformIconWrapper} ${styles.platformLogoWrapper}`}>
                        <AppStoreLogo className={`${styles.platformLogo}`} />
                      </div>
                    </div>
                  </div>
                </div>
                <AppstoreQr className={`${styles.qr}`} />
              </div>
            </div>
          </div>

          <div className={styles.platformDownloadContainer}>
            <div className={styles.platformDownloadText}>
              <div className={styles.platformHeader}>
                <div className={`${styles.platformIconWrapper} ${styles.androidIconWrapper}`}>
                  <AndroidIcon className={`${styles.platformIcon}`} />
                </div>
                <div className={styles.platformName}>
                  <p className={styles.platformHeading}>Zunu for Android</p>
                  <div className={styles.buildsWrapper}>
                    <div className={styles.platformBuild} >
                      <div className={`${styles.platformIconWrapper} ${styles.platformLogoWrapper}`} onClick={() => { handleBuildDownload(PLATFORM.AND) }}>
                        <PlayStoreLogo className={`${styles.platformLogo}`} />
                      </div>
                      {device !== DEVICE.DESKTOP &&
                        <div className={`${styles.platformIconWrapper} ${styles.platformLogoWrapper}`} onClick={() => { handleBuildDownload(PLATFORM.INDUS) }}>
                          <IndusAppStoreLogo className={`${styles.platformLogo}`} />
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <PlaystoreQr className={`${styles.qr}`} />
              </div>
            </div>
          </div>

          {device === DEVICE.DESKTOP &&
            <div className={styles.platformDownloadContainer}>
              <div className={styles.platformDownloadText}>
                <div className={styles.platformHeader}>
                  <div className={`${styles.platformIconWrapper} ${styles.androidIconWrapper}`}>
                    <AndroidIcon className={`${styles.platformIcon}`} />
                  </div>
                  <div className={styles.platformName}>
                    <p className={styles.platformHeading}>Zunu for Android</p>
                    <div className={styles.buildsWrapper}>
                      <div className={styles.platformBuild} onClick={() => { handleBuildDownload(PLATFORM.INDUS) }}>
                        <div className={`${styles.platformIconWrapper} ${styles.platformLogoWrapper}`}>
                          <IndusAppStoreLogo className={`${styles.platformLogo}`} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <img src={IndusAppStoreQR} alt="IndusQR" className={`${styles.qr}`} />
                </div>
              </div>
            </div>
          }

        </div>

        <p className={styles.platformsHeading}>Zunu for USB and Storage Devices</p>
        <div className={styles.platformsWrapper}>
          <div className={styles.platformDownloadContainer}>
            <div className={styles.platformDownloadText}>
              <div className={styles.platformHeader}>
                <div className={`${styles.platformIconWrapper} ${styles.portableIconWrapper}`}>
                  <PortableIcon className={`${styles.platformIcon} ${styles.portableIcon}`} />
                </div>
                <div className={styles.platformName}>
                  <p className={styles.platformHeading}>Zunu Portable
                    {/* <span title='This Zunu version is portable, installable on USBs, hard drives, and more.'>&#9432;</span> */}
                  </p>
                  <div className={styles.buildsWrapper}>

                    <div className={styles.platformBuild} onClick={() => { handleBuildDownload(PLATFORM.WIN_PORATBLE) }}>
                      <p className={styles.buildName}>Windows</p>
                      <DownloadIcon className={styles.downloadIcon} />
                    </div>

                    {/* <div className={styles.platformBuild}>
                      <p className={styles.buildName}>Mac Intel Processor</p>
                      <DownloadIcon className={styles.downloadIcon} />
                    </div>

                    <div className={styles.platformBuild}>
                      <p className={styles.buildName}>Apple Silicon</p>
                      <DownloadIcon className={styles.downloadIcon} />
                    </div>

                    <div className={styles.platformBuild}>
                      <p className={styles.buildName}>Linux</p>
                      <DownloadIcon className={styles.downloadIcon} />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}