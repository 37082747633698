import { useEffect } from 'react';

import styles from './privacyPolicy.module.scss';
import { ReactComponent as IconLeftArrow } from '../../assets/svg/privacy_left_arrow.svg';
import { ReactComponent as IconRightArrow } from '../../assets/svg/privacy_right_arrow.svg';

import { ReactComponent as IconDownArrowCircle } from '../../assets/svg/down_arrow_circle.svg';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import { PrivacyPolicyContents } from './components/PrivacyPolicyContents/privacyPolicyContents';
import {
	privacyPolicyCardsCamera,
	privacyPolicyCardsDrive,
	privacyPolicyCardsMail,
	privacyPolicyCardsMessage
} from './data/content';
import { TabsName } from './_types/tabsName';
import { PRIVACY_TABS_TITLES } from './data/privacyTabsTitle';
import { PrivacyCards } from './components/PrivacyCards/privacyCards';
import { Helmet } from 'react-helmet';
import { usePrivacyPolicy } from './usePrivacyPolicy';
import { useDownloadBtnStore } from '../../StateManager/DownloadBtnStore';

//LAZY LOADING
import { lazily } from 'react-lazily';
// const { PrivacyCards } = lazily(() => import('./components/PrivacyCards/privacyCards'));
const { PrivacyPolicyContents } = lazily(() => import('./components/PrivacyPolicyContents/privacyPolicyContents'));

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1700 },
		items: 5,
		slidesToSlide: 3 // optional, default to 1.
	},
	desktopMid: {
		breakpoint: { max: 1699, min: 1200 },
		items: 4,
		slidesToSlide: 2 // optional, default to 1.
	},
	desktopSmall: {
		breakpoint: { max: 1199, min: 1025 },
		items: 4,
		slidesToSlide: 2 // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 730 },
		items: 4,
		slidesToSlide: 2 // optional, default to 1.
	},
	mobileLarge: {
		breakpoint: { max: 729, min: 480 },
		items: 2,
		slidesToSlide: 1 // optional, default to 1.
	},
	mobileMid: {
		breakpoint: { max: 479, min: 361 },
		items: 2,
		slidesToSlide: 1 // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 360, min: 0 },
		items: 1,
		slidesToSlide: 1 // optional, default to 1.
	}
};

export default function PrivacyPolicy() {

	const { isDownloadBtn, setIsDownloadBtn} = useDownloadBtnStore();
	useEffect(() => {
		setIsDownloadBtn(false);
	}, []);

	const {
		ref,
		selectedTab,
		showPrivacyPolicy,
		handleSelectedTab,
		handlePrivacyPolicyClick
	} = usePrivacyPolicy();

	return (
		<>
			<Helmet>
				<meta
					name="description"
					content="Privacy Policy of Zunu Apps"
				/>
				<title>Privacy Policy - Zunu</title>
				
				<meta property="og:site_name" content="Zunu" />
				<meta property="og:title" content="Privacy Policy - Zunu" />
				<meta
					property="og:description"
					content="Privacy Policy of Zunu Apps"
				/>
				<meta property="og:type" content="website" />
				<meta
					property="og:image"
					content="https://zunuprivacy.com/icon192.png"
				/>
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@zirohlabs" />
				<meta
					name="twitter:title"
					content="Zunu: Software-defined security stack with privacy-preserving, performant cryptographic algorithms."
				/>
				<meta
					name="twitter:description"
					content="Privacy Policy of Zunu Apps"
				/>
				<meta
					name="twitter:image"
					content="https://zunuprivacy.com/icon192.png"
				/>
			</Helmet>
			{selectedTab !== 'passwords' && (
				<section className={styles.privacy_section}>
					<h3>Privacy Simplified.</h3>

					<section className={styles.privacy_tabs_section}>
						<section className={styles.privacy_tabs_container}>
							{PRIVACY_TABS_TITLES.map(
								(name: TabsName, index) => (
									<div
										key={index}
										className={`${
											styles.privacy_tabs_name
										} ${
											selectedTab === name
												? styles.privacy_tabs_name_active
												: ''
										}`}
										onClick={() => {
											handleSelectedTab(name);
										}}
									>
										<p>{name}</p>
										<div
											className={
												styles.tabs_border_active
											}
										></div>
									</div>
								)
							)}
						</section>
						<section className={styles.privacy_contents_container}>
							{selectedTab === 'mail' && (
								<Carousel
									swipeable={true}
									draggable={true}
									showDots={false}
									responsive={responsive}
									customTransition="transform 500ms ease-in"
									containerClass={
										styles.privacy_cards_carousel
									}
									itemClass={styles.carousel_item_style}
									customLeftArrow={
										<button
											className={
												styles.privacy_left_arrow
											}
											aria-label='left_Arrow'
										>
											<IconLeftArrow />
										</button>
									}
									customRightArrow={
										<button
											className={
												styles.privacy_right_arrow
											}
											aria-label='right_Arrow'
										>
											<IconRightArrow />
										</button>
									}
								>
									{privacyPolicyCardsMail.map(
										(
											{ icon, data, backgroundElement },
											index
										) => {
											return (
												<PrivacyCards
													key={index}
													icon={icon}
													data={data}
													backgroundElement={
														backgroundElement
													}
												/>
											);
										}
									)}
								</Carousel>
							)}
							{selectedTab === 'camera' && (
								<Carousel
									swipeable={true}
									draggable={true}
									showDots={false}
									responsive={responsive}
									customTransition="transform 500ms ease-in"
									containerClass={
										styles.privacy_cards_carousel
									}
									itemClass={styles.carousel_item_style}
									customLeftArrow={
										<button
											className={
												styles.privacy_left_arrow
											}
										>
											<IconLeftArrow />
										</button>
									}
									customRightArrow={
										<button
											className={
												styles.privacy_right_arrow
											}
										>
											<IconRightArrow />
										</button>
									}
								>
									{privacyPolicyCardsCamera.map(
										(
											{ icon, data, backgroundElement },
											index
										) => {
											return (
												<PrivacyCards
													key={index}
													icon={icon}
													data={data}
													backgroundElement={
														backgroundElement
													}
												/>
											);
										}
									)}
								</Carousel>
							)}
							{selectedTab === 'drive' && (
								<Carousel
									swipeable={true}
									draggable={true}
									showDots={false}
									responsive={responsive}
									customTransition="transform 500ms ease-in"
									containerClass={
										styles.privacy_cards_carousel
									}
									itemClass={styles.carousel_item_style}
									customLeftArrow={
										<button
											className={
												styles.privacy_left_arrow
											}
										>
											<IconLeftArrow />
										</button>
									}
									customRightArrow={
										<button
											className={
												styles.privacy_right_arrow
											}
										>
											<IconRightArrow />
										</button>
									}
								>
									{privacyPolicyCardsDrive.map(
										(
											{ icon, data, backgroundElement },
											index
										) => {
											return (
												<PrivacyCards
													key={index}
													icon={icon}
													data={data}
													backgroundElement={
														backgroundElement
													}
												/>
											);
										}
									)}
								</Carousel>
							)}
							{selectedTab === 'message' && (
								<Carousel
									swipeable={true}
									draggable={true}
									showDots={false}
									responsive={responsive}
									customTransition="transform 500ms ease-in"
									containerClass={
										styles.privacy_cards_carousel
									}
									itemClass={styles.carousel_item_style}
									customLeftArrow={
										<button
											className={
												styles.privacy_left_arrow
											}
										>
											<IconLeftArrow />
										</button>
									}
									customRightArrow={
										<button
											className={
												styles.privacy_right_arrow
											}
										>
											<IconRightArrow />
										</button>
									}
								>
									{privacyPolicyCardsMessage.map(
										(
											{ icon, data, backgroundElement },
											index
										) => {
											return (
												<PrivacyCards
													key={index}
													icon={icon}
													data={data}
													backgroundElement={
														backgroundElement
													}
												/>
											);
										}
									)}
								</Carousel>
							)}
						</section>
						<button
							className={styles.privacy_policy_button}
							onClick={handlePrivacyPolicyClick}
						>
							privacy policy
							<IconDownArrowCircle
								className={styles.privacy_policy_arrow_icon}
							/>
						</button>
					</section>
				</section>
			)}

			<div ref={ref}>
				<PrivacyPolicyContents showContentFor={selectedTab} />
			</div>
		</>
	);
}
