import { ZunuThingsCardsData } from "../ZunuThingsCards/zunuThingsCards";

import { ReactComponent as IconDevices } from "../../../../assets/svg/icon_devices_outlined.svg";
import { ReactComponent as IconDevicesWhite } from "../../../../assets/svg/icon_devices_outlined_white.svg";

import { ReactComponent as IconGallery } from "../../../../assets/svg/icon_gallery_outlined.svg";
import { ReactComponent as IconGalleryWhite } from "../../../../assets/svg/icon_gallery_outlined_white.svg";

import { ReactComponent as IconChat } from "../../../../assets/svg/icon_chat_outlined.svg";
import { ReactComponent as IconChatWhite } from "../../../../assets/svg/icon_chat_outlined_white.svg";

// import { ReactComponent as IconFile } from "../../../../assets/svg/icon_file_outlined.svg";
// import { ReactComponent as IconFileWhite } from "../../../../assets/svg/icon_file_outlined_white.svg";

import { ReactComponent as IconThirdParty } from "../../../../assets/svg/icon_third_party_outlined.svg";
import { ReactComponent as IconThirdPartyWhite } from "../../../../assets/svg/icon_third_party_outlined_white.svg";

import { ReactComponent as IconUSB } from "../../../../assets/svg/icon_usb_outlined.svg";
import { ReactComponent as IconUSBWhite } from "../../../../assets/svg/icon_usb_outlined_white.svg";

import { ReactComponent as IconSync } from "../../../../assets/svg/icon_sync_outlined.svg";
import { ReactComponent as IconSyncWhite } from "../../../../assets/svg/icon_sync_outlined_white.svg";

import { ReactComponent as IconShield } from "../../../../assets/svg/icon_shield_outlined.svg";
import { ReactComponent as IconShieldWhite } from "../../../../assets/svg/icon_shield_outlined_white.svg";

import { ReactComponent as IconDrive } from "../../../../assets/svg/icon_drive_outlined.svg";
import { ReactComponent as IconDriveWhite } from "../../../../assets/svg/icon_drive_outlined_white.svg";

import { ReactComponent as IconCloud } from "../../../../assets/svg/icon_cloud_outlined.svg";
import { ReactComponent as IconCloudWhite } from "../../../../assets/svg/icon_cloud_outlined_white.svg";

import { ReactComponent as IconGmail } from "../../../../assets/svg/icon_gmail_outlined.svg";
import { ReactComponent as IconGmailWhite } from "../../../../assets/svg/icon_gmail_outlined_white.svg";

import { ReactComponent as IconOutlook } from "../../../../assets/svg/icon_outlook_outlined.svg";
import { ReactComponent as IconOutlookWhite } from "../../../../assets/svg/icon_outlook_outlined_white.svg";

import { ReactComponent as IconPadLocked } from "../../../../assets/svg/icon_pad_lock_outlined.svg";
import { ReactComponent as IconPadLockWhite } from "../../../../assets/svg/icon_pad_lock_outlined_white.svg";

import { ReactComponent as IconHacking } from "../../../../assets/svg/icon_hacking_outlined.svg";
import { ReactComponent as IconHackingWhite } from "../../../../assets/svg/icon_hacking_outlined_white.svg";
import { ReactComponent as IconEmail } from "../../../../assets/svg/icon_email_outlined.svg";
import { ReactComponent as IconEmailWhite } from "../../../../assets/svg/icon_email_outlined_white.svg";
import { ReactComponent as IconAttachment } from "../../../../assets/svg/icon_protect_attachment_outlined.svg";
import { ReactComponent as IconAttachmentWhite } from "../../../../assets/svg/icon_protect_attachment_outlined_white.svg";
import { ReactComponent as IconPhoto } from "../../../../assets/svg/icon_photo_outlined.svg";
import { ReactComponent as IconPhotoWhite } from "../../../../assets/svg/icon_photo_outlined_white.svg";
import { ReactComponent as IconSafeFile } from "../../../../assets/svg/icon_safe_file_outlined.svg";
import { ReactComponent as IconSafeFileWhite } from "../../../../assets/svg/icon_safe_file_outlined_white.svg";
import { ReactComponent as IconProtection } from "../../../../assets/svg/icon_protection_outlined.svg";
import { ReactComponent as IconProtectionWhite } from "../../../../assets/svg/icon_protection_outlined_white.svg";
import { ReactComponent as IconFamily } from "../../../../assets/svg/icon_family_outlined.svg";
import { ReactComponent as IconFamilyWhite } from "../../../../assets/svg/icon_family_outlined_white.svg";
import { ReactComponent as IconChatting } from "../../../../assets/svg/icon_chatting_outlined.svg";
import { ReactComponent as IconChattingWhite } from "../../../../assets/svg/icon_chatting_outlined_white.svg";

import { ReactComponent as IconGalleryShield } from "../../../../assets/svg/icon_gallery_shield.svg";
import { ReactComponent as IconFamilyShield } from "../../../../assets/svg/icon_family_shield.svg";
import { ReactComponent as IconChildShield } from "../../../../assets/svg/icon_child_shield.svg";
import { ReactComponent as IconDarkWebShield } from "../../../../assets/svg/icon_darkweb_shield.svg";
import { ReactComponent as IconMessageShieldLock } from "../../../../assets/svg/icon_message_shield_lock.svg";
import { ReactComponent as IconDevicesColored } from "../../../../assets/svg/icon_devices.svg";
import { ReactComponent as IconGalleryLock } from "../../../../assets/svg/icon_gallery_locked.svg";
import { ReactComponent as IconMessageLock } from "../../../../assets/svg/icon_message_locked.svg";
import { ReactComponent as IconMessageInvisible } from "../../../../assets/svg/icon_message_invisible.svg";
import { ReactComponent as IconRecover } from "../../../../assets/svg/icon_recover.svg";
import { ReactComponent as IconNoVirus } from "../../../../assets/svg/icon_no_virus.svg";
import { ReactComponent as IconPadLock } from "../../../../assets/svg/icon_pad_lock.svg";
import { ReactComponent as IconFileShield } from "../../../../assets/svg/icon_file_shield.svg";
import { ReactComponent as IconUsbShield } from "../../../../assets/svg/icon_usb_shield.svg";
import { ReactComponent as IconGDrive } from "../../../../assets/svg/icon_gdrive.svg";
import { ReactComponent as IconOneDrive } from "../../../../assets/svg/icon_onedrive.svg";
import { ReactComponent as IconGmailInvisible } from "../../../../assets/svg/icon_gmail_invisible.svg";
import { ReactComponent as IconOutlookInvisible } from "../../../../assets/svg/icon_outlook_invisible.svg";
import { ReactComponent as IconMailShield } from "../../../../assets/svg/icon_mail_shield.svg";
import { ReactComponent as IconAttachmentShield } from "../../../../assets/svg/icon_attachment_shield.svg";

export type ThingZunuDoesData = ZunuThingsCardsData & {
  iconDark: any;
};

export const THINGS_ZUNU_DOES: ThingZunuDoesData[] = [
  {
    icon: IconGalleryShield,
    iconDark: IconDevicesWhite,
    heading: `All your <span>photos</span> are <span>shielded</span> from harmful AI.`,
    body: `AI can no longer identify people, emotions, clothing, time, and location of your photos and use them without your consent.`,
  },
  {
    icon: IconFamilyShield,
    iconDark: IconGalleryWhite,
    heading: `Protects your <span>family's data.</span>`,
    body: `Harmful AI can't exploit your family's photos and videos.`,
  },
  {
    icon: IconChildShield,
    iconDark: IconChatWhite,
    heading: `Safeguards your <span>child's data.</span>`,
    body: `Harmful social media AI can't misuse your child's photos and videos.`,
  },
  //   NEW
  {
    icon: IconDarkWebShield,
    iconDark: IconThirdPartyWhite,
    heading: `Your data can no longer be sold on the <span>Dark Web</span>`,
    body: `even if your Google and Microsoft accounts are hacked.`,
  },
  //   {
  //     icon: IconFile,
  //     iconDark: IconFileWhite,
  //     heading: `Protects your <span>sensitive documents</span>`,
  //     body: `like bank statements, health records, and ID proofs from harmful AI.`,
  //   },
  {
    icon: IconMessageShieldLock,
    iconDark: IconUSBWhite,
    heading: `<span>Secures</span> messaging applications `,
    body: `like WhatsApp and Signal.`,
  },
  {
    icon: IconDevicesColored,
    iconDark: IconSyncWhite,
    heading: `Safeguards <span>confidential files</span> on all your devices –`,
    body: `mobiles, laptops, cloud storage, and USB drives.`,
  },
  {
    icon: IconGalleryLock,
    iconDark: IconShieldWhite,
    heading: `Photos and videos on your phone <span>can't be seen</span> by anyone,`,
    body: `even if your phone is borrowed, hacked, or password compromised.`,
  },

  {
    icon: IconMessageLock,
    iconDark: IconDriveWhite,
    heading: `Your text messages are always <span>hidden,</span>`,
    body: `even if someone gains entry to your phone.`,
  },
  {
    icon: IconMessageInvisible,
    iconDark: IconCloudWhite,
    heading: `<span>No third-party</span> app can read`,
    body: `your personal messages and photos.`,
  },
  //   NEW
  {
    icon: IconRecover,
    iconDark: IconGmailWhite,
    heading: `Recover data with <span>one click</span>`,
    body: `even if your laptop stops working, Zunu restores all your files.`,
  },
  //   NEW
  {
    icon: IconNoVirus,
    iconDark: IconOutlookWhite,
    heading: `<span>Ransomware, Malware, or Viruses</span> are no longer a worry.`,
    body: `even if your laptop stops working, Zunu restores all your files.`,
  },
  {
    icon: IconPadLock,
    iconDark: IconPadLockWhite,
    heading: `No more need to remember the <span>passwords</span>`,
    body: `of your Google and Microsoft accounts.`,
  },
  {
    icon: IconFileShield,
    iconDark: IconHackingWhite,
    heading: `<span>Files</span> on your <span>laptop</span> remain secure`,
    body: `of your Google and Microsoft accounts.`,
  },
  {
    icon: IconUsbShield,
    iconDark: IconEmailWhite,
    heading: `<span>Secures</span> your files on any USB storage,`,
    body: `even those without built-in security.`,
  },
  {
    icon: IconGDrive,
    iconDark: IconAttachmentWhite,
    heading: `Nobody can view your <span>files</span> in <span>Google Drive,</span>`,
    body: `even if your password is compromised.`,
  },
  {
    icon: IconOneDrive,
    iconDark: IconPhotoWhite,
    heading: `Nobody can view your <span>files</span> in <span>OneDrive</span>,`,
    body: `even if your password is compromised.`,
  },
  {
    icon: IconGmailInvisible,
    iconDark: IconSafeFileWhite,
    heading: `Nobody can view your <span>emails</span> in <span>Gmail,</span>`,
    body: `even if your password is compromised.`,
  },
  {
    icon: IconOutlookInvisible,
    iconDark: IconProtectionWhite,
    heading: `Nobody can view your <span>emails</span> in <span>Outlook,</span>`,
    body: `even if your password is compromised.`,
  },
  {
    icon: IconMailShield,
    iconDark: IconFamilyWhite,
    heading: `Email <span>service providers</span> can’t read your emails`,
    body: `as you type them.`,
  },
  {
    icon: IconAttachmentShield,
    iconDark: IconChattingWhite,
    heading: `All your downloaded <span>email attachments</span>`,
    body: `remain confidential.`,
  },
];
