import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { ReactComponent as ZunuIcon } from "../../assets/svg/zunu_icon.svg";
import { ReactComponent as ZunuCameraIcon } from "../../assets/svg/zunu_cam_icon.svg";
import { ReactComponent as ZunuCameraIconInactive } from "../../assets/svg/zunu_cam_icon_inactive.svg";
import { ReactComponent as ZunuMailIcon } from "../../assets/svg/zunu_mail_icon.svg";
import { ReactComponent as ZunuMailIconInactive } from "../../assets/svg/zunu_mail_icon_inactive.svg";
import { ReactComponent as ZunuMessageIcon } from "../../assets/svg/zunu_msg_icon.svg";
import { ReactComponent as ZunuMessageIconInactive } from "../../assets/svg/zunu_msg_icon_inactive.svg";
import { ReactComponent as ZunuDriveIcon } from "../../assets/svg/zunu_drive_icon.svg";
import { ReactComponent as ZunuDriveIconInactive } from "../../assets/svg/zunu_drive_icon_inactive.svg";
import { ReactComponent as GettingStartedIconInactive } from "../../assets/svg/rocket_icon_inactive.svg";
import { ReactComponent as GettingStartedIconActive } from "../../assets/svg/rocket_icon_active.svg";
import { ReactComponent as GettingStartedIconActiveDark } from "../../assets/svg/rocket_icon_active_dark.svg";
import { ReactComponent as ZunuIconInactive } from "../../assets/svg/zunu_icon_inactive.svg";
import { ReactComponent as ZunuIconActive } from "../../assets/svg/zunu_icon_active.svg";
import { ReactComponent as ShieldIconInactive } from "../../assets/svg/shield_icon_inactive.svg";
import { ReactComponent as ShieldIconActive } from "../../assets/svg/shield_icon_active.svg";
import { ReactComponent as ShieldIconActiveDark } from "../../assets/svg/shield_icon_active_dark.svg";
import { useThemeStore } from "../../StateManager/ThemeStore";
import { useDownloadBtnStore } from "../../StateManager/DownloadBtnStore";

const useSupportHook = ()=>{

    const products=[
        {
            Icon: ZunuIcon,
            title: `ZUNU`,
        },
        {
            Icon: ZunuCameraIcon,
            title:`Camera`
        },
        {
            Icon: ZunuMailIcon,
            title:`Mail`
        },
        {
            Icon: ZunuMessageIcon,
            title:`Message`
        },
        {
            Icon: ZunuDriveIcon,
            title:`Drive`
        },
    ]

    const [usingIcon, setUsingIcon] = useState<any>(ZunuIconInactive)
    const [usingIconActive, setUsingIconActive] = useState<any>(ZunuIconActive)
    const [usingIconDark, setUsingIconDark] = useState<any>(ZunuIconActive)

    const topics=[
        {
            Icon: GettingStartedIconInactive,
            IconActive: GettingStartedIconActive,
            IconActiveDark: GettingStartedIconActiveDark,
            title: `Getting Started`,
        },
        {
            Icon: usingIcon,
            IconActive: usingIconActive,
            IconActiveDark: usingIconActive,
            title:`Using`
        },
        {
            Icon: ShieldIconInactive,
            IconActive: ShieldIconActive,
            IconActiveDark: ShieldIconActiveDark,
            title:`Security`
        },
    ]


    const { theme, setTheme } = useThemeStore()
    const { isDownloadBtn, setIsDownloadBtn} = useDownloadBtnStore();

    const [activeProduct, setActiveProduct] = useState([products[0]]);
    const [activeTopic, setActiveTopic] = useState([topics[0]]);
    const [isDropdown, setIsDropdown] = useState(false);
    const [isZunu, setIsZunu] = useState(false)
    const [isCamera, setIsCamera] = useState(false)
    const [isMail, setIsMail] = useState(false)
    const [isMessage, setIsMessage] = useState(false)
    const [isDrive, setIsDrive] = useState(false)
    const [isGettingStarted, setIsGettingStarted] = useState(true);
    const [isUsing, setIsUsing] = useState(false)
    const [isSecurity, setIsSecurity] = useState(false)
    const [isMobile, setIsMobile] = useState(false);

    const [searchParams] = useSearchParams();
    const product_query = searchParams.get('p');
    const question_query = searchParams.get('q');

    useEffect(() => {
        // console.log(product_query);
        // console.log(question_query);
        setIsDownloadBtn(true)
        if(product_query){
            handleProductSelect(product_query)
        }
        else{
            handleProductSelect(products[0].title)
        }
        if(question_query){
            handleTopicClick(topics[1].title)
        }
        else{
            handleTopicClick(topics[0].title)
        }
        if(window.screen.width < 550){
            setIsMobile(true)
        }
    }, [])


    function handleProductSelect(title:string, i?:number){
        setIsDropdown(false)

        setIsZunu(false)
        setIsCamera(false)
        setIsMail(false)
        setIsMessage(false)
        setIsDrive(false)

        if(title.toLowerCase() == products[0].title.toLowerCase()){
            setIsZunu(true)
            setActiveProduct([products[0]])
            setUsingIcon(ZunuIconInactive)
            setUsingIconActive(ZunuIconActive)
            setUsingIconDark(ZunuIconActive)
            return
        }
        if(title.toLowerCase() == products[1].title.toLowerCase()){
            setIsCamera(true)
            setActiveProduct([products[1]])
            setUsingIcon(ZunuCameraIconInactive)
            setUsingIconActive(ZunuCameraIcon)
            setUsingIconDark(ZunuCameraIcon)
            return
        }
        if(title.toLowerCase() == products[2].title.toLowerCase()){
            setIsMail(true)
            setActiveProduct([products[2]])
            setUsingIcon(ZunuMailIconInactive)
            setUsingIconActive(ZunuMailIcon)
            setUsingIconDark(ZunuMailIcon)
            return
        }
        if(title.toLowerCase() == products[3].title.toLowerCase()){
            setIsMessage(true)
            setActiveProduct([products[3]])
            setUsingIcon(ZunuMessageIconInactive)
            setUsingIconActive(ZunuMessageIcon)
            setUsingIconDark(ZunuMessageIcon)
            return
        }
        if(title.toLowerCase() == products[4].title.toLowerCase()){
            setIsDrive(true)
            setActiveProduct([products[4]])
            setUsingIcon(ZunuDriveIconInactive)
            setUsingIconActive(ZunuDriveIcon)
            setUsingIconDark(ZunuDriveIcon)
            return
        }
        if(title.toLowerCase() == 'experience'){
            window.location.href = 'https://calendly.com/gozunu/experience'
            return
        }
        else{
            handleProductSelect(products[0].title)
            setActiveProduct([products[0]])
            return
        }
    }

    function handleTopicClick(title:string){
        setIsGettingStarted(false)
        setIsUsing(false)
        setIsSecurity(false)

        if(title.toLowerCase() == topics[0].title.toLowerCase()){
            setIsGettingStarted(true)
            setActiveTopic([topics[0]]);
        }
        if(title.toLowerCase() == topics[1].title.toLowerCase()){
            setIsUsing(true);
            setActiveTopic([topics[1]]);
        }
        if(title.toLowerCase() == topics[2].title.toLowerCase()){
            setIsSecurity(true)
            setActiveTopic([topics[2]]);
        }
    }

    
    

    return{
        isDropdown, setIsDropdown,
        products, activeProduct,
        handleProductSelect,
        isZunu, isCamera, isMail, isMessage, isDrive,
        topics, isGettingStarted, isUsing, isSecurity,
        handleTopicClick, question_query,
        theme, isMobile, activeTopic,
    }
}

export default useSupportHook