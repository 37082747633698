import { PrivacyCardsType } from "../../_types/privacyCardsType";
import styles from "./privacyCards.module.scss";

type PrivacyCardsProps = PrivacyCardsType & {
  isAnimate?: boolean;
};

const PrivacyCards = ({
  icon: Icon,
  data,
  backgroundElement: BackgroundElement,
  isAnimate,
}: PrivacyCardsProps) => {
  return (
    <div className={`${styles.privacy_cards_container}`}>
      <article className={styles.privacy_cards}>
        <Icon className={styles.privacy_cards_icon} />
        <p>{data} </p>
      </article>
      <BackgroundElement className={styles.privacy_cards_backdrop} />
    </div>
  );
};
export { PrivacyCards }