import React, { useEffect, useState } from 'react'
import styles from './cryptoChallenge.module.scss';
import IntroImage from "../../assets/webp/crypto_challange_hero_image.webp";
import {RegistrationModal} from '../_shared/components/RegistrationModal/registrationModal.component';
import { useDownloadBtnStore } from '../../StateManager/DownloadBtnStore';

function CryptoChallenge() {

    const { isDownloadBtn, setIsDownloadBtn} = useDownloadBtnStore();
    useEffect(() => {
        setIsDownloadBtn(true)
    }, [])


    const [isModal, setIsModal] = useState(false);

    function handleRegisterClick() {
        setIsModal(true);
    }

    return (
        <div className={styles.challangePage}>
            <div className={styles.challangeIntro}>
                <div className={styles.introText}>
                    Zunu Cryptographic Challenge
                    <button onClick={handleRegisterClick}>Register Now</button>
                </div>
                <div className={styles.introImage}>
                    <figure>
                        <img src={IntroImage} alt="Trophy" />
                    </figure>
                </div>
            </div>
            <div className={styles.challengeDescription}>
                <div className={styles.descriptionCategory}>
                    <p className={styles.categoryHeading}><span>Challenge</span> Overview :</p>
                    <p className={styles.categoryText}>The Zunu Cryptographic Challenge is a two-part competition designed to test participants' cryptographic skills. In the first stage, participants will be provided with an Email ID and password of an account encrypted using Zunu. The objective is to decrypt the information stored in those accounts and associate the data to the original email or file.</p>
                </div>



                <div className={styles.descriptionCategory}>
                    <p className={styles.categoryHeading}><span>Stage 1</span> :</p>
                    <ul className={styles.categoryText}>
                        <li>Participants will receive the encrypted account’s Email ID and password on their registered email.</li>
                        <li>The challenge is to decrypt the stored information and map it to the original email or file.</li>
                        <li>Successful submissions should reveal the methods and tools used to achieve it.</li>
                    </ul>
                </div>



                <div className={styles.descriptionCategory}>
                    <p className={styles.categoryHeading}><span>Stage 2</span> :</p>
                    <ul className={styles.categoryText}>
                        <li>In case there are no successful Stage 1 submissions, Stage 2 will be activated.</li>
                        <li>In this stage, the data will be partially decrypted to simplify the challenge. A selected set of files and emails will be decrypted and shared with the participants.</li>
                        <li>Participants can use the deciphered information to extract more information from the data stored in the encrypted accounts.</li>
                        <li>The goal is to accurately retrieve information from the remaining files and emails.</li>
                    </ul>
                </div>



                <div className={styles.descriptionCategory}>
                    <p className={styles.categoryHeading}><span className={styles.rewards}>Rewards :</span></p>
                    <ul className={styles.categoryText}>
                        <li>The challenge offers rewards for successful solutions:</li>
                        <li>Participants who are successful in extracting information from the decrypted emails will get a reward of INR 5,00,000.</li>
                    </ul>
                </div>



                <div className={styles.descriptionCategory}>
                    <p className={styles.categoryHeading}><span>Submitting</span> Solutions :</p>
                    <p className={styles.categoryText}>Solutions for both parts can be submitted via email to communications@ziroh.com with the subject line specifying the stage (e.g., "Zunu Crypto Challenge Part 1" or "Zunu Crypto Challenge Part 2"). Submissions must detail the decrypted information, method and tools used to achieve the outcome.</p>
                </div>



                <div className={styles.descriptionCategory}>
                    <p className={styles.categoryHeading}><span>Revision</span> History :</p>
                    <p className={styles.categoryText}>Challenge Launch Date: <strong>14-02-2024</strong>
                        <br></br>
                        <br></br>
                        <strong>Note:</strong>&nbsp;The Zunu Cryptographic Challenge is governed by the rules outlined in the challenge documentation. Participants must adhere to the specified terms and conditions for eligibility.
                        <br></br>
                        <br></br>
                        <strong>Revision 1:</strong>&nbsp;[No successful submissions yet.]
                    </p>
                </div>

            </div>


            {isModal &&
                <RegistrationModal setIsModal={setIsModal} subject={"Crypto Challenge Registration"} />
            }


        </div>
    )
}

export default CryptoChallenge