import { RefObject, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TabsName } from "./_types/tabsName";

type UsePrivacyPolicyReturns = {
  showPrivacyPolicy: boolean;
  selectedTab: TabsName;
  ref: RefObject<HTMLDivElement>;

  handleSelectedTab: (name: TabsName) => void;
  handlePrivacyPolicyClick: () => void;
};

export const usePrivacyPolicy = (): UsePrivacyPolicyReturns => {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<TabsName>("camera");
  const ref = useRef<HTMLDivElement>(null);

  const [searchParams] = useSearchParams();
  const query = searchParams.get("product");

  useEffect(() => {
    // console.log({ query });
    if (query) {
      setShowPrivacyPolicy(true);
      setSelectedTab(query.toLowerCase() as TabsName);
    }
  }, [query]);

  useEffect(() => {
    const carouselUL = document.querySelector(".react-multi-carousel-track");

    if (carouselUL) {
      // @ts-ignore
      carouselUL.style.transform = "translate3d(0px, 0px, 0px)";
    }
  }, [selectedTab]);

  const handlePrivacyPolicyClick = () => {
    if (!ref.current) {
      return;
    }

    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const handleSelectedTab = (name: TabsName) => {
    setSelectedTab(name);
  };

  return {
    showPrivacyPolicy,
    selectedTab,
    ref,

    handleSelectedTab,
    handlePrivacyPolicyClick,
  };
};
