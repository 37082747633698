import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { locationService } from './App.registration';
import { useDeviceStore } from './StateManager/DeviceStore';
import { useLocalizationStore } from './StateManager/LocalizationStore';
import { useOsStore } from './StateManager/OsStore';
import { useThemeStore } from './StateManager/ThemeStore';
import { DEVICE, PLATFORM, THEME } from './_shared/constants/constants';


const useAppHook = () => {

    const [isButtonVisible, setIsButtonVisible] = useState<boolean>(true);
    const { theme, setTheme } = useThemeStore()
    const { localization, setLocalization } = useLocalizationStore()
    const { os, setOs } = useOsStore();
    const { device, setDevice } = useDeviceStore();


    function handleResize() {
        if (window.innerWidth <= 730) {
            setDevice(DEVICE.MOBILE)
        }
        else if (window.innerWidth >= 1200) {
            setDevice(DEVICE.DESKTOP)
        }
        else {
            setDevice(DEVICE.TABLET)
        }
    }


    useEffect(() => {
        window.addEventListener('resize', handleResize)
        if (window.innerWidth <= 730) {
            setDevice(DEVICE.MOBILE)
        }
        else if (window.innerWidth >= 1200) {
            setDevice(DEVICE.DESKTOP)
        }
        else {
            setDevice(DEVICE.TABLET)
        }

        if (!localStorage.getItem("Theme")) {
            setTheme(THEME.LIGHT)
            localStorage.setItem("Theme", THEME.LIGHT)
        }
        else {
            // setTheme(localStorage.getItem("Theme") as string);
            setTheme(THEME.LIGHT)
            localStorage.setItem("Theme", THEME.LIGHT)
        }

        getOS()

        if (!sessionStorage.getItem("Localizaton")) {
            fetchDataFromAPI();
        }
        else {
            setLocalization(sessionStorage.getItem("Localizaton") as string);
        }


        // fetchDataFromAPI();

    }, [])

    async function fetchDataFromAPI() {
        try {
            const jsonData = await locationService.fetchIp();

            if (jsonData.country_code == 'IN') {
                setLocalization('0')
                sessionStorage.setItem("Localizaton", '0')
                return
            }

            if (jsonData.country_code == 'US') {
                setLocalization('1')
                sessionStorage.setItem("Localizaton", '1')
                return
            }

            if (jsonData.continent_code == 'EU') {
                setLocalization('2')
                sessionStorage.setItem("Localizaton", '2')
                return
            }

            if (jsonData.country_code == 'JP') {
                setLocalization('3')
                sessionStorage.setItem("Localizaton", '3')
                return
            }

            else {
                setLocalization('1')
                sessionStorage.setItem("Localizaton", '1')
            }


        } catch (error) {
            setLocalization('1')
        } finally {
        }
    }

    // Localization code for different countries
    // 0 = India
    // 1 = USA
    // 2 = Europe
    // 3 = Japan

    const getOS = (): any => {
        const userAgent = window.navigator.userAgent;
        const platform = window.navigator.platform;

        const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
        const macPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'darwin'];
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        const androidPlatforms = ['Android'];

        // setOs(window.navigator.userAgent)
        if (windowsPlatforms.indexOf(platform) !== -1) {
            setOs(PLATFORM.WIN)
            return 'Windows';
        }
        else if (macPlatforms.indexOf(platform) !== -1 && window.screen.width > 1400) {
            setOs(PLATFORM.MAC)
            return 'mac';
        }
        else if (iosPlatforms.indexOf(platform) !== -1) {
            setOs(PLATFORM.IOS)
            return 'iOS';
        }
        else if (macPlatforms.indexOf(platform) !== -1 && window.screen.width < 1200) {
            setOs(PLATFORM.IOS)
            return 'iPadOS';
        }
        else if (userAgent.indexOf('Android') !== -1) {
            setOs(PLATFORM.AND)
            return 'android'
        }
        else if (/Linux/.test(userAgent)) {
            setOs("linux")
            return 'Linux';
        }

        setOs(PLATFORM.WIN)
        return 'Unknown OS';
    };

    return {
        fetchDataFromAPI,
        localization, setLocalization,
        theme, setTheme,
        os, setOs,
        getOS,
        isButtonVisible, setIsButtonVisible,
    }
}

export default useAppHook;