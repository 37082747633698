import { useEffect } from "react";
import { ZunuThingsCardsContainer } from "../Home/components/ZunuThingsCardsContainer/zunuThingsCardsContainer";
import styles from "./thingsZunuDoes.module.scss";
import { useDownloadBtnStore } from "../../StateManager/DownloadBtnStore";

export default function ThingsZunuDoes() {

  const { isDownloadBtn, setIsDownloadBtn} = useDownloadBtnStore();
  useEffect(() => {
    setIsDownloadBtn(true);
  }, []);

  return (
    <section className={styles.things_zunu_does_section}>
      <header className={styles.things_zunu_does_header}>
        <h3 className={styles.things_zunu_does_title}>
          20 Things that <span>Zunu</span> does today to protect your privacy
        </h3>
      </header>

      <ZunuThingsCardsContainer isPage={true} />
    </section>
  );
}
