export const ENVIRONMENT = {
    zunuDownloadUrls: {
        builds: "/builds",
        windows: "https://storage.googleapis.com/zunu-releases/win/Zunu-X64-v1.1.5.msi",
        mac: "https://apps.apple.com/in/app/zunu/id1672198613?mt=12",
        mac_intel: "https://storage.googleapis.com/zunu-releases/mac/intel/Zunu-intel-v1.0.23.pkg",
        android: "https://play.google.com/store/apps/developer?id=Ziroh+Labs+Inc.&hl",
        indus: "https://www.indusappstore.com/#scanToDownloadApp",
        ios: "https://apps.apple.com/in/developer/ziroh-labs-inc/id1665684284",
        linux_18: "https://storage.googleapis.com/zunu-releases/linux/Ubuntu18/Zunu-amd64-18-v1.1.0.deb",
        linux_22: "https://storage.googleapis.com/zunu-releases/linux/Ubuntu22/Zunu-amd64-22-v1.1.0.deb",
        winows_portable: "https://storage.googleapis.com/zunu-releases/portable/windows/Zunu-Portable-WinX64-v1.1.5.zip",
        mailPlayStore: "https://play.google.com/store/apps/details?id=com.ziroh.zunumail&pcampaignid=web_share",
        mailAppStore: "https://play.google.com/store/apps/details?id=com.ziroh.zunumail&pcampaignid=web_share",
        drivePlayStore: "https://play.google.com/store/apps/details?id=com.ziroh.zunudrive&pcampaignid=web_share",
        driveAppStore: "https://apps.apple.com/in/app/zunu-drive/id1665684282",
        messagePlayStore: "https://play.google.com/store/apps/details?id=com.ziroh.zunumessage&pcampaignid=web_share",
        cameraPlayStore: "https://play.google.com/store/apps/details?id=com.ziroh.zunucamera&pcampaignid=web_share",
        cameraAppStore: "https://apps.apple.com/in/app/zunu-camera/id6468880940",
    },
    baseUrl:"https://vault-svc1.dev.ziroh.com/websiteserver/api/v1",
    // baseUrl:"https://websiteserver-svc.vault.ziroh.com/api/v1",
    regionUrl: "https://ipapi.co/json/",
    mailsenderUrl: "https://mailer-svc.vault.ziroh.com/api/v1/mailsender/send",
    disposableDomainsUrl: "https://raw.githubusercontent.com/disposable/disposable-email-domains/master/domains.txt",
    sendOtpUrl: "https://vault-svc1.dev.ziroh.com/nonzunuserver/api/v1/auth/otp/",
    verifyotpUrl: "https://vault-svc1.dev.ziroh.com/nonzunuserver/api/v1/auth/register",

    createTicketUrl:"/ticket",
    academicRegisterUrl: "/mailsender/academic",
    whitepaperSenderUrl: "/whitepapers/send",
    emailValidator: "/email/validate",

    consoleAppUrls:{
        baseUrl:"https://vault-svc1.dev.ziroh.com/eaas/api/v1",
        // baseUrl:"https://eaas-svc.vault.ziroh.com/api/v1",
        generateStringKey:"/string/client/key/generate",
        generateNumericKey:"/numeric/client/key/generate",
        encryptString:"/string/client/encrypt",
        decryptString:"/string/client/decrypt",
        encryptInteger:"/numeric/client/integer/encrypt",
        decryptInteger:"/numeric/client/integer/decrypt",
        encryptDouble:"/numeric/client/double/encrypt",
        decryptDouble:"/numeric/client/double/decrypt",

        prefixMatch:"/string/operation/search/prefix",
        suffixMatch:"/string/operation/search/suffix",
        substringMatch:"/string/operation/search/substring",
        equalToMatch:"/string/operation/equalto",
        concatenateString:"/string/operation/concatenation",
        splitString:"/string/operation/split",
        sortString:"/string/operation/sort",
        compareString:"/string/operation/compare",

        addInterger:"/numeric/operation/integer/add",
        subtractInteger:"/numeric/operation/integer/subtract",
        multiplyInteger:"/numeric/operation/integer/multiply",
        addDouble:"/numeric/operation/double/add",
        subtractDouble:"/numeric/operation/double/subtract",
        multiplyDouble:"/numeric/operation/double/multiply",
        compareInteger:"/numeric/operation/integer/compare",
        compareDouble:"/numeric/operation/double/compare",
        stringOperations:"/string/operation/all",
        numericOperations:"/numeric/operation/all",

        codeSnippet: "/codesnippet",

        sendOtp: "/console/otp/email/send",
        verifyOtp: "/console/otp/email/verify",

        // releaseNotes: "https://websiteserver-svc.vault.ziroh.com/api/v1/whitepapers/FHE_CONSOLE_RELEASE_NOTES.pdf",
        releaseNotes: "https://vault-svc1.dev.ziroh.com/websiteserver/api/v1/whitepapers/FHE_CONSOLE_RELEASE_NOTES.pdf",
    }
};